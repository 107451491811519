import React from 'react'
import { FormikMessage } from 'components/Formik/FormikMessage'
import { field } from 'pages/RecognizeSomeone/configForm'

export const RecognizeSomeoneDescription = () => {
  const name = field('description')

  return (
    <FormikMessage
      minRows={5}
      size="large"
      name={name}
      placeholder="recognize_someone.description_placeholder"
      variant="paper"
      bottomBarText="recognize_someone.description_placeholder_2"
    />
  )
}
