import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Hide, Show } from 'components/ArteelIcons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import { providerTypes } from 'pages/Login/api/useGetSSOProviders'
import { ChooseProvider } from 'pages/Login/SSO/ChooseProvider'
import { FormValues } from 'pages/Login/types'
import { useSubdomainInfo } from 'shared/Site/api'
import { useCustomAuthContext } from 'store/CustomAuthTokenProvider'
import { useCookiesContext } from 'store/useCookiesContext'
import { fontFamilies, fontSizes } from 'theme/styles.utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { safeHtml } from 'utils/safeHtml'

// eslint-disable-next-line complexity
export const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isSubmitting } = useFormikContext<FormValues>()
  const { data: subdomainInfo } = useSubdomainInfo()
  const hasActivationCodes = subdomainInfo?.data?.subscriber?.has_activation_codes
  const { providers } = useCustomAuthContext()
  const customProvider = providers?.find((provider) => provider.type === providerTypes.custom_jwt)
  const isCustomAuthActive = !!customProvider
  const activeProviders = !!providers?.filter((provider) => provider.type !== providerTypes.custom_jwt)?.length
  const isAdminForm = location.pathname.includes('form')
  const { isCookieBannerVisible } = useCookiesContext()
  const isMobile = useIsMobile()
  const toggleShowPassword = () => setShowPassword((prev) => !prev)

  if (isCustomAuthActive && !isAdminForm) {
    return (
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={safeHtml(getObjectTranslation(customProvider?.translation)?.login_page)} />
      </Grid>
    )
  }

  if (activeProviders && !isAdminForm) {
    return <ChooseProvider providers={providers} />
  }

  return (
    <>
      {hasActivationCodes && (
        <Grid item>
          <Button
            data-cy="activate_account_button"
            variant="contained"
            color="primary"
            type="button"
            onClick={() => navigate('/auth/activate-account')}
            sx={{ mb: 4 }}
          >
            {t('auth.activate_account')}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormikTextField
          autoFocus={providers?.length === 0 && !isCookieBannerVisible}
          name="username"
          inputLabel="auth.login.username"
          variant="outlined"
          size="small"
          center
          InputProps={{
            inputProps: {
              autoComplete: 'email',
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          name="password"
          inputLabel="auth.login.password"
          type={showPassword ? 'text' : 'password'}
          size="small"
          center
          InputProps={{
            inputProps: {
              autoComplete: 'new-password',
            },
            endAdornment: (
              <IconButton size="large" color="primary" onClick={toggleShowPassword}>
                {showPassword ? <Hide fontSize="large" /> : <Show fontSize="large" />}
              </IconButton>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Grid
              container
              direction={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'center' : 'flex-start'}
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs lg="auto" order={isMobile ? 2 : 1}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="medium"
                  loading={isSubmitting}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {t('auth.login')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="small"
              variant="text"
              type="button"
              onClick={() => navigate('/auth/forgot-password')}
              sx={{
                fontSize: fontSizes.h4,
                fontFamily: fontFamilies.default,
              }}
            >
              {t('auth.forgot_password')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
