import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import AccordionDetails from '@mui/material/AccordionDetails'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import truncate from 'lodash/truncate'
import { useDeletePersonalTemplate } from 'pages/PersonalMessages/api/useDeletePersonalTemplate'
import { Occasion, PersonalTemplate } from 'pages/PersonalMessages/api/useGetPersonalTemplatesByOccasionId'
import { SubscriberTemplate } from 'pages/PersonalMessages/api/useGetSubscriberTemplates'
import { usePostPersonalTemplate } from 'pages/PersonalMessages/api/usePostPersonalTemplate'
import { fontFamilies } from 'theme/styles.utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { removeHTML } from 'utils/removeHTML'

type Props<T> = {
  template: T extends SubscriberTemplate
    ? SubscriberTemplate['message_templates'][0]
    : Occasion['message_templates_user'][0]
  occasionId: number
  setObjectToEdit?: Dispatch<SetStateAction<undefined | null | (PersonalTemplate & { occasionId: Occasion['id'] })>>
}

const shortenedContentLength = 150
export const Template = <T extends SubscriberTemplate | Occasion>({
  template,
  occasionId,
  setObjectToEdit,
}: Props<T>) => {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(false)
  const name = 'translation' in template ? getObjectTranslation(template?.translation)?.name : template?.name
  const content =
    'translation' in template
      ? removeHTML(getObjectTranslation(template?.translation)?.content)
      : removeHTML(template?.content)

  const { mutate, isLoading: isLoadingCopy } = usePostPersonalTemplate()
  const { mutate: deleteTemplate, isLoading: isLoadingDelete } = useDeletePersonalTemplate()

  return (
    <AccordionDetails>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography fontWeight="bold" variant="h4" sx={{ fontFamily: fontFamilies.default }}>
            {name}
          </Typography>
          <Typography variant="h4" sx={{ pl: 4, pt: 2 }}>
            {collapsed ? content : truncate(content, { length: shortenedContentLength, separator: ' ' })}
            {content?.length > shortenedContentLength && (
              <Box sx={{ width: '100%', ml: (theme) => theme.spacing(-4) }}>
                <Button
                  size="small"
                  color="primary"
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  onClick={() => setCollapsed((prev) => !prev)}
                >
                  {t(collapsed ? 'app.hide_more' : 'app.view_more')}
                </Button>
              </Box>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Box display="grid" gridColumn="auto">
            {/* Only in personal templates*/}
            {!!setObjectToEdit && (
              <Button
                size="small"
                color="primary"
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                onClick={() =>
                  setObjectToEdit({
                    occasionId: occasionId,
                    id: template.id,
                    name: name,
                    content: content,
                  })
                }
              >
                {t('app.edit')}
              </Button>
            )}
            {/* Only in default templates*/}
            {!setObjectToEdit && (
              <LoadingButton
                size="small"
                color="primary"
                loading={isLoadingCopy}
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                onClick={() =>
                  mutate({
                    occasion: occasionId,
                    name: name,
                    content: content,
                  })
                }
              >
                {t('app.copy')}
              </LoadingButton>
            )}
            {/* Only in personal templates*/}
            {!!setObjectToEdit && (
              <LoadingButton
                size="small"
                color="error"
                loading={isLoadingDelete}
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                onClick={() =>
                  deleteTemplate({
                    id: template.id,
                  })
                }
              >
                {t('app.remove')}
              </LoadingButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  )
}
