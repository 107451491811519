import React, { useContext } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { Form, Formik } from 'formik'
import { XXL } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useRecognizeSomeone } from 'utils/hooks/api/SocialWall/useRecognizeSomeone'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import * as Yup from 'yup'

import {
  RecognizeSomeoneDescription,
  RecognizeSomeoneMe,
  RecognizeSomeoneSection,
  RecognizeSomeoneSelectUsers,
  RecognizeSomeoneValues,
} from './components'
import { initialValues, mapValues, useValidationSchemaObject } from './configForm'

export const RecognizeSomeoneForm = () => {
  const { closeDrawer } = useContext(drawerContext)
  const isMobile = useIsMobile()
  const { isLoading, mutate } = useRecognizeSomeone({ onSuccess: closeDrawer })
  const validationSchema = Yup.object().shape(useValidationSchemaObject())
  const containerWidth = window.visualViewport?.width > XXL ? 'md' : 'sm'

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => mutate(mapValues(values))}
      validationSchema={validationSchema}
    >
      <Form data-cy="recognize-someone-form">
        <Container maxWidth={containerWidth} sx={{ py: isMobile ? 1 : 4, px: valueOrUndefined(isMobile, 1) }}>
          <Grid container spacing={2} justifyContent="center">
            <RecognizeSomeoneSection SectionComponent={<RecognizeSomeoneMe />} />
            <RecognizeSomeoneSection
              sectionName="recognize_someone.who"
              SectionComponent={<RecognizeSomeoneSelectUsers />}
            />
            <RecognizeSomeoneSection
              sectionName="recognize_someone.for_what"
              SectionComponent={<RecognizeSomeoneValues />}
            />
            <RecognizeSomeoneSection
              sectionName="recognize_someone.why"
              SectionComponent={<RecognizeSomeoneDescription />}
            />
            <RecognizeSomeoneSection
              SectionComponent={
                <FormikButtons
                  textButtonSubmit="recognize_someone.recognize"
                  onBack={closeDrawer}
                  isLoading={isLoading}
                  gridLayout
                  size="small"
                  fullWidth
                />
              }
            />
          </Grid>
        </Container>
      </Form>
    </Formik>
  )
}
