import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import Loader from 'components/Loader/Loader'
import qs from 'query-string'
import { getResponsiveSize } from 'theme/styles.utils'
import { displayPoints } from 'utils/displayPoints'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useExpensivestProducts } from 'utils/hooks/useExpensivestProducts'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { important } from 'utils/styles'
import { makeStyles } from 'utils/styles/makeStyles'

import useCheckboxes from './useCheckboxes'
import { getParamKeys, urlParams } from './utils'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: getResponsiveSize(300),
    overflowY: 'initial',
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
  },
  slider: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 0),
    },
    '& .MuiSlider-thumb[data-index="1"] .MuiSlider-valueLabel': {
      transform: 'translateX(-62%)',
    },
    '& .MuiSlider-valueLabel': {
      left: '50%',
      top: '100%',
      background: 'transparent',
      transform: 'translateX(-50%)',
      '& span': {
        background: 'transparent',
        color: theme.palette.primary.main,
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    '& .MuiSlider-track': {
      height: 4,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  buttonGroup: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
}))

export const FilterProduct = () => {
  const { closeDrawer } = useContext(drawerContext)
  const { data, isLoading: isLoading } = useExpensivestProducts()
  const maxSliderValue = data?.data?.data?.[0]?.price_points || 0
  const { dialogContent, label, slider, buttonGroup } = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { available_points } = useCurrentUser()
  const { t } = useTranslation()
  const step = Math.floor((maxSliderValue || 100) / 100)
  const params = qs.parse(location.search.replace('?', ''))
  const [sliderValue, setSliderValue] = useState([0, 100])
  const [{ enoughPointsCheckbox, newProductsCheckbox, viewedCheckbox }, { setCheckbox, toggleCheckbox }] =
    useCheckboxes()
  const isTablet = useIsTablet()
  const { isPointsLogic } = useIsLuckyBirdUser()
  const getInitFilterParam = (name: string) => {
    const filter = getParamKeys(params, 'filters').find((f) => f === name)

    if (filter && params[filter]) {
      return parseInt(params[filter] as string, 10)
    }
  }

  const initSliderValue = useCallback(
    () => [getInitFilterParam(urlParams.filtersGt) || 0, getInitFilterParam(urlParams.filtersLt) || maxSliderValue],
    [maxSliderValue]
  )

  const onOk = () => {
    closeDrawer()
    navigate(
      `${location.pathname}?${qs.stringify(
        {
          ...params,
          [urlParams.filtersGt]: sliderValue[0],
          [urlParams.filtersLt]: sliderValue[1],
          [urlParams.enoughPoints]: enoughPointsCheckbox,
        },
        { encode: false }
      )}`
    )
  }

  const onClear = () => {
    closeDrawer()
    navigate(location.pathname)
  }

  useEffect(() => {
    if (enoughPointsCheckbox) {
      setSliderValue([0, Math.min(available_points, maxSliderValue)])
    }
  }, [enoughPointsCheckbox])

  useEffect(() => {
    setSliderValue(initSliderValue())
    const enoughPointsKey = getParamKeys(params, urlParams.enoughPoints)[0]
    if (enoughPointsKey) {
      setCheckbox('enoughPointsCheckbox', JSON.parse(params[enoughPointsKey] as string))
    }
  }, [initSliderValue])

  return (
    <DialogContent className={dialogContent}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg="auto">
              <Typography
                className={label}
                align="center"
                sx={(t) => ({
                  [t.breakpoints.down('lg')]: {
                    textTransform: 'uppercase',
                    color: important(t.palette.text.secondary),
                  },
                })}
              >
                {t('filter.products.range')}
                {!isTablet && ':'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md
              sx={(_) => ({
                [_.breakpoints.down('lg')]: {
                  mb: valueOrUndefined(isPointsLogic, 2),
                },
              })}
            >
              <Loader isLoading={isLoading}>
                <Slider
                  className={slider}
                  valueLabelDisplay="on"
                  step={step}
                  value={valueOrUndefined(!!maxSliderValue, sliderValue)}
                  min={0}
                  max={maxSliderValue || 100}
                  disabled={enoughPointsCheckbox || !maxSliderValue}
                  getAriaValueText={(value) => `${value}`}
                  valueLabelFormat={(value) => displayPoints(value)}
                  onChange={(event, newValue) => setSliderValue(newValue as number[])}
                />
              </Loader>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent={isTablet ? 'center' : 'space-around'}>
            {!isPointsLogic && (
              <Grid item lg={7}>
                <FormGroup row={!isTablet}>
                  <FormControlLabel
                    className={label}
                    value="end"
                    sx={{ mr: 4 }}
                    checked={enoughPointsCheckbox}
                    onClick={(e) => toggleCheckbox('enoughPointsCheckbox')}
                    control={<Radio color="primary" sx={(t) => ({ color: t.palette.primary.main })} />}
                    label={
                      <Typography className={label} onClick={(e) => toggleCheckbox('enoughPointsCheckbox')}>
                        {t('filter.products.enough_points')}
                      </Typography>
                    }
                    labelPlacement="end"
                  />

                  {/* commented because of not working backend */}

                  {/* <FormControlLabel*/}
                  {/*  disabled*/}
                  {/*  sx={{ mr: 4 }}*/}
                  {/*  checked={newProductsCheckbox}*/}
                  {/*  className={label}*/}
                  {/*  value="end"*/}
                  {/*  control={<Radio color="primary" />}*/}
                  {/*  label={<Typography className={label}>{t('filter.products.new_products')}</Typography>}*/}
                  {/*  labelPlacement="end"*/}
                  {/*/ >*/}
                  {/* <FormControlLabel*/}
                  {/*  disabled*/}
                  {/*  checked={viewedCheckbox}*/}
                  {/*  className={label}*/}
                  {/*  value="end"*/}
                  {/*  control={<Radio color="primary" />}*/}
                  {/*  label={<Typography className={label}>{t('filter.products.recently_viewed')}</Typography>}*/}
                  {/*  labelPlacement="end"*/}
                  {/*/ >*/}
                </FormGroup>
              </Grid>
            )}
            <Grid
              container
              item
              lg={valueOrUndefined(!isPointsLogic, 5)}
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              className={buttonGroup}
            >
              <Grid item>
                <Button variant="contained" color="primary" size="small" onClick={onOk}>
                  {t('app.filters.apply').toUpperCase()}
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="outlined" size="small" onClick={onClear}>
                  {t('app.filters.no_defined').toUpperCase()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  )
}
