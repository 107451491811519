import React from 'react'
import Grid from '@mui/material/Grid'
import { MyAccountPoints } from 'pages/MyAccount/components/Points/MyAccountPoints'

import { MyAccountHistory } from './components/MyAccountHistory'
import { MyAccountUserConsent } from './components/MyAccountUserConsent'
import { MyAccountVoucherOrders } from './components/MyAccountVoucherOrders'
import { MyAccount } from './MyAccount'

export const MyAccountColumn = () => {
  return (
    <Grid container spacing={2}>
      <MyAccount />
      <MyAccountPoints />
      <MyAccountHistory />
      <MyAccountVoucherOrders />
      <MyAccountUserConsent />
    </Grid>
  )
}
