import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import Chip from '@mui/material/Chip'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { DepartmentTreeProps } from 'components/DepartmentTree/types'
import { UseDepartments } from 'components/DepartmentTree/useDepartments'
import { TextField } from 'components/TextField/TextField'
import { getResponsiveSize } from 'theme/styles.utils'

interface DepartmentSearchFieldProps
  extends Pick<DepartmentTreeProps, 'searchProps'>,
    Partial<Pick<DepartmentTreeProps, 'setSelected'>>,
    Pick<UseDepartments, 'handleSetSearch'>,
    Partial<Pick<UseDepartments, 'selectedNode'>>,
    Pick<TextFieldProps, 'onFocus' | 'onBlur' | 'onClick'> {}
export const DepartmentSearchField = forwardRef<HTMLDivElement, DepartmentSearchFieldProps>(
  (
    {
      handleSetSearch,
      searchProps: { iconProps = {}, searchSx = {}, placeholder = 'app.search' } = {},
      onBlur,
      onFocus,
      onClick,
      selectedNode,
      setSelected,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    return (
      <TextField
        sx={searchSx}
        size="small"
        variant="outlined"
        data-cy="search-department-text-field"
        placeholder={t(placeholder)}
        onChange={(event) => handleSetSearch(event.target.value)}
        InputProps={{
          startAdornment: [
            selectedNode ? (
              <Chip
                key={0}
                sx={{ mr: 1, minWidth: getResponsiveSize(100, 'px') }}
                label={selectedNode.name}
                variant="outlined"
                onDelete={() => setSelected(undefined)}
              />
            ) : (
              <SearchIcon key={1} {...iconProps} />
            ),
          ],
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={onClick}
        ref={ref}
      />
    )
  }
)
