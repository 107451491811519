import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import { Comment } from 'pages/SocialWall/components/comment/Comment'
import { spliceIntoChunks } from 'pages/SocialWall/components/comment/utils'
import { PostComment } from 'utils/api/SocialWall/types'
interface Props {
  comments: PostComment[]
  postId: number
}

export const Comments = ({ comments = [], postId }: Props) => {
  const [visibleChunks, setVisibleChunks] = useState(0)
  const { t } = useTranslation()
  const START_VISIBLE_AMOUNT = 2
  const CHUNK_SIZE = 10

  const showMoreHandler = () => setVisibleChunks((prev) => prev + 1)

  const chunks = spliceIntoChunks(comments.slice(START_VISIBLE_AMOUNT), CHUNK_SIZE).map((postsChunk, i) => (
    <Grid item xs={12} key={i}>
      <Collapse in={visibleChunks >= i + 1} mountOnEnter unmountOnExit>
        <Grid container spacing={1}>
          {postsChunk.map((comment) => (
            <Grid item xs={12} key={comment.id} data-cy="comment-box-wrapper">
              <Comment postId={postId} comment={comment} />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Grid>
  ))

  const commentsLeftText = () => {
    const left = comments.length - START_VISIBLE_AMOUNT - visibleChunks * CHUNK_SIZE
    return t('recognition_post.show_more_comments', { amount: left < 0 ? 0 : left })
  }

  const showLoadMore = visibleChunks < chunks.length

  return (
    <Grid container spacing={1} data-cy="comments-container" sx={{ mt: 1, mb: 2 }}>
      {comments.slice(0, START_VISIBLE_AMOUNT).map((comment) => (
        <Grid item xs={12} key={comment.id} data-cy="comment-box-wrapper">
          <Comment postId={postId} comment={comment} />
        </Grid>
      ))}
      {chunks}
      <Collapse in={showLoadMore} sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="primary"
            onClick={showMoreHandler}
            endIcon={<KeyboardArrowDownIcon />}
            data-cy="load-more-comments-button"
          >
            {commentsLeftText()}
          </Button>
        </Box>
      </Collapse>
    </Grid>
  )
}
