import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { CommentAdd } from 'pages/SocialWall/components/comment/CommentAdd'
import { Comments } from 'pages/SocialWall/components/comment/Comments'
import { PostActionsButtons } from 'pages/SocialWall/components/PostActionsButtons'
import { PostActionsInfo } from 'pages/SocialWall/components/PostActionsInfo'
import { Recognition } from 'pages/SocialWall/components/Recognition'
import { SpecialMoment } from 'pages/SocialWall/components/SpecialMoment'
import { TimeStamp } from 'pages/SocialWall/components/TimeStamp'
import { useLikedByLoggedUser } from 'pages/SocialWall/components/utils'
import { SocialWallPost, SocialWallPostType } from 'utils/api/SocialWall/types'
import { important } from 'utils/styles/stylesUtils'

interface Props {
  post: SocialWallPost
}

export const PostBox = ({ post }: Props) => {
  const { likedByLoggedUser } = useLikedByLoggedUser(post.likes)
  const [showAddComment, setShowAddComment] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const showCollapseIcon = !!post.comments?.length

  return (
    <ResponsivePaper sx={(t) => ({ pb: important(t.spacing(0)) })}>
      <TimeStamp
        created_at={post.created_at}
        sx={(t) => ({ position: 'absolute', right: t.spacing(3), top: t.spacing(2) })}
      />
      {post.type === SocialWallPostType.Recognition ? <Recognition post={post} /> : <SpecialMoment post={post} />}
      <PostActionsInfo
        comments={post.comments}
        likes={post.likes}
        onClickComments={() => post.comments?.length && setShowComments((prev) => !prev)}
      />
      <PostActionsButtons
        isAddCommentOpen={showAddComment}
        onCommentButtonClick={() => setShowAddComment((prev) => !prev)}
        postId={post.id}
        isLiked={likedByLoggedUser}
      />
      <Collapse in={showAddComment} mountOnEnter={false} unmountOnExit={true}>
        <CommentAdd postId={post.id} onExit={() => setShowAddComment(false)} showComments={showComments} />
      </Collapse>
      <Collapse in={showComments} mountOnEnter={false} unmountOnExit={false}>
        <Comments comments={post.comments} postId={post.id} />
      </Collapse>
      {showCollapseIcon && (
        <IconButton
          size="small"
          color="primary"
          data-cy="collapse-comments-button"
          onClick={() => setShowComments((prev) => !prev)}
          sx={(theme) => ({
            position: 'absolute',
            left: '50%',
            bottom: theme.spacing(-1),
            transform: `translate(-50%, 15%) rotate(${!showComments ? 0 : 180}deg)`,
            background: 'white',
            p: 0.125,
            borderBottom: !showComments ? `1px solid ${theme.palette.grey['300']}` : 'none',
            borderTop: showComments ? `1px solid ${theme.palette.grey['300']}` : 'none',
            boxShadow: '0 0 0 0.125rem rgba(0, 0, 0, 0)',
            transition: '0.1s all ease',
            '&:hover': {
              background: 'white',
              boxShadow: '0 0 0 0.125rem rgba(0, 0, 0, 0.1)',
              borderBottom: '1px solid transparent',
              borderTop: '1px solid transparent',
            },
          })}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      )}
    </ResponsivePaper>
  )
}
