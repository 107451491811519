import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Close } from 'components/ArteelIcons'
import Loader from 'components/Loader/Loader'
import { CartItem } from 'pages/ProductCard/components/AddItemToCart/CartItem'
import { useAmountOfItemsInShoppingCart, useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { getResponsiveSize } from 'theme/styles.utils'
import { dataCy } from 'utils/cypressUtils'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const WIDTH = getResponsiveSize(1000)
const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      minWidth: `calc(100vw - ${theme.spacing(10)})`,
      padding: theme.spacing(6, 3),
    },
    [theme.breakpoints.up(1921)]: {
      padding: theme.spacing(6),
      minWidth: WIDTH,
      maxWidth: WIDTH,
      '& .MuiPaper-root': {
        minWidth: WIDTH,
        maxWidth: WIDTH,
      },
    },
    '&.MuiDialogContent-root:first-child': {
      paddingTop: theme.spacing(6),
    },
  },
  dialogPaper: {
    minWidth: 500,
    [theme.breakpoints.down('md')]: {
      minWidth: `calc(100vw - ${theme.spacing(10)})`,
      '& .MuiDialog-paperWidthSm': {
        maxWidth: `calc(100vw - ${theme.spacing(10)})`,
      },
    },
    [theme.breakpoints.up(1921)]: {
      padding: theme.spacing(6),
      '& .MuiDialog-paperWidthSm': {
        minWidth: WIDTH,
        maxWidth: WIDTH,
      },
    },
    '& .MuiPaper-rounded': {
      borderRadius: 12,
      overflowY: 'visible',
    },
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h1.fontSize,
  },
  buttons: {
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}))

type Props = {
  open: boolean
  onCancel: () => void
  isAdding?: boolean
  warning?: string
}

export const DialogShoppingCartList = ({ onCancel, open, isAdding, warning }: Props) => {
  const { dialog, dialogPaper, header, buttons } = useStyles()
  const { t } = useTranslation()
  const { data: shoppingCartItems, isFetching } = useSelectShoppingCart()
  const navigate = useNavigate()
  const { isSingleLogic } = useIsLuckyBirdUser()
  const amountItems = useAmountOfItemsInShoppingCart()

  return (
    <Dialog open={open} className={dialogPaper} onClose={onCancel}>
      <IconButton
        color="primary"
        onClick={onCancel}
        size="large"
        data-cy={dataCy('button-dialog-close')}
        sx={(t) => ({
          position: 'absolute',
          right: 0,
          background: t.palette.primary.main,
          transform: 'translate(50%, -50%)',
          '&:hover': {
            background: t.palette.primary.dark,
          },
        })}
      >
        <Close fontSize="small" sx={(t) => ({ color: t.palette.background.paper })} />
      </IconButton>
      <DialogContent className={dialog}>
        <Loader isLoading={isFetching || isAdding} objects={shoppingCartItems?.data?.items}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" className={header}>
                {t('shopping_cart')} ({shoppingCartItems?.data?.items?.length || 0})
              </Typography>
            </Grid>
            {warning && (
              <Grid item xs={12}>
                <Alert severity="error">{warning}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {shoppingCartItems?.data?.items.map((item) => (
              <Fragment key={item.id}>
                <Grid item xs={12}>
                  <CartItem item={item} onClose={onCancel} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={3} className={buttons}>
                <Grid item xs>
                  <Button color="primary" fullWidth variant="outlined" onClick={() => navigate('/catalog')}>
                    {t('shopping_cart.back_to_catalog')}
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    data-cy={dataCy('button-cart-visit')}
                    onClick={() => {
                      navigate('/shopping-cart')
                      window.scrollTo({ top: 0 })
                    }}
                    disabled={isSingleLogic && amountItems > 1}
                  >
                    {t('shopping_cart.go_to')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Loader>
      </DialogContent>
    </Dialog>
  )
}
