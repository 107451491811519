import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import myScoreQueryKeys from 'utils/api/MyScore/myScoreQueryKeys'
import { postRecognition } from 'utils/api/SocialWall/postRecognition'
import socialWallQueryKeys from 'utils/api/SocialWall/socialWallQueryKeys'
import userQueryKeys from 'utils/api/User/userQueryKeys'
import { PostsQueryData, useSWQueryHelpers } from 'utils/hooks/api/SocialWall/utils'

type Props = {
  onSuccess?: () => void
}

export const useRecognizeSomeone = ({ onSuccess }: Props) => {
  const { invalidateSWUser, invalidateAllPosts, t, enqueueSnackbar } = useSWQueryHelpers()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(postRecognition, {
    onSuccess: (response) => {
      onSuccess?.()
      queryClient.setQueryData<PostsQueryData>(socialWallQueryKeys.postsFiltered(), (old) => {
        const page = old?.pages?.find((el) => el.data.page === 1)
        if (page) {
          const updatedPage = {
            ...page,
            data: {
              ...page.data,
              data: [response.data, ...page.data.data],
            },
          }
          return { ...old, pages: [updatedPage, ...(old.pages?.slice(1) || [])] }
        }
      })
      invalidateSWUser()
      invalidateAllPosts()
      queryClient.invalidateQueries(myScoreQueryKeys.widgetMyScoreLists())
      queryClient.invalidateQueries(userQueryKeys.me())
      enqueueSnackbar(t('recognize_someone.success'), { variant: 'success' })
      navigate(`/social-wall`)
    },
    onError: () => {
      enqueueSnackbar(t('recognize_someone.fail'), { variant: 'error' })
    },
  })
}
