import React from 'react'
import { useTranslation } from 'react-i18next'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { MessageBaseProps } from 'components/Message/types'
import { sxSize } from 'components/Message/utils'
import { useIsTouchDevice } from 'utils/hooks/useIsTouchDevice'
import { makeSx } from 'utils/styles/makeSx'

export type MessageBottomBarProps = Pick<
  MessageBaseProps,
  'maxLengthOverseeded' | 'maxLength' | 'bottomBarText' | 'size'
> & {
  onEmojiButtonClick: () => void
  textLength?: number
  absolute?: boolean
  isEmojiOpen: boolean
}

export const MessageBottomBar = ({
  onEmojiButtonClick,
  textLength,
  absolute,
  maxLength,
  maxLengthOverseeded,
  isEmojiOpen,
  bottomBarText,
  size,
}: MessageBottomBarProps) => {
  const isTouchDevice = useIsTouchDevice()
  const { t } = useTranslation()
  const position = makeSx((theme) =>
    absolute
      ? {
          position: 'absolute',
          right: theme.spacing(sxSize(size)(theme).px),
          top: '50%',
          transform: 'translateY(-50%)',
          width: 'auto',
        }
      : {}
  )
  const textColor = makeSx((theme) => ({
    color: theme.palette.grey[400],
  }))
  const sxBox = makeSx((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...position(theme),
  }))

  return (
    <Box sx={sxBox}>
      <Box>
        {bottomBarText && (
          <Typography variant="body2" sx={{ pr: 2, color: (t) => textColor(t).color }}>
            {t(bottomBarText)}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: (t) => (!maxLengthOverseeded ? textColor(t).color : t.palette.error.main) }}
        >{`${textLength}${maxLengthOverseeded ? ` \\ ${maxLength}` : ''}`}</Typography>
        {!isTouchDevice && (
          <Box sx={{ pl: 1, mr: '-10px' }}>
            <IconButton size="small" color="primary" onClick={onEmojiButtonClick}>
              {isEmojiOpen ? <EmojiEmotionsIcon /> : <SentimentVerySatisfiedIcon />}
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}
