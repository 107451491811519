import { initReactI18next } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { options } from 'i18n'
import i18n from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { useAuthContext } from 'store/useAuthContext'
import en from 'translations/en.json'
import { getRequest } from 'utils/api/apiRequest'
import { Language, LanguageSubscriber } from 'utils/api/Languages/types'
import { WithPagination } from 'utils/api/pagination'
import { userTypes } from 'utils/hooks/userTypes'
import { mapLangsToLBGlobalPage } from 'utils/mapLangsToLBGlobalPage'
import { orderLanguages } from 'utils/orderLanguages'

const overrideOptions = {
  supportedLngs: ['en'],
  fallbackLng: 'en',
  resources: { en: { translation: en } },
  debug: process.env.NODE_ENV !== 'production',
}

export const getLanguages = (type: 'subscriber' | 'project') =>
  getRequest<WithPagination<LanguageSubscriber>>(`/${type}/languages/front`)?.then((data) => orderLanguages(data?.data))

export const getGlobalLanguages = () =>
  getRequest<WithPagination<Language>>('/languages')?.then((data) => orderLanguages(mapLangsToLBGlobalPage(data?.data)))

export const useLanguages = () => {
  const { data: subdomainInfo, isSuccess } = useSubdomainInfo()
  const isProjectSite = subdomainInfo?.data?.type === SiteType.project
  const isLuckyBirdGlobal = subdomainInfo?.data?.type === SiteType.luckyBirdGlobal
  const userContext = useAuthContext(true)

  if (!!isSuccess && !subdomainInfo?.data?.type) {
    i18n
      .use(ChainedBackend)
      .use(I18nextBrowserLanguageDetector)
      .use(initReactI18next)
      .init({
        ...options,
        ...overrideOptions,
      })
  }

  if (isLuckyBirdGlobal) {
    return useQuery({
      enabled: !!isSuccess && !!subdomainInfo?.data?.type,
      queryKey: ['languages', 'global'],
      queryFn: () => getGlobalLanguages(),
      onSuccess: (languages) => {
        const supportedLngs = [...new Set(languages?.data?.map((l) => l.language.code))]
        const fallbackLng = languages?.data?.find((l) => l.default)?.language?.code || 'en'

        i18n
          .use(ChainedBackend)
          .use(I18nextBrowserLanguageDetector)
          .use(initReactI18next)
          .init({ ...options, supportedLngs, fallbackLng })
      },
      onError: () => {
        i18n
          .use(ChainedBackend)
          .use(I18nextBrowserLanguageDetector)
          .use(initReactI18next)
          .init({
            ...options,
            ...overrideOptions,
          })
      },
    })
  }

  return useQuery({
    enabled: !!isSuccess && !!subdomainInfo?.data?.type,
    queryKey:
      isProjectSite || userContext?.user?.type === userTypes.lucky_bird
        ? ['languages', 'project']
        : ['languages', 'subscriber'],
    queryFn: () =>
      getLanguages(isProjectSite || userContext?.user?.type === userTypes.lucky_bird ? 'project' : 'subscriber'),
    onSuccess: (languages) => {
      const supportedLngs = [...new Set(languages?.data?.map((l) => l.language.code))]
      const fallbackLng = languages?.data?.find((l) => l.default)?.language?.code || 'en'

      i18n
        .use(ChainedBackend)
        .use(I18nextBrowserLanguageDetector)
        .use(initReactI18next)
        .init({ ...options, supportedLngs, fallbackLng })
    },
    onError: () => {
      i18n
        .use(ChainedBackend)
        .use(I18nextBrowserLanguageDetector)
        .use(initReactI18next)
        .init({
          ...options,
          ...overrideOptions,
        })
    },
  })
}

export { i18n }
