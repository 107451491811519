import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AvatarGroup from '@mui/material/AvatarGroup'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Avatar from 'components/Avatar'
import { MessageUserWithTooltip, UserNameWithAvatar } from 'components/Message/components/MessageUserWithTooltip'
import { MessageBaseProps } from 'components/Message/types'
import { LARGE_AVATAR, MAX_AVATARS } from 'components/Message/utils'
import { getResponsiveSize } from 'theme/styles.utils'
import { RecognizingUser } from 'utils/api/SocialWall/types'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

const SingleRecognitionUser = ({ recognitionUser }: { recognitionUser: RecognizingUser }) => {
  const isMobile = useIsMobile()
  return (
    <Box
      sx={{
        pl: valueOrUndefined(!isMobile, 3),
      }}
    >
      <MessageUserWithTooltip user={recognitionUser} dimensions={LARGE_AVATAR.SIZE} fontSize={LARGE_AVATAR.FONT} />
    </Box>
  )
}

const ManyRecognitionUsers = ({ recognitionUsers }: { recognitionUsers: RecognizingUser[] }) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const showAsGroup = recognitionUsers.length >= MAX_AVATARS && isMobile

  const renderUsers = () => recognitionUsers.map((el) => <MessageUserWithTooltip user={el} key={el.id} />)
  const renderUsersGroup = () => (
    <>
      <AvatarGroup
        spacing={16}
        max={MAX_AVATARS}
        slotProps={{
          additionalAvatar: {
            onClick: () => setOpen(true),
            sx: {
              border: 'none',
              width: getResponsiveSize(LARGE_AVATAR.SIZE),
              height: getResponsiveSize(LARGE_AVATAR.SIZE),
              fontSize: `${getResponsiveSize(LARGE_AVATAR.FONT)}rem`,
            },
          },
        }}
      >
        {recognitionUsers.map((el) => (
          <Avatar
            height={LARGE_AVATAR.SIZE}
            width={LARGE_AVATAR.SIZE}
            fontSize={LARGE_AVATAR.FONT}
            fullName={el.profile.full_name}
            image={el.profile.avatar}
            key={el.id}
          />
        ))}
      </AvatarGroup>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="paper"
        PaperProps={{ sx: { maxHeight: 400, width: '100%', maxWidth: 300 } }}
      >
        <DialogTitle variant="h3" textAlign="center" fontWeight={600}>
          {t('recognition_post.recognitions', { amount: recognitionUsers.length })}
        </DialogTitle>
        <DialogContent dividers>
          {recognitionUsers.map((el) => (
            <UserNameWithAvatar user={el} key={el.id} pb={1}>
              <Typography pl={1} variant="body2" fontWeight={600}>
                {el.profile.job_title}
              </Typography>
              <Typography pl={1} variant="body2" sx={{ color: (t) => t.palette.grey[600] }}>
                {el.profile.department?.name}
              </Typography>
            </UserNameWithAvatar>
          ))}
        </DialogContent>
      </Dialog>
    </>
  )

  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: isMobile ? '1fr' : 'auto  1fr',
        columnGap: 2,
        rowGap: 1,
        justifyItems: 'start',
      }}
    >
      {showAsGroup ? renderUsersGroup() : renderUsers()}
    </Box>
  )
}

type MessageUsersProps = Pick<MessageBaseProps, 'author' | 'recognitionUsers'>
export const MessageTopUsers = ({ recognitionUsers = [], author }: MessageUsersProps) => {
  return (
    <Grid container spacing={2}>
      {!!recognitionUsers.length && (
        <Grid item xs pb={2}>
          {recognitionUsers.length === 1 ? (
            <SingleRecognitionUser recognitionUser={recognitionUsers[0]} />
          ) : (
            <ManyRecognitionUsers recognitionUsers={recognitionUsers} />
          )}
          {!!author && (
            <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', mb: -2 }}>
              {author && <MessageUserWithTooltip user={author} author />}
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  )
}
