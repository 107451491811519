import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { MAIN_DOMAIN, PROTOCOL } from 'config/env'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { useStyles } from 'theme/templateStyles'
import { useSocialWall } from 'utils/hooks/useSocialWall'

export const LogoWeb = () => {
  const { csSubscriberName } = useStyles()
  const { data: subdomainInfo } = useSubdomainInfo()
  const subscriberName = subdomainInfo?.data?.subscriber?.name
  const navigate = useNavigate()
  const { isSocialWallActive } = useSocialWall()
  const cmsUrl = subdomainInfo?.data?.subscriber?.sites?.find((site) => site.type === 2)?.subdomain

  const isRedemptionSite = subdomainInfo?.data?.type === SiteType.project

  const onClick = () => {
    if (isRedemptionSite) {
      return window.location.assign(`${PROTOCOL}://${cmsUrl}.${MAIN_DOMAIN}${isSocialWallActive ? '/social-wall' : ''}`)
    }

    return navigate(isSocialWallActive ? '/social-wall' : '/')
  }

  return (
    <Box
      sx={{
        width: 'fit-content',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        paddingLeft: 0,
      }}
      onClick={onClick}
    >
      <Typography variant="h2" align="center" className={csSubscriberName}>
        {subscriberName}
      </Typography>
    </Box>
  )
}
