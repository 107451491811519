import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Breadcrumbs, MobileBreadcrumbs } from 'components/Breadcrumbs'
import { CountrySelectButton } from 'components/CountryContextDialog/CountrySelectButton'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { ShopAllButton } from 'components/HeaderPage/ShopAllButton'
import { PointsWrapper } from 'components/WhitePaper/Toolbar'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { CategoryList } from 'pages/HomeRedemptionSite/CategoryList'
import { DesktopFilterComponent, DesktopSortComponent } from 'pages/HomeRedemptionSite/components'
import { ProductsList } from 'pages/HomeRedemptionSite/ProductsList'
import {
  getBreadcrumbsSegments,
  ROOT_CATEGORY_ID,
  SEARCH_PRODUCT,
  useHomeRSUtils,
  useShowFiltersAndSorting,
} from 'pages/HomeRedemptionSite/utils'
import { CategoriesDrawerButton } from 'theme/Mobile/CategoriesDrawer/CategoriesDrawerButton'
import { displayPoints } from 'utils/displayPoints'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'

// eslint-disable-next-line complexity
export const HomeRedemptionSite: FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const { showFilters } = useShowFiltersAndSorting()
  const { currentCategoriesPath, isCategoriesPage, filtersEnabled, searchEnabled, isRootCategory } = useHomeRSUtils()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const user = useCurrentUser()
  const pointsName = usePointsName(user?.available_points)
  const { isSingleLogic } = useIsLuckyBirdUser()

  const isProductList = !isCategoriesPage || searchEnabled || filtersEnabled
  const isCatalog = isCategoriesPage && !filtersEnabled && !searchEnabled
  const isRootCatalogAndDisabledCategoryTree = isRootCategory && !user?.show_categories_tree
  const isMainCatalogAndDisabledCategoryTree = isCategoriesPage && !user?.show_categories_tree

  if (isMainCatalogAndDisabledCategoryTree) {
    return <Navigate to={`/catalog/categories/${ROOT_CATEGORY_ID}`} />
  }

  const renderLeftHeaderSide = () => {
    switch (true) {
      case isCategoriesPage && isTablet:
        return <CategoriesDrawerButton />
      case isCategoriesPage || isRootCatalogAndDisabledCategoryTree:
        return <Box width="1px" height="1px" />
      case isMobile:
        return <MobileBreadcrumbs segments={getBreadcrumbsSegments(currentCategoriesPath, t, i18n.language)} />
      case !isMobile:
        return <Breadcrumbs segments={getBreadcrumbsSegments(currentCategoriesPath, t, i18n.language)} />
      default:
        return null
    }
  }

  return (
    <WhitePaper
      FilterComponent={
        isSingleLogic
          ? undefined
          : ({ gutterTop }) => (showFilters ? <DesktopFilterComponent gutterTop={gutterTop} /> : null)
      }
      SortComponent={({ gutterTop }) => (showFilters ? <DesktopSortComponent gutterTop={gutterTop} /> : null)}
      searchParam={SEARCH_PRODUCT}
      header={
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ minHeight: 20 }}>
          {renderLeftHeaderSide()}
          {isTablet && <CountrySelectButton />}
          {isTablet && !isSingleLogic && (
            <PointsWrapper item>
              {displayPoints(user?.available_points)} {pointsName}
            </PointsWrapper>
          )}
        </Box>
      }
    >
      <Grid container spacing={2}>
        {isProductList && <ProductsList />}
        {isCatalog && (
          <>
            <Grid item xs={12}>
              <HeaderPage text={t('categories')} size="medium" rightComponent={<ShopAllButton />} />
            </Grid>
            <Grid item xs={12}>
              <CategoryList />
            </Grid>
          </>
        )}
      </Grid>
    </WhitePaper>
  )
}
