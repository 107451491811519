import { useQuery } from '@tanstack/react-query'
import { getRequest } from 'utils/api/apiRequest'
import { Country } from 'utils/api/country'
import { WithPagination } from 'utils/api/pagination'

export const availableCountriesKey = ['all', 'availableCountries']

export const useGetAvailableCountries = () => {
  return useQuery({
    queryKey: availableCountriesKey,
    queryFn: () => getRequest<WithPagination<Country>>('/user/available-countries?limit=1000'),
    select: (data) => data?.data,
  })
}
