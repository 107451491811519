import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const Message2 = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon
    {...rest}
    viewBox="0 0 141.8 117.8"
    // @ts-ignore
    style={{ enableBackground: 'new 0 0 141.8 117.8' }}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M118.8,2.9h-97c-10,0-17.7,7.8-17.7,17.7v52.6c0,10,7.8,17.7,17.7,17.7h12.7v18.3c0,2.2,1.7,4.4,3.3,5  c0.6,0,1.7,0.6,2.2,0.6c1.7,0,2.8-0.6,4.4-1.7l22.7-22.7h52.6c10,0,17.7-7.8,17.7-17.7V20.1C136.6,11.2,128.8,2.9,118.8,2.9  L118.8,2.9z M35.7,57.8c-5.5,0-10.5-5-10.5-10.5s5-10.5,10.5-10.5s10.5,5,10.5,10.5S41.2,57.8,35.7,57.8z M71.2,57.8  c-5.5,0-10.5-5-10.5-10.5s5-10.5,10.5-10.5c5.5,0,10.5,5,10.5,10.5S76.7,57.8,71.2,57.8z M106.1,57.8c-5.5,0-10.5-5-10.5-10.5  s5-10.5,10.5-10.5s10.5,5,10.5,10.5C116.6,52.8,111.6,57.8,106.1,57.8L106.1,57.8z"
    />
  </SvgIcon>
)
