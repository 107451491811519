import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { ComponentProps, JSX } from 'react'
import Slider from 'react-slick'
import { useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { Arrow } from 'components/ArteelIcons'

const translate = 30
const sx = { position: 'absolute', top: '50%' }

const NextArrow = ({ onClick }: { onClick?: () => void }) => {
  return (
    !!onClick && (
      <IconButton
        color="primary"
        size="large"
        onClick={onClick}
        sx={{
          ...sx,
          right: 0,
          transform: `translate(${translate}%, -50%)`,
        }}
      >
        <Arrow fontSize="large" />
      </IconButton>
    )
  )
}
const PrevArrow = ({ onClick }: { onClick?: () => void }) => {
  return (
    !!onClick && (
      <IconButton
        color="primary"
        size="large"
        onClick={onClick}
        sx={{
          ...sx,
          transform: `translate(-${translate}%, -50%)`,
          left: 0,
          zIndex: 1,
        }}
      >
        <Arrow fontSize="large" direction="left" />
      </IconButton>
    )
  )
}

interface Props {
  children: JSX.Element[]
  lineGrid?: boolean
}
export const HorizontalSlider = ({ children, lineGrid }: Props) => {
  const {
    breakpoints: {
      values: { sm, md, xl },
      down,
    },
  } = useTheme()
  const isMobile = useMediaQuery(down('sm'))

  const settings: ComponentProps<typeof Slider> = {
    accessibility: false,
    adaptiveHeight: false,
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    speed: 200,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: xl,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const mappedChildren = children.map((child, i) => (
    <Box
      key={i}
      sx={(t) => ({
        p: 3,
      })}
    >
      {child}
    </Box>
  ))
  return (
    <Box
      sx={(t) =>
        lineGrid
          ? {
              '& .slick-slide.slick-active': {
                borderLeft: !isMobile ? `1px solid ${t.palette.divider}` : undefined,
                '&.slick-current': {
                  borderLeft: 'none',
                },
              },
            }
          : {}
      }
    >
      <Slider {...settings}>{mappedChildren}</Slider>
    </Box>
  )
}
