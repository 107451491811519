import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { MessageBaseProps } from 'components/Message/types'

type MessageEditButtonProps = Pick<MessageBaseProps, 'onEditClick'>
export const MessageEditButton = ({ onEditClick }: MessageEditButtonProps) => {
  return (
    <IconButton
      color="primary"
      onClick={onEditClick}
      data-cy="edit-button"
      sx={{
        position: 'absolute',
        right: 2,
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <EditIcon />
    </IconButton>
  )
}
