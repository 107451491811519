import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Avatar from 'components/Avatar'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const RecognizeSomeoneMe = () => {
  const user = useCurrentUser()
  const { t } = useTranslation()
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Avatar height={80} width={80} fullName={user?.profile?.full_name} image={user?.profile?.avatar} />
      </Grid>
      <Grid item xs>
        <Typography fontWeight={600} color="primary" variant="h1">
          {t('recognize_someone.hi', { name: user?.profile?.first_name })}
        </Typography>
        <Typography variant="h2">{t('recognize_someone.who_recognize')}</Typography>
      </Grid>
    </Grid>
  )
}
