import { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import Loader from 'components/Loader/Loader'
import { TextButton } from 'components/TextButton'
import { Brand } from 'utils/api/Brands/fetchBrands'
import { WithPagination } from 'utils/api/pagination'
import { useBrands } from 'utils/hooks/api/useBrands'

import { useStyles } from './styles'

const getTopBrands = (brands: WithPagination<Brand>) =>
  brands?.data?.length ? brands?.data.slice(0, 4) : ([] as Brand[])

export const TopBrands: FunctionComponent = () => {
  const { brandsContainer, brandBox, heading, showAll, topBrandsSection } = useStyles()
  const { t } = useTranslation()
  const { data, isFetching } = useBrands()
  const navigate = useNavigate()
  const { closeDrawer } = useContext(drawerContext)
  const brands = data?.data

  const handleClickLink = (path: string) => {
    navigate(path)
    closeDrawer()
  }

  return (
    <div className={topBrandsSection}>
      <Loader isLoading={isFetching} objects={brands?.data}>
        <div className={heading}>{t('shop_all.topBrands')}</div>
        <div className={brandsContainer}>
          {getTopBrands(brands).map((brand) => (
            <div key={brand.id} className={brandBox}>
              <TextButton onClick={() => handleClickLink(`/catalog/brands/${brand.id}`)}>{brand.name}</TextButton>
            </div>
          ))}
        </div>
        <TextButton className={clsx(showAll)} onClick={() => handleClickLink('/catalog/brands')}>
          {t('shop_all.show_all')}
        </TextButton>
      </Loader>
    </div>
  )
}
