import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { MenuItem } from 'components/FooterMenu/MenuItem'
import { MenuSpacer } from 'components/FooterMenu/MenuSpacer'
import { APP_VERSION, ENVIRONMENT } from 'config/env'
import { useCookiesContext } from 'store/useCookiesContext'
import { useLogout } from 'utils/hooks/useLogout'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 0),
  },
}))

export const Footer = () => {
  const copyrightYear = new Date().getFullYear()
  const { footer } = useStyles()
  const { t } = useTranslation()
  const { DialogLogout, onClickLogout } = useLogout()
  const navigate = useNavigate()
  const { openDetails } = useCookiesContext()

  const footerItems = [
    {
      name: 'copyright',
      content: <MenuItem>&copy; Arteel {copyrightYear}</MenuItem>,
      onMobile: true,
    },
    {
      name: 'help',
      content: <MenuItem onClick={() => navigate(`/service-request`)}>{t('app.help')}</MenuItem>,
      onMobile: false,
    },
    {
      name: 'contact',
      content: <MenuItem onClick={() => navigate(`/contact`)}>{t('app.contact')}</MenuItem>,
      onMobile: false,
    },
    {
      name: 'privacyTerms',
      content: <MenuItem onClick={() => navigate(`/privacy-and-terms`)}>{t('app.privacy_terms')}</MenuItem>,
      onMobile: true,
    },
    {
      name: 'cookiesManagement',
      content: <MenuItem onClick={() => openDetails()}>{t('cookies.management')}</MenuItem>,
      onMobile: true,
    },
    {
      name: 'logout',
      content: <MenuItem onClick={onClickLogout}>{t('app.logout')}</MenuItem>,
      onMobile: false,
    },
    {
      ...(ENVIRONMENT !== 'prod' && {
        name: 'version',
        content: <MenuItem>{APP_VERSION}</MenuItem>,
        onMobile: false,
      }),
    },
  ]

  return (
    <Grid container spacing={2} justifyContent="center" className={footer}>
      {footerItems.map((item, index, arr) => (
        <React.Fragment key={item.name}>
          {item.content}
          {index !== arr.length - 1 && <MenuSpacer />}
        </React.Fragment>
      ))}
      <DialogLogout />
    </Grid>
  )
}
