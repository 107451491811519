import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import { ArrowDown } from 'components/ArteelIcons'
import { useFormikContext } from 'formik'
import { useGetPersonalTemplatesByOccasionId } from 'pages/PersonalMessages/api/useGetPersonalTemplatesByOccasionId'
import { useGetSubscriberTemplatesByOccasionId } from 'pages/PersonalMessages/api/useGetSubscriberTemplatesByOccasionId'
import { Label } from 'pages/PersonalMessages/components/Label'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { removeHTML } from 'utils/removeHTML'
import { important } from 'utils/styles'

type Props = {
  occasionId: number
}

export const ReuseTemplates = ({ occasionId }: Props) => {
  const [collapsed, setCollapsed] = useState(false)
  const { t } = useTranslation()

  const { data } = useGetSubscriberTemplatesByOccasionId(occasionId)
  const { data: personalData } = useGetPersonalTemplatesByOccasionId(occasionId)

  const { setFieldValue } = useFormikContext()

  const handleClick = (message: string) => {
    setFieldValue('message', removeHTML(message))
  }

  const personalMessageTemplates = personalData?.data?.data?.flatMap(
    (personalMessageTemplate) => personalMessageTemplate.message_templates_user
  )

  const subscriberMessageTemplates = data?.data?.data?.flatMap(
    (subscriberTemplate) => subscriberTemplate.message_templates
  )

  return (
    <>
      <Grid item xs={12}>
        <Button
          color="primary"
          fullWidth
          sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          endIcon={
            <ArrowDown
              sx={{
                fontSize: '1rem!important',
                fontWeight: 'bold',
                transition: '0.3s ease all',
                transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          }
          onClick={() => setCollapsed((prev) => !prev)}
        >
          {t('personal_messages.add.reuse_existing_template')?.toUpperCase()}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ pt: valueOrUndefined(!collapsed, important('0')), transition: '0.3s ease all' }}>
        <Collapse in={collapsed} mountOnEnter unmountOnExit>
          <Grid container spacing={2}>
            {!!personalMessageTemplates?.length && (
              <>
                <Grid item xs={12}>
                  <Label translationKey="personal_messages.add.your_templates" />
                </Grid>
                <Grid item xs={12}>
                  <Box display="grid" gridColumn="1fr" gap={2} sx={{ px: { xs: 4, md: 6, lg: 8 } }}>
                    {personalMessageTemplates?.map((template) => (
                      <Button
                        key={template.id}
                        variant="outlined"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => handleClick(template?.content)}
                        sx={{ textTransform: 'uppercase' }}
                      >
                        {template?.name}
                      </Button>
                    ))}
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Label translationKey="personal_messages.add.default_templates" />
            </Grid>
            <Grid item xs={12}>
              <Box display="grid" gridColumn="1fr" gap={2} sx={{ px: { xs: 4, md: 6, lg: 8 } }}>
                {subscriberMessageTemplates?.map((template) => (
                  <Button
                    key={template.id}
                    variant="outlined"
                    color="primary"
                    size="small"
                    fullWidth
                    onClick={() => handleClick(getObjectTranslation(template?.translation)?.content)}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {getObjectTranslation(template?.translation)?.name}
                    {template?.default && ` - ${t('personal_messages.add.default')}`}
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Collapse>
      </Grid>
    </>
  )
}
