import React from 'react'
import { CardGrid } from 'components/CardGrid/CardGrid'
import { Birthday } from 'utils/hooks/api/Birthdays/useBirthdays'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { useMonths } from 'utils/hooks/useMonths'

import { BirthdayView } from './BirthdayView'

type Props = {
  birthdays: Birthday[]
}

export const BirthdaysGrid = ({ birthdays }: Props) => {
  const { months } = useMonths()
  const isTablet = useIsTablet()

  return (
    <CardGrid
      headerSx={(theme) => ({
        fontSize: theme.typography.h2.fontSize,
        textTransform: 'capitalize',
      })}
      headerAreaSize={{ md: 2, xs: 3 }}
      hideHeaderRow={isTablet}
      noRecords={!birthdays.length}
      rows={months.map((month) => {
        const birthdayObject = birthdays.find((birthday) => birthday.month === month.month)

        return {
          id: month.elementId,
          text: month.longName,
          content: <BirthdayView persons={birthdayObject?.persons || []} />,
          isEmpty: birthdayObject === undefined || birthdayObject.persons.length === 0,
        }
      })}
    />
  )
}
