import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { Form, Formik } from 'formik'
import { useGetPersonalMessage } from 'pages/PersonalMessages/api/useGetPersonalMessage'
import { usePatchPersonalMessage } from 'pages/PersonalMessages/api/usePatchPersonalMessage'
import { usePostPersonalTemplate } from 'pages/PersonalMessages/api/usePostPersonalTemplate'
import { EditPersonalMessageForm } from 'pages/PersonalMessages/Edit/EditPersonalMessageForm'
import { FormValues } from 'pages/PersonalMessages/Edit/types'
import { getResponsiveSize } from 'theme/styles.utils'
import { containEmoji } from 'utils/formik/containEmoji'
import * as Yup from 'yup'

export const EditPersonalMessage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data, isLoading } = useGetPersonalMessage({ isEdit: true })
  const onBack = () => navigate('/personal-messages')
  const { mutate, isLoading: isSubmitting } = usePatchPersonalMessage(onBack)
  const { mutate: submitTemplate } = usePostPersonalTemplate()
  const triggerGift = data?.triggered_gift

  const initialValues: FormValues = {
    message: data?.has_content ? data?.content : '',
    saveAsTemplate: false,
    name: '',
    occasion: data?.triggered_gift?.occasion?.id,
  }
  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .required()
      .min(1)
      .max(65535)
      .test('no-emoji', t('personal_messages.add.validation.no_emojis'), containEmoji),
    saveAsTemplate: Yup.boolean(),
    name: Yup.string().when(['saveAsTemplate'], ([saveAsTemplate], _) =>
      saveAsTemplate ? Yup.string().required() : Yup.string().nullable()
    ),
  })
  const onSubmit = (values: typeof initialValues) => {
    mutate({ content: values.message })
    if (values.saveAsTemplate) {
      submitTemplate({
        name: values.name,
        occasion: values.occasion,
        content: values.message,
      })
    }
  }

  return (
    <ResponsivePaper data-cy="edit-personal-message-desktop">
      <Box position="absolute" right={getResponsiveSize(10, 'px')} top={0}>
        <GoBackButton translation="app.back" noPx onClick={onBack} />
      </Box>
      <Grid container justifyContent="center" spacing={6}>
        <Grid item xs={12}>
          <HeaderPage
            data-cy="edit-personal-message-header"
            text={t('personal_messages.add.header')}
            color="medium"
            uppercase={false}
          />
        </Grid>
        <Grid item xs={12} sm={10} lg={10} xl={8}>
          <Loader isLoading={isLoading}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <EditPersonalMessageForm isLoading={isSubmitting} triggerGift={triggerGift} onBack={onBack} />
              </Form>
            </Formik>
          </Loader>
        </Grid>
      </Grid>
    </ResponsivePaper>
  )
}
