import clsx from 'clsx'
import { makeStyles } from 'utils/styles/makeStyles'
import { FCWithChildren } from 'utils/types/FCWithChildren'

const useStyles = makeStyles((theme) => ({
  textButton: {
    color: theme.palette.text.primary,
    transition: '0.3s all',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}))

type Props = {
  onClick?: () => void
  className?: string
}

export const TextButton: FCWithChildren<Props> = ({ className, children, onClick }) => {
  const { textButton, pointer } = useStyles()
  return (
    <span onClick={onClick} className={clsx(textButton, onClick && pointer, className)} role="button" tabIndex={0}>
      {children}
    </span>
  )
}
