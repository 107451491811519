import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Search from '@mui/icons-material/Search'
import { badgeClasses } from '@mui/material'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { ShoppingBasket } from 'components/ArteelIcons'
import isEmpty from 'lodash/isEmpty'
import { MobileMenuButton } from 'pages/CMS/components/MobileMenuButton'
import { useShowFiltersAndSorting } from 'pages/HomeRedemptionSite/utils'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import SearchContext from 'store/SearchContext'
import { FilterAndSearchMobile } from 'theme/Mobile/FilterAndSearch/FilterAndSearchMobile'
import { ProductFilterSortMobile } from 'theme/Mobile/FilterAndSearch/ProductFilterSortMobile'
import { inside } from 'utils/styles'

import { TopBarGridMobile } from './TopBarGridMobile'
import { WelcomeText } from './WelcomeText'

export const ProjectTopbarMobile = () => {
  const { data: shoppingCartItems } = useSelectShoppingCart()
  const quantityArray = shoppingCartItems?.data?.items?.map((value) => value.quantity)
  const { showFilters } = useShowFiltersAndSorting()
  const navigate = useNavigate()

  const { onToggleSearch } = useContext(SearchContext)
  const renderBasket = () => (
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      max={99}
      sx={{
        [inside(badgeClasses.badge)]: {
          lineHeight: '20px',
        },
      }}
      badgeContent={isEmpty(quantityArray) ? 0 : quantityArray.reduce((prev, curr) => prev + curr) || 0}
      color="primary"
      onClick={() => navigate('/shopping-cart')}
    >
      <ShoppingBasket color="primary" sx={{ mr: 2 }} />
    </Badge>
  )

  const { LeftComponent, RightComponent, MidComponent } = showFilters
    ? {
        LeftComponent: (
          <Box display="grid" gridTemplateColumns="auto 1fr" columnGap={1} alignItems="center">
            <MobileMenuButton />
            <WelcomeText />
          </Box>
        ),
        MidComponent: null,
        RightComponent: (
          <FilterAndSearchMobile
            basket={renderBasket()}
            showFiltersBadge={false}
            search={
              <IconButton size="small" onClick={onToggleSearch}>
                <Search color="primary" fontSize="large" />
              </IconButton>
            }
            filters={<ProductFilterSortMobile />}
          />
        ),
      }
    : {
        LeftComponent: <MobileMenuButton />,
        MidComponent: <WelcomeText />,
        RightComponent: (
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>{renderBasket()}</Grid>
            <Grid item>
              <IconButton size="small" onClick={onToggleSearch}>
                <Search color="primary" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        ),
      }

  return <TopBarGridMobile LeftComponent={LeftComponent} MidComponent={MidComponent} RightComponent={RightComponent} />
}
