import { FunctionComponent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import clsx from 'clsx'
import { Arrow } from 'components/ArteelIcons'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import Loader from 'components/Loader/Loader'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { TextButton } from 'components/TextButton'
import { ROOT_CATEGORY_ID } from 'pages/HomeRedemptionSite/utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useCategories } from 'utils/hooks/api/useCategories'

import { useStyles } from './styles'
import { TopBrands } from './TopBrands'

export const ShopAll: FunctionComponent = () => {
  const {
    activeCategoryHeader,
    activeItem,
    columnHeader,
    count,
    heading,
    inLastRow,
    inFirstRow,
    inlineList,
    level1,
    level2,
    level3,
    level3Item,
    listItem,
    showAll,
    wrapper,
  } = useStyles()
  const { t } = useTranslation()
  const showCategoriesTree = useShowCategoriesTree()
  const { data, isFetching } = useCategories(!showCategoriesTree)
  const [activeCategory, setActiveCategory] = useState<{ id: number; name: string }>()
  const navigate = useNavigate()
  const { closeDrawer } = useContext(drawerContext)
  const categories = data?.data?.categories_tree?.[0]?.__children || []

  const handleClickLink = (path: string) => {
    closeDrawer()
    navigate(path)
  }

  const showAllCategories = () => {
    closeDrawer()
    navigate(`/catalog/categories/${ROOT_CATEGORY_ID}`)
  }

  const selectedCategory = categories?.find((category) => category.id === activeCategory?.id)
  const theme = useTheme()

  return (
    <Loader isLoading={isFetching}>
      <Container maxWidth="lg" sx={{ [theme.breakpoints.up(1921)]: { maxWidth: 2400 } }}>
        <div className={wrapper}>
          {showCategoriesTree && (
            <>
              <Grid container>
                <Grid item xs={12} lg={3} className={clsx(columnHeader, heading)} data-cy="shop_all.categories">
                  {t('shop_all.categories')}
                </Grid>
                <Grid item xs={12} lg={9} className={clsx(columnHeader, heading, activeCategoryHeader)}>
                  {activeCategory?.name}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} lg={3} className={level1}>
                  <Box component="ul" sx={{ paddingInline: 0, margin: 0 }}>
                    {categories?.map((category) => (
                      <Box
                        component="li"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          '&:first-child > span': {
                            paddingTop: theme.spacing(0),
                          },
                        }}
                        key={category.id}
                        className={clsx(category?.id === activeCategory?.id && activeItem)}
                      >
                        <TextButton
                          className={listItem}
                          onClick={() =>
                            setActiveCategory({
                              id: category?.id,
                              name: `${getObjectTranslation(category?.translation)?.name || category?.name || '-'}`,
                            })
                          }
                        >
                          {`${getObjectTranslation(category?.translation)?.name || category?.name || '-'} (${
                            category.count
                          })`}
                        </TextButton>
                        {category?.id === activeCategory?.id && <Arrow fontSize="small" />}
                      </Box>
                    ))}
                    {!!categories?.length && (
                      <li className={clsx(listItem, showAll, activeCategory?.id === -1 && activeItem)}>
                        <TextButton className={clsx(listItem, showAll)} onClick={showAllCategories}>
                          {t('shop_all.show_all')}
                        </TextButton>
                      </li>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} lg={9}>
                  {selectedCategory?.__children?.map((category, i) => {
                    const firstRowClass = i === 0 ? inFirstRow : undefined
                    return (
                      <Grid key={category.id} container>
                        <Grid item xs={12} lg={4} className={clsx(level2, firstRowClass)}>
                          <TextButton onClick={() => handleClickLink(`/catalog/categories/${category.id}`)}>
                            {getObjectTranslation(category?.translation)?.name || category?.name || '-'}{' '}
                            <span className={count}>({category.count || 0})</span>
                          </TextButton>
                        </Grid>
                        <Grid item xs={12} lg={8} className={clsx(level3, firstRowClass)}>
                          <ul className={inlineList}>
                            {category.__children?.map((subCategory) => (
                              <li key={subCategory.id}>
                                <TextButton onClick={() => handleClickLink(`/catalog/categories/${subCategory.id}`)}>
                                  {getObjectTranslation(subCategory?.translation)?.name || subCategory?.name || '-'}{' '}
                                </TextButton>
                              </li>
                            ))}
                          </ul>
                          {category.count > 3 && (
                            <TextButton
                              className={clsx(showAll, level3Item)}
                              onClick={() => handleClickLink(`/catalog/categories/${category.id}`)}
                            >
                              {t('shop_all.show_all')}
                            </TextButton>
                          )}
                        </Grid>
                      </Grid>
                    )
                  })}
                  {selectedCategory && (
                    <Box className={clsx(level2, inLastRow)}>
                      <TextButton
                        className={showAll}
                        onClick={() => handleClickLink(`/catalog/categories/${selectedCategory?.id}`)}
                      >
                        {t('shop_all.show_all_in_category')}
                      </TextButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          <Grid container>
            {showCategoriesTree && <Grid item xs={12} lg={3} />}
            <Grid item xs={12} lg={!showCategoriesTree ? 12 : 9}>
              <TopBrands />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Loader>
  )
}
