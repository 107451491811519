import React from 'react'
import { MessageActive, MessageActiveProps } from 'components/Message/MessageActive'

export interface FormikMessageProps extends Omit<MessageActiveProps, 'onChange' | 'onBlur' | 'error'> {
  name: string
}

export const FormikMessage = ({ name, ...props }: FormikMessageProps) => {
  return <MessageActive name={name} {...props} />
}
