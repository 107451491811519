import React, { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { safeHtml } from 'utils/safeHtml'

export interface IframeProps {
  unsafeHTML: string
}

export const Iframe = ({ unsafeHTML }: IframeProps) => {
  const [contentRef, setContentRef] = useState<any>(null)
  // @ts-ignore
  const mountNode = !!contentRef?.contentWindow?.document?.head

  const appendIframeStyles = debounce(() => {
    const iframeNode = document.getElementById('consent-frame')
    const style = document.createElement('style')
    style.textContent = 'img {' + '  max-width: 100%; height: auto;' + '}'
    // @ts-ignore
    iframeNode?.contentDocument?.head?.appendChild(style)
    /* earlier head styles are overridden by empty head tag */
  }, 40)

  useEffect(() => {
    if (!mountNode) return
    appendIframeStyles()
  }, [mountNode])

  const { __html: srcDoc } = safeHtml(unsafeHTML)
  return (
    <iframe
      id="consent-frame"
      data-cy="iframe"
      title="User consent"
      srcDoc={srcDoc}
      width={'100%'}
      height={'100%'}
      ref={setContentRef}
    />
  )
}
