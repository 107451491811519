import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { ItemListGrid } from 'components/ItemListGrid/ItemListGrid'
import Loader from 'components/Loader/Loader'
import { ROOT_CATEGORY_ID } from 'pages/HomeRedemptionSite/utils'
import { CategoryGridCard } from 'pages/TopRatedProducts/CategoryGridCard'
import { useSubCategories } from 'utils/hooks/api/useSubCategories'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

export const CategoryList = () => {
  const { categoryId } = useParams<{ categoryId: string }>()
  const isMobile = useIsMobile()
  const { data: categoriesData, isLoading } = useSubCategories(Number(categoryId || ROOT_CATEGORY_ID))
  const categories = categoriesData?.data?.subcategories || []
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search } = useLocation()

  return (
    <Loader isLoading={isLoading}>
      {categories?.length ? (
        <ItemListGrid gap={isMobile ? 2 : 5}>
          {categories.map((category) => (
            <CategoryGridCard
              category={category}
              key={category.id}
              onClick={() => navigate(`/catalog/categories/${category.id}${search}`)}
            />
          ))}
        </ItemListGrid>
      ) : (
        <Typography textAlign="center">{t('no_categories')}</Typography>
      )}
    </Loader>
  )
}
