import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { TextField } from 'components/TextField/TextField'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { ServiceRequestUploadFile } from 'pages/CMS/components/ContentBox/ServiceRequest/ServiceRequestUploadFile'
import { ServiceRequestFormValues } from 'utils/api/ServiceRequest/postServiceRequest'
import { createTranslationKey } from 'utils/createTranslationKey'
import { getTextFieldParams } from 'utils/formik/getTextFieldProps'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

type Props = {
  isLoading: boolean
}

export const ServiceRequestForm = ({ isLoading }: Props) => {
  const [width, setWidth] = useState(100)
  const { t } = useTranslation()
  const user = useCurrentUser()
  const navigate = useNavigate()
  const ref = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth)
    }
  }, [ref])

  const { setFieldValue, values, getFieldMeta, getFieldProps } = useFormikContext<ServiceRequestFormValues>()

  const ns = <T extends string>(field: T) => createTranslationKey(field, 'service_request.')

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <HeaderPage text={t(ns('personal_information'))} size="small" color="medium" />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormikTextField name="first_name" inputLabel={ns('first_name')} />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormikTextField name="last_name" inputLabel={ns('last_name')} />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormikTextField name="email" inputLabel={ns('email')} />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormikTextField name="phone_number" inputLabel={ns('phone_number')} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <HeaderPage text={t(ns('information_request'))} size="small" color="medium" />
          </Grid>
          <Grid item xs={12} lg={10}>
            <TextField
              select={!isEmpty(user?.projects)}
              variant="outlined"
              {...getTextFieldParams('project_name', 'service_request.', getFieldMeta, getFieldProps)}
              value={values.project_name || ''}
              onChange={(event) => setFieldValue('project_name', event.target.value)}
            >
              {user?.projects &&
                Object.values(user?.projects).map((pointsGroupedByProject) => (
                  <MenuItem key={pointsGroupedByProject.id} value={pointsGroupedByProject.name}>
                    {pointsGroupedByProject.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormikTextField name="subject" inputLabel={ns('subject')} />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormikTextField name="product" inputLabel={ns('product')} />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormikTextField name="order_reference_id" inputLabel={ns('order_reference_id')} />
          </Grid>
          <Grid item xs={12} lg={10}>
            <Box ref={ref}>
              <FormikTextField name="message" inputLabel={ns('message')} multiline rows={3} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={10}>
            <ServiceRequestUploadFile width={width} />
          </Grid>
          <Grid item xs={12}>
            <FormikButtons
              isLoading={isLoading}
              disabled={isLoading}
              onBack={() => navigate(`/`)}
              textButtonSubmit={ns('send')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
