import { getResponsiveSize, otherColors } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2, 12),
    color: theme.palette.text.primary,
    fontSize: `${getResponsiveSize(1)}rem`,
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h1.fontSize,
    textTransform: 'uppercase',
  },
  columnHeader: {
    extend: 'heading',
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
  activeCategoryHeader: {
    color: theme.palette.primary.main,
  },
  activeItem: {
    position: 'relative',
    listStyleType: 'none',

    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    '& > *': {
      color: theme.palette.primary.main,
    },
  },
  level3Item: {
    padding: theme.spacing(0, 2),
  },
  showAll: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: `${getResponsiveSize(1)}rem`,
    display: 'block',
    listStyleType: 'none',
  },
  level1: {
    padding: theme.spacing(2),
    borderRight: `2px solid ${otherColors.separator}`,
    [theme.breakpoints.down('lg')]: {
      borderTop: '2px solid #e3e2d9',
      borderRight: 'none',
    },
  },
  level2: {
    padding: theme.spacing(2),
    borderTop: '2px solid #e3e2d9',
    borderRight: '2px solid #e3e2d9',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('lg')]: {
      border: 'none',
    },
  },
  level3: {
    padding: theme.spacing(2, 0),
    borderTop: '2px solid #e3e2d9',
  },
  listItem: {
    display: 'inline-block',
    padding: theme.spacing(0.5, 0),
    whiteSpace: 'nowrap',
    listStyleType: 'none',
  },
  inFirstRow: {
    borderTop: 'none',
  },
  inLastRow: {
    borderTop: 'none',
    borderRight: 'none',
  },
  inlineList: {
    paddingInline: 0,
    marginBlock: 0,
    '& > li': {
      position: 'relative',
      display: 'inline-block',
      padding: theme.spacing(0, 2),
      '&:last-child': {
        content: '',
        paddingBottom: theme.spacing(1.5),
      },

      '&:not(:last-child):after': {
        content: '"|"',
        position: 'absolute',
        right: 0,
      },
    },
  },
  count: {
    color: theme.palette.grey[600],
  },
  topBrandsSection: {
    padding: theme.spacing(2),
  },
  brandsContainer: {
    display: 'flex',
    margin: theme.spacing(0, -1),
  },
  brandBox: {
    flex: '0 0 25%',
    width: '25%',
    padding: theme.spacing(1),
    fontSize: `${getResponsiveSize(1.75)}rem`,
    lineHeight: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))
