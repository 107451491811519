import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import DisplayDate from 'components/DisplayDate'
import { PersonalMessage } from 'pages/PersonalMessages/types'
import { dateFormat } from 'utils/dateFormats'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

import { DisplayValue } from './DisplayValue'
import { Label } from './Label'

type Props = {
  triggerGift: PersonalMessage['triggered_gift']
}

export const PersonalMessageDetails = ({ triggerGift }: Props) => {
  const isMobile = useIsMobile()

  return (
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
      <Box display="grid" gridTemplateColumns="auto auto" columnGap={isMobile ? 4 : 13} rowGap={2}>
        <Label translationKey={'personal_messages.add.recipient.name'} />
        <DisplayValue value={triggerGift?.receiver?.full_name} />
        <Label translationKey={'personal_messages.add.recipient.birthday'} />
        <DisplayValue
          value={
            <DisplayDate dateString={triggerGift?.receiver?.birth_date} options={{ formatTemplate: dateFormat }} />
          }
        />
        <Label translationKey={'personal_messages.add.recipient.start_date'} />
        <DisplayValue
          value={
            <DisplayDate dateString={triggerGift?.receiver?.start_date} options={{ formatTemplate: dateFormat }} />
          }
        />
        <Label translationKey={'personal_messages.add.recipient.language'} />
        <DisplayValue value={triggerGift?.receiver?.language?.name} />
        <Label translationKey={'personal_messages.add.recipient.occasion'} />
        <DisplayValue value={getObjectTranslation(triggerGift?.occasion?.translation)?.name} />
        <Label translationKey={'personal_messages.add.recipient.gift'} />
        <DisplayValue value={getObjectTranslation(triggerGift?.gift_option?.translation)?.name} />
      </Box>
    </Grid>
  )
}
