import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Avatar from 'components/Avatar'
import { format } from 'date-fns'
import { getResponsiveSize } from 'theme/styles.utils'
import { BirthdayPerson } from 'utils/api/Birthdays/BirthdayPerson'
import { useDateLocale } from 'utils/hooks/useDateLocale'

const Container = styled(Box)({
  maxWidth: '300px',
  width: '100%',
  borderRadius: '15px',
  position: 'relative',
  marginTop: getResponsiveSize(100) / 2,
  overflow: 'visible',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
})

const AvatarContainer = styled(Box)({
  position: 'absolute',
  top: `-${getResponsiveSize(100) / 2}px`,
  left: '50%',
  transform: 'translate(-50%, 0)',
})

const ContentBox = styled(Box)({
  borderRadius: 'inherit',
  overflow: 'hidden',
})

const UpperBox = styled(Box)(({ theme }) => ({
  paddingTop: getResponsiveSize(100) / 2,
  textAlign: 'center',
  background: theme.palette.background.default,
}))

const BottomBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h3.fontSize,
}))

const NameContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.main,
  fontSize: theme.typography.h3.fontSize,
}))

interface Props {
  person: BirthdayPerson
}

export const BirthdayCard = ({ person }: Props) => {
  const { locale } = useDateLocale()

  return (
    <Container>
      <AvatarContainer>
        <Avatar fullName={person.full_name} image={person.avatar} />
      </AvatarContainer>
      <ContentBox>
        <UpperBox>
          <NameContainer>{person.full_name}</NameContainer>
        </UpperBox>
        <BottomBox>
          {format(new Date(person.birth_date), 'd MMMM', {
            // @ts-ignore
            locale,
          })}
        </BottomBox>
      </ContentBox>
    </Container>
  )
}
