import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { ArteelLogo } from 'components/ArteelLogo/ArteelLogo'
import { useIsLuckyBirdAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdAuth'
import { useCookiesContext } from 'store/useCookiesContext'
import { getResponsiveSize } from 'theme/styles.utils'
import { AuthMenu } from 'theme/Web/AuthMenu/AuthMenu'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  body: {
    background: theme.palette.background.default,
    height: '100vh',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
    },
  },
  leftPart: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    overflow: 'hidden',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    position: 'fixed',
    width: '100%',
    background: theme.palette.background.default,
  },

  wrapperChildrenDesktop: {
    background: theme.palette.background.paper,
    height: 'auto',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerWrapperChildren: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
  },
  linksWrapper: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    cursor: 'pointer',
    color: 'inherit',
    fontSize: theme.typography.h4.fontSize,
    padding: theme.spacing(2),
    transition: '0.3s all',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

type Props = {
  children: ReactNode
}

export const AuthTemplate = ({ children }: Props) => {
  const { body, leftPart, wrapperChildrenDesktop, link, innerWrapperChildren, linksWrapper } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { isRoute: fullScreen } = useIsRoute(['/auth/privacy-and-policy/', '/auth/luckybird/privacy-and-policy/'])
  const { openDetails } = useCookiesContext()

  const onClickPolicyLink = (path: string) => {
    navigate(path)
    window.scroll(0, 0)
  }

  const PolicyLinks = ({ py }: { py?: number }) => {
    const isLuckyBird = useIsLuckyBirdAuth()
    return (
      <Box className={linksWrapper} sx={{ py }}>
        <Box
          className={link}
          onClick={() =>
            onClickPolicyLink(isLuckyBird ? '/auth/luckybird/privacy-and-policy' : '/auth/privacy-and-policy')
          }
        >
          {t('auth.privacy_and_policy')}
        </Box>
        <Box className={link} onClick={() => openDetails()}>
          {t('cookies.management')}
        </Box>
      </Box>
    )
  }
  const Logo = ({ verticalPadding = 3 }) => (
    <ArteelLogo size={`${getResponsiveSize(11.5)}rem`} paddingVertical={verticalPadding} />
  )
  const Content = () => <div className={valueOrUndefined(isMobile, innerWrapperChildren)}>{children}</div>
  const MobileLayout = () => (
    <Box
      sx={(t) => ({
        borderBottom: `24px solid ${t.palette.primary.main}`,
        height: '100%',
      })}
    >
      <Box
        display="grid"
        alignItems="center"
        justifyItems="center"
        py={8}
        rowGap={2}
        sx={(t) => ({
          background: t.palette.background.paper,
        })}
      >
        <Content />
        <Box px={3} width="100%">
          <AuthMenu noIcons />
        </Box>
      </Box>
      <Box
        display="grid"
        alignItems="center"
        justifyItems="center"
        width="100%"
        rowGap={2}
        py={4}
        sx={(t) => ({
          background: t.palette.background.default,
        })}
      >
        <Logo />
        <PolicyLinks py={1} />
      </Box>
    </Box>
  )

  const DesktopLayout = () => (
    <Grid container>
      <Grid item md={3} />
      <Grid item md={3} className={leftPart}>
        <Box
          display="grid"
          gridTemplateRows="auto 1fr auto"
          alignItems="center"
          minHeight="100vh"
          justifyItems="center"
        >
          <Logo verticalPadding={6} />
          <AuthMenu noIcons />
          <PolicyLinks py={5} />
        </Box>
      </Grid>
      <Grid item xs className={wrapperChildrenDesktop} px={fullScreen ? 0 : 3} py={fullScreen ? 0 : 5}>
        {children}
      </Grid>
    </Grid>
  )

  return (
    <div className={body}>
      <CssBaseline />
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </div>
  )
}
