import { Fragment, JSX, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgIconProps } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Building, Gift2, Message2, Support } from 'components/ArteelIcons'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

interface Props {
  text: ReactNode
  icon: (props: SvgIconProps) => JSX.Element
  href?: string
}
const IconBox = ({ icon: Icon, text, href }: Props) => {
  const isMobile = useIsMobile()
  return (
    <Grid item xs={6}>
      <Link sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'none' } }} href={href} target="_blank">
        <Box display="grid" rowGap={2} justifyItems="center">
          <Icon color="primary" sx={{ fontSize: getResponsiveSize(isMobile ? 70 : 100, 'px') }} />
          <Typography
            sx={{ color: (t) => t.palette.text.primary }}
            textAlign="center"
            variant={isMobile ? 'h4' : 'h2'}
            fontFamily={fontFamilies.default}
            fontWeight="bold"
          >
            {text}
          </Typography>
        </Box>
      </Link>
    </Grid>
  )
}
export const Contact = () => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const address = ['Wingepark 17', '3110 Rotselaar', 'Belgium']
  const tel = ['+32', '16', '442', '777']

  return (
    <Box display="flex" justifyContent="center" alignItems="flex-start" maxWidth={700}>
      <Grid container spacing={isMobile ? 2 : 6} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <HeaderPage text={t('contact.header')} sx={{ pb: 3 }} color="medium" />
        </Grid>
        <IconBox
          icon={Building}
          text={
            <>
              {address.map((el, i, arr) => (
                <Fragment key={i}>
                  {el}
                  {i < arr.length - 1 && <br />}
                </Fragment>
              ))}
            </>
          }
          href={`https://google.com/maps/place/${address.join(' ')}`}
        />
        <IconBox
          icon={Support}
          text={
            <>
              {tel.join(' ')}
              <br /> (9:00 - 17:00)
            </>
          }
          href={`tel:${tel.join('')}`}
        />
        <IconBox icon={Message2} text="support@arteel.com" href="mailto:support@arteel.com" />
        <IconBox icon={Gift2} text="www.arteel.com" href="https://arteel.com/" />
      </Grid>
    </Box>
  )
}
