import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'query-string'

type Primitive = number | string | boolean | undefined

export const parseNumberQuery = (params?: string | string[]) =>
  (typeof params === 'string' ? [params] : params)?.map(Number) || []

export const useQueryParams = <T extends Record<string, Primitive | Primitive[]>>() => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = qs.parse(location.search, { decode: false, arrayFormat: 'comma' }) as T
  const pushParam = (param: Partial<Record<keyof T, Primitive | Primitive[]>>) =>
    navigate(
      `${location.pathname}?${qs.stringify(
        { ...params, ...param },
        {
          encode: false,
          arrayFormat: 'comma',
        }
      )}`
    )

  const resetFilters = (keys?: (keyof T)[]) => {
    if (!keys) return navigate(location.pathname)
    const params = Object.fromEntries(keys.map((key) => [key, undefined])) as any
    pushParam(params)
  }

  return { params, pushParam, resetFilters }
}
