import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { Breadcrumbs, Segment } from 'components/Breadcrumbs'
import { ProductGrid } from 'components/ProductGrid/ProductGrid'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { TFunction } from 'i18next'
import { DesktopFilterComponent, DesktopSortComponent } from 'pages/HomeRedemptionSite/components'
import { SEARCH_PRODUCT, useShowFiltersAndSorting } from 'pages/HomeRedemptionSite/utils'
import { useBrandProducts } from 'utils/hooks/api/useBrandProducts'

const getBreadcrumbsSegments = (t: TFunction): Segment[] => [
  {
    id: 'catalog',
    label: t('catalog'),
    url: '/catalog',
  },
  {
    id: 'brands',
    label: t('brands'),
    url: '/catalog/brands',
  },
]

export const BrandProducts = () => {
  const productQuery = useBrandProducts()
  const { t } = useTranslation()
  const { showFilters } = useShowFiltersAndSorting()

  return (
    <WhitePaper
      searchParam={SEARCH_PRODUCT}
      header={<Breadcrumbs segments={getBreadcrumbsSegments(t)} />}
      FilterComponent={({ gutterTop }) => (showFilters ? <DesktopFilterComponent gutterTop={gutterTop} /> : null)}
      SortComponent={({ gutterTop }) => (showFilters ? <DesktopSortComponent gutterTop={gutterTop} /> : null)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductGrid headingT="brand.products" {...productQuery} />
        </Grid>
      </Grid>
    </WhitePaper>
  )
}
