import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ShoppingBasket } from 'components/ArteelIcons'
import { ButtonWithIcon } from 'components/ButtonWithIcon/ButtonWithIcon'
import { DialogShoppingCartList } from 'pages/ProductCard/components/AddItemToCart/DialogShoppingCartList'
import { useAmountOfItemsInShoppingCart } from 'shared/ShoppingCart/slice'
import { dataCy } from 'utils/cypressUtils'
import { useAddItemToCart } from 'utils/hooks/api/ShoppingCart/useAddItemToCart'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

export const AddItemToCart = () => {
  const [open, setOpen] = useState(false)
  const [visibleWarning, setVisibleWarning] = useState(false)
  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { isSingleLogic } = useIsLuckyBirdUser()
  const amountItems = useAmountOfItemsInShoppingCart()

  const { mutate: handleAddItemToShoppingCart, isLoading: isAddingToCart } = useAddItemToCart({
    toggleDialog: setOpen,
  })

  const handleCancel = () => {
    setOpen(false)
    setVisibleWarning(false)
  }

  return (
    <>
      <ButtonWithIcon
        Icon={<ShoppingBasket />}
        translation="product.add_to_shopping_basket"
        onClick={() => {
          if (isSingleLogic && amountItems < 1) {
            handleAddItemToShoppingCart(parseInt(productId, 10))
          }
          if (isSingleLogic && amountItems > 0) {
            setOpen(true)
            setVisibleWarning(true)
          }
          if (!isSingleLogic) {
            handleAddItemToShoppingCart(parseInt(productId, 10))
          }
        }}
        isLoading={isAddingToCart}
        dataCy={dataCy('button-product-add')}
      />
      <DialogShoppingCartList
        onCancel={handleCancel}
        open={open}
        isAdding={isAddingToCart}
        warning={visibleWarning ? t('product.single_logic_alert') : undefined}
      />
    </>
  )
}
