import { ElementType } from 'react'
import * as React from 'react'
import { RouteProps } from 'react-router-dom'
import Loader from 'components/Loader/Loader'
import { useAuthContext } from 'store/useAuthContext'
import { useSocialWall } from 'utils/hooks/useSocialWall'

import { FEATURES } from './Features'
import { ProtectedRedirect } from './ProtectedRedirect'

type Props = RouteProps & {
  Component: ElementType<any>
  permissions: string[]
}

const useIsAuthorized = ({ permissions }: { permissions: string[] }) => {
  const { isLoading: userLoading, user } = useAuthContext()
  const { isLoading: socialWallLoading, isSocialWallActive } = useSocialWall()

  const isLoading = userLoading || socialWallLoading

  if (isLoading) {
    return { isLoading, isAuthorized: false }
  }

  const isAuthorized = permissions.reduce((prev, curr) => {
    switch (curr) {
      case FEATURES.SOCIAL_WALL:
        return prev && isSocialWallActive
      default:
        throw new Error('Undefined Permission')
    }
  }, !!user)

  return { isLoading, isAuthorized }
}

export const ProtectedRoute = ({ Component, permissions }: Props) => {
  const { isLoading, isAuthorized } = useIsAuthorized({ permissions })

  return <Loader isLoading={isLoading}>{isAuthorized ? <Component /> : <ProtectedRedirect to="/" />}</Loader>
}
