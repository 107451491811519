import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { MaterialTable } from 'components/MaterialTable/MaterialTable'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { getColumn } from 'pages/PersonalMessages/getColumn'
import { getHistoryPersonalMessages, getPersonalMessages } from 'pages/PersonalMessages/getPersonalMessages'
import { PersonalMessage } from 'pages/PersonalMessages/types'
import { getResponsiveSize } from 'theme/styles.utils'
import { useSocialWall } from 'utils/hooks/useSocialWall'

export const PersonalMessages = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isSocialWallActive } = useSocialWall()

  return (
    <Box sx={{ '& > div': { mb: 2 } }}>
      <ResponsivePaper data-cy="personal-messages-desktop">
        <Box position="absolute" right={getResponsiveSize(10, 'px')} top={0}>
          <GoBackButton
            translation="app.back"
            noPx
            onClick={() => navigate(isSocialWallActive ? '/social-wall' : '/')}
          />
        </Box>
        <Grid container gap={6}>
          <Grid item xs>
            <HeaderPage
              data-cy="personal-messages-header"
              text={t('personal_messages.header')}
              color="medium"
              uppercase={false}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable<PersonalMessage>
              queryKey={personalMessageKeys.getPersonalMessages}
              queryFn={getPersonalMessages}
              columns={getColumn({ isHistory: false })}
            />
          </Grid>
        </Grid>
      </ResponsivePaper>

      <ResponsivePaper data-cy="personal-messages-history-desktop">
        <Grid container gap={6}>
          <Grid item xs>
            <HeaderPage
              data-cy="personal-messages-history-header"
              text={t('personal_messages.history.header')}
              color="medium"
              uppercase={false}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialTable<PersonalMessage>
              queryKey={personalMessageKeys.getHistoryPersonalMessages}
              queryFn={getHistoryPersonalMessages}
              columns={getColumn({ isHistory: true })}
            />
          </Grid>
        </Grid>
      </ResponsivePaper>

      <ResponsivePaper data-cy="personal-message-templates-desktop">
        <Grid container gap={2} justifyContent="center">
          <Grid item xs={12}>
            <HeaderPage
              data-cy="personal-message-templates-header"
              text={t('personal_messages.templates')}
              color="medium"
              uppercase={false}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => navigate('/personal-messages/templates')}
            >
              {t('personal_messages.templates.button')}
            </Button>
          </Grid>
        </Grid>
      </ResponsivePaper>
    </Box>
  )
}
