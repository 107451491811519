import React from 'react'
import { alpha, keyframes, styled } from '@mui/system'
import { detect } from 'detect-browser'
import { getResponsiveSize } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { isSafari } from 'utils/isSafari'

const isIOS = (() => {
  const { os, name } = detect()
  // iOS safari
  if (isSafari()) return true
  // iOS chrome
  if (os === 'iOS' && name === 'crios') return true
  return false
})()
const BarContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'barHeight',
})<{ barHeight: number }>(({ theme, barHeight }) => ({
  outline: valueOrUndefined(!isIOS, `1px solid ${theme.palette.primary.main}`),
  border: valueOrUndefined(isIOS, `1px solid ${theme.palette.primary.main}`),
  borderRadius: `${barHeight * 2}rem`,
  width: '100%',
  overflow: 'hidden',
}))

const getWidth = (animated: boolean, progress: number) => {
  if (animated) {
    return {
      width: 0,
      animation: `${keyframes({
        '0%': {
          width: 0,
        },
        '100%': {
          width: `${progress}%`,
        },
      })} 1s`,
      animationDelay: '500ms',
      animationFillMode: 'forwards',
    }
  }

  return { width: `${progress}%` }
}

interface BarProps {
  progress: number
  animated: boolean
  barHeight: number
}

const Bar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'progress' && prop !== 'animated' && prop !== 'barHeight',
})<BarProps>(({ theme, progress, animated, barHeight }) => ({
  height: `${barHeight}rem`,
  background: alpha(theme.palette.primary.main, 0.7),
  borderTopRightRadius: `${barHeight * 2}rem`,
  borderBottomRightRadius: `${barHeight * 2}rem`,
  ...getWidth(animated, progress),
}))

interface ProgressBarProps {
  progress: number
  animated?: boolean
  height?: number
}

export const ProgressBar = ({ progress, animated = false, height = 1 }: ProgressBarProps) => {
  return (
    <BarContainer barHeight={getResponsiveSize(height)} data-cy="bar-container">
      <Bar progress={progress} animated={animated} barHeight={getResponsiveSize(height)} data-cy="bar-content" />
    </BarContainer>
  )
}

interface LevelProgressBarProps extends Omit<ProgressBarProps, 'progress'> {
  level: number
  maxLevel: number
  minLevel?: number
}

export const LevelProgressBar = ({ level, maxLevel, minLevel = 1, ...progressBarProps }: LevelProgressBarProps) => {
  const progress = ((level - minLevel) / (maxLevel - minLevel)) * 100
  return <ProgressBar progress={progress} {...progressBarProps} />
}
