import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import { useOktaAuth } from '@okta/okta-react'
import isEmpty from 'lodash/isEmpty'
import { providerTypes, SSOProvider } from 'pages/Login/api/useGetSSOProviders'
import { LoggedAzure } from 'pages/Login/SSO/LoggedAzure'
import { LoginAzure } from 'pages/Login/SSO/LoginAzure'
import { LoginOkta } from 'pages/Login/SSO/LoginOkta'
import { OktaLogged } from 'pages/Login/SSO/OktaLogged'
import qs from 'query-string'

type Props = {
  providers: SSOProvider[]
}

export const ChooseProvider = ({ providers }: Props) => {
  const isAuthenticated = useIsAuthenticated()
  const { authState } = useOktaAuth()
  const location = useLocation()
  const params = qs.parse(location.search)

  if ((!isEmpty(params?.code) && !isEmpty(params?.state)) || authState?.isAuthenticated) {
    return <OktaLogged />
  }

  if (isAuthenticated) {
    return <LoggedAzure />
  }

  return (
    <Grid item xs={12}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <List sx={{ width: '100%', border: (t) => `1px solid ${t.palette.divider}` }} disablePadding>
            {providers?.map((provider, index, array) => {
              switch (provider.type) {
                case providerTypes.azure:
                  return (
                    <Fragment key={provider.type}>
                      <LoginAzure />
                      {array.length - 1 !== index && <Divider />}
                    </Fragment>
                  )
                case providerTypes.okta:
                  return (
                    <Fragment key={provider.type}>
                      <LoginOkta />
                      {array.length - 1 !== index && <Divider />}
                    </Fragment>
                  )
                default:
                  return null
              }
            })}
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}
