import './App.css'

import { Suspense, useEffect } from 'react'
import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CookiesDialog } from 'components/CookiesDialog/CookiesDialog'
import { LoaderFullScreen } from 'components/LoaderFullScreen/LoaderFullScreen'
import MaterialIcons from 'components/MaterialIcons'
import MessagesContainer from 'components/MessagesContainer'
import NoMatch from 'components/NoMatch'
import { CustomGlobalStyles } from 'GlobalStyles'
import { SnackbarProvider } from 'notistack'
import { AppRoutes } from 'routes/AppRoutes'
import { useSubdomainInfo } from 'shared/Site/api'
import { CookiesProvider } from 'store/CookiesContext'
import { CMSThemeProvider } from 'store/ThemeContext'
import { muiTheme, useArteelTheme } from 'theme/styles'
import { i18n, useLanguages } from 'utils/api/Languages/useLanguages'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsCypress } from 'utils/hooks/useIsCypress'

export const App = () => {
  const { isFetching: isFetchingLanguage } = useLanguages()
  const { isFetched, data } = useSubdomainInfo()
  const subscriberMaterialTheme = useArteelTheme()
  const hideRQDevtools = valueOrUndefined(window.localStorage.getItem('IS_CYPRESS_ENVIRONMENT') === 'true', {
    style: { display: 'none' },
  })
  const { isCypress } = useIsCypress()
  const location = useLocation()

  useEffect(() => {
    if (data?.data?.subscriber?.name && !document.title.includes(data?.data?.subscriber?.name)) {
      document.title = document.title + ' | ' + data.data.subscriber.name
    }
  }, [data])

  if ((!isFetched || isFetchingLanguage) && location.pathname !== '/404') {
    return (
      <ThemeProvider theme={muiTheme({})}>
        <LoaderFullScreen />
      </ThemeProvider>
    )
  }
  if (location.pathname === '/404') {
    return (
      <ThemeProvider theme={muiTheme({})}>
        <Routes>
          <Route path="*" Component={() => <NoMatch withoutButton noTranslation />} />
        </Routes>
      </ThemeProvider>
    )
  }

  return (
    <I18nextProvider i18n={i18n}>
      <SnackbarProvider
        maxSnack={3}
        style={{
          display: valueOrUndefined(isCypress, 'none'),
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ containerRoot: 'snackbar-custom' }}
        preventDuplicate
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={subscriberMaterialTheme}>
            <CMSThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CustomGlobalStyles />
                <MaterialIcons />
                <MessagesContainer />
                <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={hideRQDevtools} />

                <Suspense fallback={<LoaderFullScreen />}>
                  <CookiesProvider>
                    <CookiesDialog />
                    <AppRoutes />
                  </CookiesProvider>
                </Suspense>
              </LocalizationProvider>
            </CMSThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </SnackbarProvider>
    </I18nextProvider>
  )
}
