import React, { forwardRef, ReactNode, useState } from 'react'
import { styled, tooltipClasses, TooltipProps } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Avatar from 'components/Avatar'
import { SMALL_AVATAR } from 'components/Message/utils'
import { RecognizingUser } from 'utils/api/SocialWall/types'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsTouchDevice } from 'utils/hooks/useIsTouchDevice'

type Props = {
  user: RecognizingUser
  author?: boolean
  dimensions?: number
  fontSize?: number
  onClick?: () => void
  pb?: number
  children?: ReactNode | ReactNode[]
}
export const UserNameWithAvatar = forwardRef<any, Props>(
  (
    { user, author, dimensions = SMALL_AVATAR.SIZE, fontSize = SMALL_AVATAR.FONT, onClick, children, ...props },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        sx={{ display: 'flex', width: 'fit-content', alignItems: 'center', mb: valueOrUndefined(author, -0.5) }}
        onClick={onClick}
        {...props}
      >
        <Avatar
          height={dimensions}
          width={dimensions}
          fullName={user.profile.full_name}
          image={user.profile.avatar}
          zIndex={1}
          fontSize={fontSize}
        />
        <Box>
          <Typography pl={1} fontWeight={600} color="primary" variant="body2">
            {user.profile.full_name}
          </Typography>
          {children}
        </Box>
      </Box>
    )
  }
)

const UserTooltipDetails = ({ user }: { user: Props['user'] }) => {
  return (
    <Paper elevation={1} sx={(t) => ({ p: 0, minWidth: 200, borderRadius: t.spacing(1.5) })}>
      <Box
        sx={(t) => ({
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: t.palette.grey[200],
          p: 2,
          pb: 1,
          borderRadius: t.spacing(1.5, 1.5, 0, 0),
        })}
      >
        <Avatar
          height={60}
          width={60}
          fullName={user.profile.full_name}
          image={user.profile.avatar}
          zIndex={1}
          fontSize={1.6}
        />
        <Typography fontWeight={600} color="primary" variant="body2" pt={1}>
          {user.profile.full_name}
        </Typography>
      </Box>
      <Typography textAlign="center" fontWeight={600} pt={1}>
        {user.profile.job_title}
      </Typography>
      <Typography textAlign="center" pb={1} sx={(t) => ({ color: t.palette.grey[600] })}>
        {user.profile.department?.name}
      </Typography>
    </Paper>
  )
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement="top" {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
  },
}))

export const MessageUserWithTooltip = ({
  user,
  dimensions,
  fontSize,
  author,
}: Pick<Props, 'user' | 'author' | 'fontSize' | 'dimensions'>) => {
  const isTouch = useIsTouchDevice()
  const [open, setOpen] = useState(false)

  if (isTouch)
    return (
      <>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <UserTooltipDetails user={user} />
        </Dialog>
        <UserNameWithAvatar
          author={author}
          dimensions={dimensions}
          fontSize={fontSize}
          user={user}
          onClick={() => setOpen(true)}
        />
      </>
    )

  return (
    <StyledTooltip title={<UserTooltipDetails user={user} />}>
      <UserNameWithAvatar author={author} dimensions={dimensions} fontSize={fontSize} user={user} />
    </StyledTooltip>
  )
}
