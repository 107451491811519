import { useTranslation } from 'react-i18next'
import { alertClasses } from '@mui/material'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { mapJSONToYupSchema } from 'components/JSONForm/mapJSONToYupSchema'
import { renderFormFields } from 'components/JSONForm/renderFormFields'
import { mapInitialValues, mapSubmitValues } from 'components/JSONForm/valuesMappers'
import Loader from 'components/Loader/Loader'
import { Form, Formik } from 'formik'
import { ConsentField } from 'pages/Login/ActivateAccount/ConsentField'
import { ActivateAccountOnSuccess, useActivateAccount } from 'pages/Login/api/useActivateAccount'
import { EnterCustomerInternalIdResponse } from 'pages/Login/api/useEnterCustomerInternalId'
import { useGetActivationCodeForm } from 'pages/Login/api/useGetActivationCodeForm'
import { buildYup } from 'schema-to-yup'
import { parseError } from 'utils/api/parseError'
import { camelToSnakeCase } from 'utils/camelToSnakeCase'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { User } from 'utils/hooks/userType'
import { inside } from 'utils/styles'
import * as Yup from 'yup'

type Props = {
  code?: string
  user: User | null
  notLinkedUser: EnterCustomerInternalIdResponse | null
  onBack: () => void
  onSuccess: (data: ActivateAccountOnSuccess) => void
}
export const EnterPersonalDetails = ({ user, notLinkedUser, onBack, code, onSuccess }: Props) => {
  const { t } = useTranslation()
  const { mutate, error, isError, isLoading } = useActivateAccount(onSuccess)
  const userId = user?.id || notLinkedUser?.id
  const translationKey = 'auth.activate_account.field.'
  const form = useGetActivationCodeForm({ code, user: userId, translationKey })

  const activationError = parseError(error)
  const formError = parseError(form.error)
  const isVisibleError = (() => {
    const activation = activationError?.message && !isLoading && isError
    const code = formError?.message && !form.isLoading && form.isError
    return {
      any: activation || code,
      activation,
      code,
    }
  })()

  const initialValues = {
    consent: false,
    ...mapInitialValues(form.data),
  }

  const validationSchema = buildYup(mapJSONToYupSchema(form.data)).shape({
    consent: Yup.boolean().label('Consent').required().oneOf([true], 'You must accept the terms and conditions'),
  })

  const onSubmit = (values: typeof initialValues) => {
    const { avatar, email, consent, ...mappedValues } = mapSubmitValues(values)
    mutate({
      ...mappedValues,
      code,
      email: email as string,
      consent: consent as boolean,
      avatar: (avatar as string)?.includes('http') ? undefined : (avatar as string),
    })
  }

  if (form.isLoading) return <Loader isLoading />
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form data-cy="ENTER-PERSONAL-INFORMATION-FORM">
        <Grid container spacing={2}>
          {renderFormFields(form.data, t)}
          {!isVisibleError.code && (
            <Grid item xs={12}>
              <ConsentField />
            </Grid>
          )}
          <Grid item xs={12} lg={12} sx={{ pt: isVisibleError.any ? 2 : 0 }}>
            <Collapse in={isVisibleError.any}>
              <Alert
                severity="error"
                sx={{
                  borderRadius: 24,
                  [inside(alertClasses.icon)]: {
                    alignItems: 'center',
                  },
                }}
              >
                {isVisibleError.code ? (
                  formError?.message
                ) : (
                  <>
                    {activationError?.message}
                    {activationError?.mappedErrors?.map(([k, v]) => (
                      <div key={k}>{`${v} - ${t((translationKey + camelToSnakeCase(k)) as TranslationsKeys)}`}</div>
                    ))}
                  </>
                )}
              </Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <FormikButtons onBack={onBack} isLoading={isLoading} disabledSubmit={isVisibleError.code} />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}
