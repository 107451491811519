import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { Languages } from 'components/Languages/Languages'
import { useGetMyConsent } from 'pages/Login/api/useGetMyConsent'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import { Iframe } from 'shared/Iframe/Iframe'

export const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation()
  const { data } = useGetMyConsent()
  const consent = data?.data?.translation?.[i18n.language]
  const isGlobalLuckyBird = useIsLuckyBirdGlobalAuth()

  if (isGlobalLuckyBird) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={(theme) => ({
          width: '100%',
          height: '100vh',
          [theme.breakpoints.down('md')]: {
            height: '100%',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
              height: '100%',
            },
            '& embed': {
              width: '100%',
              height: '100%',
              [theme.breakpoints.down('md')]: {
                height: 'calc(100vh - 140px)',
              },
            },
          })}
        >
          <embed src="/doc/policy_privacy_13_12_2023.pdf" />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        width: '100%',
        height: '100vh',
        [theme.breakpoints.down('md')]: {
          ml: -10,
          mt: -16,
          width: `calc(100% + 160px)`,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          background: theme.palette.grey['700'],
          [theme.breakpoints.down('md')]: {
            height: '100%',
            background: theme.palette.background.paper,
          },
          '& iframe': {
            width: '100%',
            maxWidth: '800px',
            height: 'calc(100vh - 64px)',
            pl: 4,
            background: theme.palette.background.paper,
            boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.75)',
            border: 'none',
            [theme.breakpoints.down('md')]: {
              pl: 2,
              boxShadow: 'none',
            },
          },
        })}
      >
        <Box
          sx={(theme) => ({
            background: theme.palette.grey['800'],
            width: '100%',
            height: '56px',
            display: 'flex',
            justifyContent: 'center',
            boxShadow: theme.shadows[6],
            color: theme.palette.common.white,
            [theme.breakpoints.down('md')]: {
              color: 'inherit',
              background: theme.palette.background.paper,
              boxShadow: 'none',
              height: '64px',
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
          })}
        >
          <Languages withoutPadding />
        </Box>
        <Box
          sx={(theme) => ({
            mt: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              mt: 0,
            },
          })}
        >
          <Iframe unsafeHTML={consent?.text} />
        </Box>
      </Box>
    </Box>
  )
}
