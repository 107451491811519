import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Close from '@mui/icons-material/Close'
import Search from '@mui/icons-material/Search'
import SearchIcon from '@mui/icons-material/Search'
import { styled, SxProps, Theme } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Zoom from '@mui/material/Zoom'
import { TextField } from 'components/TextField/TextField'
import debounce from 'lodash/debounce'
import { useSearchCard } from 'utils/hooks/useSearchCard'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: 'ProximaNovaCond',
  color: theme.palette.primary.main,
  fontSize: theme.typography.h3.fontSize,
  minWidth: theme.typography.h2.fontSize,
  textTransform: 'capitalize',
  '&:disabled': {
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightLight,
  },
}))

const CloseIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.text.disabled,
  translation: '0.3s all',
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

interface ScrollOption {
  text: string
  elementId: string
  isVisible: boolean
}

interface Props {
  scrollOptions: ScrollOption[]
  searchText: string
  justify?: 'flex-start' | 'flex-end' | 'space-evenly'
  removeMargin?: boolean
  searchButtonSx?: SxProps<Theme>
  searchKey: string
}

export const CardSearch = ({
  scrollOptions,
  searchText,
  justify = 'flex-start',
  removeMargin = false,
  searchButtonSx,
  searchKey,
}: Props) => {
  const { t } = useTranslation()
  const { searchPhrase, setSearchPhrase } = useSearchCard({ searchKey })
  const [searchOpen, setSearchOpen] = useState(searchPhrase !== undefined)

  const handleClick = (option: ScrollOption) => () => {
    const element = document.getElementById(option.elementId)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const switchOpenSearch = () => {
    setSearchOpen((current) => !current)
    setSearchPhrase()
  }

  const onChangeSearch = debounce((value) => setSearchPhrase(value), 500)

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs
            sx={(theme) => ({
              ...(!removeMargin && { marginLeft: theme.spacing(4) }),
            })}
          >
            <Grid container justifyContent={justify}>
              {scrollOptions.map((option) => (
                <Grid item key={option.text}>
                  <StyledIconButton
                    size="small"
                    disabled={!option.isVisible}
                    onClick={handleClick(option)}
                    data-cy={`option-${option.elementId}`}
                  >
                    {option.text}
                  </StyledIconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <Button startIcon={<Search color="action" />} sx={searchButtonSx} onClick={switchOpenSearch}>
              {searchText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Zoom in={searchOpen} unmountOnExit mountOnEnter>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                background: (theme) => theme.palette.background.default,
              },
            }}
            placeholder={t('app.search')}
            defaultValue={searchPhrase}
            autoFocus
            onChange={(event) => onChangeSearch(event.target.value)}
            InputProps={{
              startAdornment: <SearchIcon color="disabled" />,
              endAdornment: <CloseIcon color="primary" onClick={switchOpenSearch} />,
            }}
          />
        </Grid>
      </Zoom>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}
