import React from 'react'
import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import parse, { attributesToProps, domToReact, Element } from 'html-react-parser'
import { useIconFromApi } from 'utils/hooks/api/MyScore/useIconFromApi'
import { safeHtml } from 'utils/safeHtml'

type Props = {
  svgUrl: string
} & SvgIconProps

export const BackendSvgIcon = ({ svgUrl, ...rest }: Props) => {
  const { data: icon } = useIconFromApi({ icon: svgUrl })

  if (!svgUrl || !icon) return null

  const sanitizeColor = (children: any[] = []) =>
    children.map((child) => {
      if (child?.type === 'text') {
        const regFill = /fill:#[A-Za-z0-9]+;/g
        const regStroke = /stroke:#[A-Za-z0-9]+;/g
        let data = child?.data?.replace(regFill, '')
        data = data?.replace(regStroke, '')
        return { ...child, data }
      }
      return child
    })
  const sanitizeStyles = (children: any[] = []) =>
    children.map((child) => {
      if (child?.type === 'style') {
        return { ...child, children: sanitizeColor(child?.children) }
      }
      return child
    })

  return (
    <>
      {parse(safeHtml(icon.data).__html, {
        replace: (domNode) => {
          const el = domNode as Element
          if (!el) return
          const props = attributesToProps(el.attribs)
          if (el.name === 'svg') {
            return (
              <SvgIcon {...props} {...rest}>
                {domToReact(sanitizeStyles(el.children))}
              </SvgIcon>
            )
          }
        },
      })}
    </>
  )
}
