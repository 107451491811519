import * as React from 'react'
import { useCallback, useMemo } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { TranslationsKeys } from 'utils/createTranslationKey'

type Props = {
  onBack?: () => void
  disabled?: boolean
  isLoading?: boolean
  textButtonCancel?: TranslationsKeys
  textButtonSubmit?: TranslationsKeys
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
  uppercase?: boolean
  gridLayout?: boolean
  disabledSubmit?: boolean
  submitDataCy?: string
  cancelDataCy?: string
}

export const FormikButtons = ({
  onBack,
  disabled,
  textButtonCancel = 'app.back',
  textButtonSubmit = 'app.save',
  isLoading,
  size = 'medium',
  fullWidth,
  uppercase,
  gridLayout,
  disabledSubmit,
  cancelDataCy,
  submitDataCy,
}: Props) => {
  const { t } = useTranslation()

  const { resetForm, errors } = useFormikContext()
  const isSubmitButtonContained = isEmpty(errors)

  const handleBack = useCallback(() => {
    if (onBack) {
      onBack()
    }
    ReactGA.event({
      category: 'FormikButtons[cancel]' + window.location.pathname,
      action: 'click',
      label: t(textButtonCancel),
    })
    resetForm()
  }, [onBack, resetForm])

  const makeUppercase = (v?: string) => (uppercase ? v?.toUpperCase() : v)

  if (gridLayout)
    return useMemo(
      () => (
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          columnGap={2}
          width={fullWidth ? '100%' : 'fit-content'}
          m="auto"
        >
          <Box display="flex" justifyItems="end">
            <Button
              type="button"
              variant={!isSubmitButtonContained ? 'contained' : 'outlined'}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleBack()
              }}
              disabled={disabled}
              size={size}
              fullWidth
              data-cy={cancelDataCy ? cancelDataCy : 'cancel-button'}
            >
              {makeUppercase(t(textButtonCancel))}
            </Button>
          </Box>
          <Box display="flex">
            <LoadingButton
              loading={isLoading}
              type="submit"
              onClick={() => {
                ReactGA.event({
                  category: 'FormikButtons[submit]' + window.location.pathname,
                  action: 'click',
                  label: t(textButtonSubmit),
                })
              }}
              variant={isSubmitButtonContained ? 'contained' : 'outlined'}
              color="primary"
              disabled={disabled || disabledSubmit}
              size={size}
              fullWidth
              data-cy={submitDataCy ? submitDataCy : 'submit-button'}
            >
              {makeUppercase(t(textButtonSubmit))}
            </LoadingButton>
          </Box>
        </Box>
      ),
      [
        disabled,
        handleBack,
        onBack,
        t,
        textButtonCancel,
        textButtonSubmit,
        isLoading,
        isSubmitButtonContained,
        gridLayout,
        fullWidth,
        disabledSubmit,
      ]
    )

  return useMemo(
    () => (
      <Grid container spacing={2} justifyContent="center">
        {onBack && (
          <Grid item xs={fullWidth || undefined}>
            <Button
              type="button"
              variant={!isSubmitButtonContained ? 'contained' : 'outlined'}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                handleBack()
              }}
              disabled={disabled}
              size={size}
              fullWidth={fullWidth}
              data-cy={cancelDataCy ? cancelDataCy : 'cancel-button'}
            >
              {makeUppercase(t(textButtonCancel))}
            </Button>
          </Grid>
        )}
        <Grid item xs={fullWidth || undefined}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            onClick={() => {
              ReactGA.event({
                category: 'FormikButtons[submit]' + window.location.pathname,
                action: 'click',
                label: t(textButtonSubmit),
              })
            }}
            variant={isSubmitButtonContained ? 'contained' : 'outlined'}
            color="primary"
            disabled={disabled || disabledSubmit}
            size={size}
            fullWidth={fullWidth}
            data-cy={submitDataCy ? submitDataCy : 'submit-button'}
          >
            {makeUppercase(t(textButtonSubmit))}
          </LoadingButton>
        </Grid>
      </Grid>
    ),
    [
      disabled,
      handleBack,
      onBack,
      t,
      textButtonCancel,
      textButtonSubmit,
      isLoading,
      isSubmitButtonContained,
      disabledSubmit,
    ]
  )
}
