import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { Values } from 'components/ArteelIcons'
import Loader from 'components/Loader/Loader'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useWidgetValues } from 'utils/hooks/api/Values/useWidgetValues'

import { Filter } from './components/Filter'
import { filterOptions } from './components/FilterOptions'
import { ProgressBar } from './components/ProgressBar'
import { ContentCard } from './ContentCard'
import { getFormattedDateFromFilterName } from './helpers/getDateFromFilterName'

const Image = styled('img')(() => ({
  maxWidth: '100%',
  width: '100%',
  height: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
}))

export const ValuesWidget = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(filterOptions[1])

  const { data, isFetching } = useWidgetValues({
    dateFrom: getFormattedDateFromFilterName(filter.name),
  })

  return (
    <ContentCard
      title={t('social_wall.filters.values')}
      icon={<Values />}
      link="/values"
      dataCy="values-widget"
      filters={<Filter title={t(filter.label)} options={filterOptions} onSelect={setFilter} currentOption={filter} />}
    >
      <Loader isLoading={isFetching} objects={data?.data.values}>
        <Grid container direction="column" gap={2} sx={(theme) => ({ padding: theme.spacing(1), paddingTop: 0 })}>
          <Grid item xs>
            <Image
              src={getObjectTranslation(data?.data.image.translation).path}
              alt=""
              loading="lazy"
              data-cy="widget-image"
            />
          </Grid>
          <Grid item container xs gap={1} data-cy="widget-values">
            {data?.data.values?.map((value) => {
              const translation = getObjectTranslation(value.translation)

              return (
                <Grid
                  key={value.id}
                  container
                  alignItems="center"
                  rowGap={1}
                  wrap="nowrap"
                  data-cy={`widget-value-${value.id}`}
                >
                  <Grid item xs={4} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={translation.name} placement="left" data-cy="value-tooltip">
                      <span data-cy="value-name">{translation.name}</span>
                    </Tooltip>
                  </Grid>
                  <Grid item gap={1} container alignItems="center" xs wrap="nowrap">
                    <Grid sx={{ width: '5ch' }} textAlign="right" data-cy="value-percentage">
                      {value.percentage}%
                    </Grid>
                    <Grid item xs>
                      <ProgressBar progress={value.percentage} animated />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Loader>
    </ContentCard>
  )
}
