import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { ItemListGrid } from 'components/ItemListGrid/ItemListGrid'
import { ProductGridCard } from 'components/ProductGrid/ProductGridCard'
import { WithPagination } from 'utils/api/pagination'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { infinityQueryMap } from 'utils/hooks/infinityQueryMap'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

import Loader from '../Loader/Loader'

type Props = (
  | {
      headingT?: TranslationsKeys
      heading: string
      headerRightComponent?: ReactNode
    }
  | {
      headingT: TranslationsKeys
      heading?: string
      headerRightComponent?: ReactNode
    }
) &
  Pick<
    UseInfiniteQueryResult<AxiosResponse<WithPagination<CatalogProduct>>>,
    'hasNextPage' | 'fetchNextPage' | 'isFetching' | 'data' | 'isLoading'
  >

export const ProductGrid = ({
  headerRightComponent,
  isLoading,
  heading,
  headingT,
  fetchNextPage,
  hasNextPage,
  isFetching,
  data,
}: Props) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { ref, inView } = useInView({ delay: 100 })
  const catalogProducts = infinityQueryMap(data)?.data || []
  const { isSingleLogic } = useIsLuckyBirdUser()

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [inView, hasNextPage])

  return (
    <>
      <Grid item xs={12}>
        <HeaderPage text={heading || t(headingT)} size="medium" rightComponent={headerRightComponent} />
      </Grid>
      {isSingleLogic && (
        <Grid item xs={12}>
          <Alert severity="info" sx={{ mt: -1 }}>
            {t('product.single_logic_info')}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Loader
          isLoading={isLoading}
          objects={catalogProducts}
          emptyLabel={
            <Typography gutterBottom variant="h3">
              {t('app.list.no_items')}
            </Typography>
          }
        >
          <ItemListGrid lineGrid gap={isMobile ? 2 : 5} ref={ref}>
            {catalogProducts.map((catalogProduct) => (
              <ProductGridCard catalogProduct={catalogProduct} key={catalogProduct.product.id} />
            ))}
          </ItemListGrid>
          {!isMobile && !isLoading && isFetching && <Loader isLoading />}
        </Loader>
      </Grid>
    </>
  )
}
