import React from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipProps as MuiTooltipProps } from '@mui/material'
import MuiTooltip from '@mui/material/Tooltip'
import { Box } from '@mui/system'
import isObject from 'lodash/isObject'
import { TranslationsKeys } from 'utils/createTranslationKey'

export interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  title: TranslationsKeys | { title: TranslationsKeys; interpolation?: { [key: string]: string } }
  disabled?: boolean
}

export const Tooltip = ({ title, disabled, children, ...props }: TooltipProps) => {
  const { t } = useTranslation()

  const renderChildren = () => (!disabled ? children : <Box sx={{ display: 'unset' }}>{children}</Box>)

  return (
    <MuiTooltip title={isObject(title) ? t(title.title, title.interpolation) : t(title)} {...props}>
      {renderChildren()}
    </MuiTooltip>
  )
}
