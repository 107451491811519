import React from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { DepartmentTree } from 'components/DepartmentTree/DepartmentTree'
import { useBirthdaysQueryParams } from 'pages/Birthdays/useBirthdaysQueryParam'
import { useBirthdays } from 'utils/hooks/api/Birthdays/useBirthdays'
import { useMonths } from 'utils/hooks/useMonths'

const DialogTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: 900,
  color: theme.palette.primary.main,
  width: '100%',
}))

const Months = () => {
  const { pushParam, selectedMonths } = useBirthdaysQueryParams()
  const { months } = useMonths()
  const { data: birthdays } = useBirthdays()
  const selectMonths = (v: number[]) => pushParam({ months: v })

  return (
    <Box display="grid" gridTemplateColumns={'1fr 1fr 1fr'} gap={1}>
      {months.map((month) => {
        const selected = selectedMonths.includes(month.month)
        const disabled =
          (!selected && !birthdays.find((birthday) => birthday.month === month.month)?.persons?.length) || 0 > 0

        return (
          <Chip
            label={month.longName}
            key={month.longName}
            color="primary"
            variant={selected ? 'filled' : 'outlined'}
            disabled={disabled}
            onClick={() => {
              selectMonths(
                selected
                  ? selectedMonths.filter((el) => el !== month.month)
                  : [...selectedMonths, month.month].sort((a, b) => (a > b ? 1 : -1))
              )
            }}
          />
        )
      })}
    </Box>
  )
}

export const BirthdayFiltersMobile = () => {
  const { t } = useTranslation()
  const { resetFilters, pushParam, params } = useBirthdaysQueryParams()

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <DialogTitle align="center">{t('month')}</DialogTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Months />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <DialogTitle align="center">{t('organisation')}</DialogTitle>
        </AccordionSummary>
        <AccordionDetails>
          <DepartmentTree selected={params.organisation} setSelected={(id) => pushParam({ organisation: id })} />
        </AccordionDetails>
      </Accordion>
      <Box sx={{ textAlign: 'center', pt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => resetFilters(['organisation', 'months'])}>
          {t('app.remove_all_filters')}
        </Button>
      </Box>
    </>
  )
}
