import { QueryClient } from '@tanstack/react-query'
export const queryConfig = {
  retry: 1,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  throwOnError: false,
  staleTime: 60000,
}

export const queryClient = new QueryClient({
  defaultOptions: { queries: queryConfig, mutations: { ...queryConfig, cacheTime: 0 } },
})
