import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Image } from 'components/Image/Image'
import { Category } from 'utils/api/Categories/fetchCategories'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useIsMobile, useIsSmallMobile } from 'utils/hooks/useBreakpoints'

type Props = {
  category: Category
  onClick: () => void
}

export const CategoryGridCard = ({ category, onClick }: Props) => {
  const isMobile = useIsMobile()
  const isSmallMobile = useIsSmallMobile()

  return (
    <Box display="grid" gap={0.5} gridTemplateRows={'auto 1fr'}>
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <Image src={category.image} />
      </Box>
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <Grid container spacing={isMobile ? 0 : 1}>
          <Grid item xs>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              fontWeight="bold"
              variant={isMobile ? (isSmallMobile ? 'h4' : 'h3') : 'h2'}
            >
              {getObjectTranslation(category?.translation)?.name || category?.name || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
