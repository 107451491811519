import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ButtonTypeMap } from '@mui/material/Button/Button'
import IconButton from '@mui/material/IconButton'
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon'
import { Arrow } from 'components/ArteelIcons'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsTablet } from 'utils/hooks/useBreakpoints'

interface Props {
  translation?: TranslationsKeys
  onClick: () => void
  noPx?: boolean
  onlyIcon?: boolean
  iconFontSize?: SvgIconTypeMap['props']['fontSize']
  buttonSize?: ButtonTypeMap['props']['size']
  dataCy?: string
}

export const GoBackButtonBase = ({
  translation = 'shopping_cart.back_to_catalog',
  onClick,
  noPx,
  onlyIcon,
  buttonSize = 'small',
  iconFontSize = 'small',
  dataCy = 'go-back-button',
}: Props) => {
  const { t } = useTranslation()

  if (onlyIcon)
    return (
      <IconButton onClick={onClick} size={buttonSize} data-cy={dataCy}>
        <Arrow
          direction="left"
          sx={(t) => ({
            borderRadius: '100%',
            color: t.palette.background.paper,
            background: t.palette.primary.main,
            p: 0.5,
          })}
          fontSize={iconFontSize}
        />
      </IconButton>
    )

  return (
    <Button
      variant="text"
      size={buttonSize}
      sx={{ fontWeight: 'bold', py: 1, px: valueOrUndefined(noPx, 0) }}
      onClick={onClick}
      data-cy={dataCy}
    >
      <Box
        sx={(t) => ({
          display: 'flex',
        })}
      >
        <Arrow
          direction="left"
          sx={(t) => ({
            borderRadius: '100%',
            color: t.palette.background.paper,
            background: t.palette.primary.main,
            p: 0.5,
            mr: 1,
          })}
          fontSize={iconFontSize}
        />
      </Box>
      {t(translation)}
    </Button>
  )
}
export const GoBackButtonMobile = (props: Props) => (
  <GoBackButtonBase buttonSize="medium" iconFontSize="large" onlyIcon {...props} />
)

export const GoBackButtonDesktop = (props: Props) => <GoBackButtonBase {...props} />
export const GoBackButton = (props: Props) => {
  const isTablet = useIsTablet()
  return isTablet ? <GoBackButtonMobile {...props} /> : <GoBackButtonDesktop {...props} />
}
