import React from 'react'
import { useTranslation } from 'react-i18next'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Typography from '@mui/material/Typography'
import { SimpleTreeView } from '@mui/x-tree-view'
import { DepartmentTreeItems } from 'components/DepartmentTree/DepartmentTreeItems'
import { DepartmentTreeProps } from 'components/DepartmentTree/types'
import { UseDepartments } from 'components/DepartmentTree/useDepartments'
import Loader from 'components/Loader/Loader'

interface DepartmentTreeViewProps
  extends Pick<DepartmentTreeProps, 'selected' | 'setSelected'>,
    Pick<UseDepartments, 'data' | 'isLoading' | 'selectedAncestors' | 'expanded' | 'handleToggle'> {}

export const DepartmentTreeView = ({
  selected,
  setSelected,
  expanded,
  data,
  isLoading,
  selectedAncestors,
  handleToggle,
}: DepartmentTreeViewProps) => {
  const { t } = useTranslation()

  return (
    <Loader isLoading={isLoading}>
      <SimpleTreeView
        data-cy="department-tree-container"
        expandedItems={expanded}
        selectedItems={selected || ''}
        onExpandedItemsChange={handleToggle}
        slots={{
          collapseIcon: () => (
            <ArrowBackIosIcon
              color="primary"
              sx={{ transform: 'rotate(90deg) translateX(-25%)', transformOrigin: 'bottom' }}
            />
          ),
          expandIcon: () => (
            <ArrowBackIosIcon
              color="primary"
              data-cy="expand-icon"
              sx={{ transform: 'rotate(-90deg) translate(75%, 100%)', transformOrigin: 'bottom' }}
            />
          ),
        }}
        multiSelect={false}
      >
        {data?.length ? (
          <DepartmentTreeItems
            data={data}
            selected={selected}
            setSelected={setSelected}
            selectedAncestors={selectedAncestors}
          />
        ) : (
          <Typography textAlign="center" variant="body2">
            {t('app.filters.no_found')}
          </Typography>
        )}
      </SimpleTreeView>
    </Loader>
  )
}
