import isEmpty from 'lodash/isEmpty'
import qs from 'query-string'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'

export const fetchProductsInBrand = (id: string, nextId = 1, search?: string) => {
  const paramsUrl = qs.parse(search?.replace('?', ''))
  let params = ''
  if (nextId) {
    params += `&limit=15&page=${nextId}`
  }
  if (!isEmpty(paramsUrl?.searchProduct)) {
    params += `&search=${paramsUrl?.searchProduct}`
  }
  if (!isEmpty(paramsUrl?.lang)) {
    params += `&lang=${paramsUrl?.lang}`
  }
  const sortParam = Object.keys(paramsUrl).find((p) => p.startsWith('sort'))
  if (sortParam) {
    params += `&${sortParam}=${paramsUrl?.[sortParam]}`
  }
  const filterParams = Object.keys(paramsUrl).filter((p) => p.startsWith('filter'))
  if (!isEmpty(filterParams)) {
    filterParams.forEach((param) => {
      // |ep special filter type - filtering products that the user can afford
      params += `&${param.replace('|ep', '|lt')}=${paramsUrl?.[param]}`
    })
  }
  return getRequest<WithPagination<CatalogProduct>>(
    `/redemption-site/products?filters[product.brand.id|eq]=${id}${params}`
  )
}
