import React from 'react'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react'

interface MessageEmojiSelectProps {
  closeEmojiSelect: () => void
  onEmojiClick: (emoji: string) => void
}
export const MessageEmojiSelect = ({ closeEmojiSelect, onEmojiClick }: MessageEmojiSelectProps) => {
  return (
    <ClickAwayListener onClickAway={closeEmojiSelect}>
      <Box sx={{ position: 'absolute', right: 0, bottom: 0, zIndex: 2 }}>
        <EmojiPicker
          emojiVersion="5.0"
          emojiStyle={EmojiStyle.APPLE}
          skinTonesDisabled
          onEmojiClick={(emoji) => onEmojiClick(emoji.emoji)}
        />
      </Box>
    </ClickAwayListener>
  )
}
