import { useLocation } from 'react-router-dom'
import qs from 'query-string'

export const NON_FILTER_KEYS = ['lang', 'searchBirthday', 'info']

export const useCountFilters = () => {
  const { search } = useLocation()

  const params = qs.parse(search, { decode: false })
  const countFilters = Object.keys(params).filter((k) => !NON_FILTER_KEYS.includes(k))?.length

  return {
    countFilters,
  }
}
