import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { CookiesContextState } from 'components/CookiesDialog/types'
import { LoaderFullScreen } from 'components/LoaderFullScreen/LoaderFullScreen'
import { useCookiesContext } from 'store/useCookiesContext'
import { getCookie } from 'utils/cookies'
import { TranslationsKeys } from 'utils/createTranslationKey'
const COOKIES_ACCEPT_KEY = 'cookies_accept'

export const CookiesDialog = () => {
  const { t } = useTranslation()
  const { isCookieBannerVisible, savePreferences, openDetails, isCookieDetailsVisible, state } = useCookiesContext()
  const [componentState, setComponentState] = useState<Omit<CookiesContextState, 'acceptedAt'>>(state)
  const [isReload, setReload] = useState(false)

  const save = (savingState: Omit<CookiesContextState, 'acceptedAt'>) => {
    const haveCookies = !!getCookie(COOKIES_ACCEPT_KEY)
    savePreferences(savingState)
    if (
      (savingState.isFunctionalAccepted !== state.isFunctionalAccepted ||
        savingState.isPerformanceAccepted !== state.isPerformanceAccepted) &&
      haveCookies
    ) {
      setReload(true)
      setTimeout(() => document.location.reload(), 2000)
    }
  }

  const acceptAllCookies = () => {
    const data = {
      isFunctionalAccepted: true,
      isPerformanceAccepted: true,
      isNecessaryAccepted: true,
    }
    setComponentState(data)
    save(data)
  }

  const acceptNecessaryCookies = () => {
    const data = {
      isFunctionalAccepted: false,
      isPerformanceAccepted: false,
      isNecessaryAccepted: true,
    }
    setComponentState(data)
    save(data)
  }

  if (isReload) {
    return <LoaderFullScreen />
  }

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isCookieBannerVisible} onClick={() => {}}>
        <Box
          sx={(theme) => ({
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            py: 2,
            px: 8,
            background: (theme) => theme.palette.background.paper,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 2,
            zIndex: 9999,
            borderTop: '1px solid #e0e0e0',
            [theme.breakpoints.down('md')]: {
              px: 2,
            },
          })}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md>
              <Typography variant="h4">{t('cookies.about')}</Typography>
              <Typography variant="body1">{t('cookies.about_text')}</Typography>
            </Grid>
            <Grid item xs={12} md="auto" display="flex" justifyContent="center">
              <Button variant="text" color="primary" size="small" onClick={() => openDetails()}>
                {t('cookies.management')}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md="auto"
              display="flex"
              justifyContent="center"
              order={{
                md: 0,
                xs: 1,
              }}
            >
              <Button variant="outlined" color="primary" size="small" onClick={acceptNecessaryCookies}>
                {t('cookies.accept_necessary')}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md="auto"
              display="flex"
              justifyContent="center"
              order={{
                md: 1,
                xs: 0,
              }}
            >
              <Button variant="contained" color="primary" size="small" onClick={acceptAllCookies}>
                {t('cookies.accept_all')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
      <Dialog open={isCookieDetailsVisible}>
        <DialogTitle component="div">
          <Typography variant="h2">{t('cookies.management')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h3">{t('cookies.privacy_preferences_center')}</Typography>
          <Typography variant="body1">{t('cookies.privacy_preferences_center_text')}</Typography>
          <Box display="flex" justifyContent="center" sx={{ py: 2 }}>
            <Button variant="contained" color="primary" size="small" onClick={acceptAllCookies}>
              {t('cookies.accept_all')}
            </Button>
          </Box>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('cookies.manage_cookies_preferences')}
          </Typography>
          <AccordionWrapper translation="cookies.strictly_necessary" value={true} disabled>
            <Typography variant="body1">{t('cookies.manage_cookies_preferences_text')}</Typography>
          </AccordionWrapper>
          <AccordionWrapper
            translation="cookies.functional"
            value={componentState.isFunctionalAccepted}
            onChange={() =>
              setComponentState((prev) => ({ ...prev, isFunctionalAccepted: !prev.isFunctionalAccepted }))
            }
          >
            <Typography variant="body1">{t('cookies.functional_text')}</Typography>
          </AccordionWrapper>
          <AccordionWrapper
            translation="cookies.performance"
            value={componentState.isPerformanceAccepted}
            onChange={() =>
              setComponentState((prev) => ({ ...prev, isPerformanceAccepted: !prev.isPerformanceAccepted }))
            }
          >
            <Typography variant="body1">{t('cookies.performance_text')}</Typography>
          </AccordionWrapper>
          <Box display="flex" justifyContent="center" sx={{ py: 2 }}>
            <Button variant="contained" color="primary" size="small" onClick={() => save(componentState)}>
              {t('cookies.save_preferences')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

interface AccordionWrapperProps {
  translation: TranslationsKeys
  children: ReactNode
  value: boolean
  onChange?: (isEnabled: boolean) => void
  disabled?: boolean
}
const AccordionWrapper = ({ value, onChange, translation, children, disabled, ...props }: AccordionWrapperProps) => {
  const { t } = useTranslation()

  return (
    <Accordion
      sx={{
        '&::before': { display: 'none' },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" {...props}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            {t(translation)}
          </Grid>
          <Grid
            item
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
            }}
          >
            <Switch
              size="small"
              checked={value}
              onClick={(event) => {
                onChange?.(!value)
                event.preventDefault()
                event.stopPropagation()
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
