import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { captureMessage } from '@sentry/browser'
import { isAxiosError } from 'axios'
import Avatar from 'components/Avatar'
import Loader from 'components/Loader/Loader'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import {
  exchangeToken,
  ExchangeTokenData,
  ExchangeTokenResponse,
  exchangeTokenTypes,
} from 'pages/Login/SSO/exchangeToken'
import { loginRequest } from 'pages/Login/SSO/msalConfig'
import { useAuthContext } from 'store/useAuthContext'
import { setAuthTokens } from 'utils/axios-jwt-cookies'

export const LoggedAzure = () => {
  const [loader, setLoader] = useState(false)
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { t } = useTranslation()
  const { refetch: refreshUser } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated && !isEmpty(accounts) && !!instance?.acquireTokenSilent) {
      accounts.forEach((acc) => {
        // checking if token is expired
        if (acc?.idTokenClaims?.exp * 1000 < new Date().getTime()) {
          try {
            // refreshing token
            instance
              ?.acquireTokenSilent({
                ...loginRequest,
                account: acc,
              })
              .then(() => {
                console.log('[AZURE]: token refreshed')
              })
              .catch((error) => {
                captureMessage(`[AZURE]: problem with refreshing token ${JSON.stringify(error)}`)
                instance.loginRedirect({
                  ...loginRequest,
                  account: acc,
                })
              })
          } catch (e) {
            // clearing cache if token is invalid and refreshing token failed
            instance.clearCache()
            enqueueSnackbar(t('sso.azure.invalid_token'), { variant: 'error' })
          }
        } else {
          // if token is not expired, we are checking if there is only one account and if user requested login
          if (isAuthenticated && accounts?.length === 1 && localStorage.getItem('azureLoginRequest') === 'true') {
            const acc = accounts.find((acc) => acc?.idTokenClaims?.exp * 1000 > new Date().getTime())
            if (acc) {
              localStorage.setItem('azureLoginRequest', 'false')
              handleSelectAzureAccount(acc)()
            }
          }
        }
      })
    }
  }, [isAuthenticated, accounts, instance?.acquireTokenSilent])

  const getAzureToken = async (acc: AccountInfo): Promise<AuthenticationResult> => {
    try {
      return await instance?.acquireTokenSilent({
        ...loginRequest,
        account: acc,
      })
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.loginRedirect({ ...loginRequest, account: acc })
      } else {
        captureMessage(`[AZURE]: problem with getting token ${JSON.stringify(error)}`)
        enqueueSnackbar(t('sso.okta.problem_with_fetch_user'), { variant: 'error' })
      }
    }
  }

  const exchangeTokenFromAzureToArteel = async (
    exchangeTokenData: ExchangeTokenData
  ): Promise<ExchangeTokenResponse> => {
    try {
      return await exchangeToken(exchangeTokenData)
    } catch (error) {
      captureMessage(`[AZURE]: problem with exchanging token ${JSON.stringify(error)}`)
      enqueueSnackbar(t('sso.okta.problem_with_fetch_user'), { variant: 'error' })
    }
  }

  const handleSelectAzureAccount = (acc: AccountInfo) => async () => {
    setLoader(true)
    try {
      const response = await getAzureToken(acc)
      if (!response || !response?.accessToken) return
      const data = await exchangeTokenFromAzureToArteel({
        token: response?.accessToken,
        type: exchangeTokenTypes.azure,
      })
      if (!data?.data?.access_token) return

      await setAuthTokens({
        accessToken: data?.data?.access_token,
        refreshToken: data?.data?.refresh_token,
      })
      await refreshUser()
        .then((data) => {
          if (data?.isError) {
            console.info('[AZURE]: problem with refetching user data')
            enqueueSnackbar(
              isAxiosError(data?.error) && data?.error?.response?.data
                ? data?.error?.response?.data
                : t('sso.okta.problem_with_fetch_user'),
              { variant: 'error' }
            )
          } else {
            console.info('[AZURE]: refetched user data')
            navigate('/')
          }
        })
        .catch((error) => {
          console.info('[AZURE]: problem with refetching user data', error)
          navigate('/auth/logout')
        })
    } catch (error) {
      enqueueSnackbar(t('sso.okta.problem_with_fetch_user'), { variant: 'error' })

      return console.error('[AZURE]: problem with integration', error)
    } finally {
      setLoader(false)
    }
  }

  return (
    <Grid item xs={12}>
      <Loader visibleChildren isLoading={loader}>
        <List sx={{ width: '100%' }}>
          {accounts.map((acc, index, array) => (
            <Fragment key={acc.username}>
              <ListItem alignItems="flex-start">
                <ListItemButton onClick={handleSelectAzureAccount(acc)}>
                  <ListItemAvatar>
                    <Avatar fullName={acc.name} width={45} height={45} fontSize={1.5} />
                  </ListItemAvatar>
                  <ListItemText primary={acc.name} secondary={acc.username} />
                </ListItemButton>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Fragment>
          ))}
          <ListItem alignItems="center">
            <ListItemButton onClick={() => instance.logoutRedirect()}>
              <ListItemText primary={t('app.logout.from_azure')} primaryTypographyProps={{ align: 'center' }} />
            </ListItemButton>
          </ListItem>
          <ListItem alignItems="center">
            <ListItemButton onClick={() => instance.loginRedirect(loginRequest)}>
              <ListItemText primary={t('app.login.different_account')} primaryTypographyProps={{ align: 'center' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Loader>
    </Grid>
  )
}
