export type Node<Extension = {}> = Extension & {
  id: number
  __children: Node<Extension>[] | null
}

type NodeWithProperty<Property extends string, Extension extends object = {}> = Node<
  Extension & { [key in Property]: string }
>

export type NodeWithAncestors<Extension extends object = {}> = Node<Extension & { ancestors: number[] }>

type Filter<Property> = { fieldName: Property; fieldValue: string }

interface Props<Property extends string, Extension extends object> {
  tree: NodeWithProperty<Property, Extension>[]
  filter: Filter<Property>
}

const doStringContains = (str: string, contains: string) => {
  return str.toLocaleLowerCase().includes(contains.toLocaleLowerCase())
}

export const filterTreeStructure = <Property extends string, Extension extends object>({
  tree,
  filter,
}: Props<Property, Extension>) => {
  return tree.reduce(
    (acc, obj) => {
      let children = [...(obj?.__children || [])]

      if (children.length > 0) {
        children = filterTreeStructure({ tree: children, filter })
      }

      if (children.length > 0 || doStringContains(obj[filter.fieldName], filter.fieldValue)) {
        acc.push({ ...obj, __children: children })
      }

      return acc
    },
    [] as NodeWithProperty<Property, Extension>[]
  )
}

export const addAncestors = <Extension extends object>({
  tree,
}: {
  tree: Node<Extension>[]
}): NodeWithAncestors<Extension>[] => {
  return tree.map((node) => {
    if (node.__children?.length === 0) {
      return { ...node, __children: [], ancestors: [] }
    }

    const childrenWithAncestors = addAncestors({ tree: node.__children }).map((childNode) => ({
      ...childNode,
      ancestors: [node.id, ...('ancestors' in childNode ? childNode.ancestors : [])],
    }))

    return { ...node, __children: childrenWithAncestors, ancestors: [] }
  })
}

export const findInTree = <Extension extends object>({
  tree,
  findFunction,
}: {
  tree: Node<Extension>[]
  findFunction: (node: Node<Extension>) => boolean
}): Node<Extension> | undefined => {
  for (const node of tree) {
    if (findFunction(node)) return node

    const searchChildren = findInTree({ tree: node.__children || [], findFunction })

    if (searchChildren) return searchChildren
  }
}
