import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Typography from '@mui/material/Typography'
import { lighten } from '@mui/system'
import { Arrow } from 'components/ArteelIcons'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ROOT_CATEGORY_ID } from 'pages/HomeRedemptionSite/utils'
import { useCategoriesDrawerContext } from 'theme/Mobile/CategoriesDrawer/hooks'
import { CONSTANTS } from 'theme/Mobile/CategoriesDrawer/styles'
import { getResponsiveSize } from 'theme/styles.utils'
import { Category } from 'utils/api/Categories/fetchCategories'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useCategories } from 'utils/hooks/api/useCategories'
import { important } from 'utils/styles'

export const CategoriesDrawerContent = () => {
  const match = matchPath<'categoryId', string>({ path: '/catalog/categories/:categoryId' }, window.location.pathname)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [listCategoryId, setListCategoryId] = useState(1)
  const { data, isLoading } = useCategories()
  const categories = data?.data?.categories_tree || []
  const selectedCategoryId = Number(match?.params?.categoryId)
  const { toggleCategoriesDrawer } = useCategoriesDrawerContext()
  const findCategory = (id: number, cats = categories) => {
    if (!id) return null
    let cat: Category

    cats.find((el) => {
      if (el.id === id) {
        cat = el
        return true
      }
      if (!el.__children.length) return
      cat = findCategory(id, el.__children)
      return cat
    })
    return cat
  }
  const hasSubCategories = (cat: Category) => !!cat.__children?.length
  const selectedCategory = findCategory(selectedCategoryId)
  const isSelectedCategory = (id: number) => {
    if (!match) return false
    return id === selectedCategoryId
  }

  const parentsIdsOfSelectedCategory = (selectedCat = selectedCategory): number[] => {
    if (!selectedCat) return []
    const parent = findCategory(selectedCat.parent_id)
    if (!parent) return []
    return [parent.id, ...parentsIdsOfSelectedCategory(parent)]
  }

  const isOneOfParentsSelectedCategory = (id: number) => parentsIdsOfSelectedCategory().includes(id)

  const parentOfCategories = (() => {
    const cat = findCategory(listCategoryId)
    if (cat?.id === ROOT_CATEGORY_ID) return { ...cat, name: t('categories') }
    return cat
  })()

  const categoriesList = (() => {
    if (!parentOfCategories) return []
    return parentOfCategories.__children
  })()

  const upperCategory = (() => {
    if (!parentOfCategories) return null
    const upperCategory = findCategory(parentOfCategories.parent_id)
    if (upperCategory?.id === ROOT_CATEGORY_ID) return { ...upperCategory, name: t('categories') }
    return upperCategory
  })()

  const isSelectedOrParent = (id: number) => isSelectedCategory(id) || isOneOfParentsSelectedCategory(id)

  const onCategoryClick = (id: number) => {
    navigate(`/catalog/categories/${id}`)
    toggleCategoriesDrawer(false)
  }

  useEffect(() => {
    const cat = findCategory(selectedCategoryId)
    setListCategoryId(cat?.parent_id || ROOT_CATEGORY_ID)
  }, [selectedCategoryId, isLoading])

  if (isLoading) return <Loader isLoading={true} />

  return (
    <>
      <Button
        sx={(t) => ({
          visibility: valueOrUndefined(!upperCategory, 'hidden'),
          color: t.palette.text.disabled,
          fontWeight: important(600),
          fontSize: important(t.typography.body2.fontSize),
          pl: 0,
        })}
        onClick={() => setListCategoryId(upperCategory?.id)}
        startIcon={<Arrow direction="left" sx={{ fontSize: important(getResponsiveSize(15, 'px')), mb: '2px' }} />}
      >
        {upperCategory?.name || 'placeholder'}
      </Button>
      <HeaderPage text={parentOfCategories?.name} sx={{ pt: 2, pr: 2 }} size="small" color="medium" />
      <MenuList sx={{ pr: CONSTANTS.paperHorizontalPadding }}>
        {categoriesList?.map((cat) => (
          <MenuItem
            key={cat.id}
            sx={(t) => ({
              transition: 'all .3s',
              background: important(valueOrUndefined(isSelectedOrParent(cat.id), lighten(t.palette.primary.main, 0.9))),
              px: 1,
            })}
          >
            <ListItemText onClick={() => onCategoryClick(cat.id)}>
              <Typography
                variant="body2"
                fontWeight="bold"
                component="span"
                sx={{
                  color: (t) => (isSelectedCategory(cat.id) ? t.palette.primary.main : t.palette.text.primaryLight),
                }}
              >
                {cat.name}
                <Typography
                  variant="body2"
                  component="span"
                  fontWeight="bold"
                  sx={{ color: (t) => t.palette.text.disabled }}
                >
                  {` (${cat?.count})`}
                </Typography>
              </Typography>
            </ListItemText>
            <IconButton onClick={() => hasSubCategories(cat) && setListCategoryId(cat.id)}>
              <Arrow
                direction="right"
                fontSize="small"
                sx={(t) => ({
                  visibility: valueOrUndefined(!hasSubCategories(cat), 'hidden'),
                  fontSize: important(getResponsiveSize(15, 'px')),
                  mb: '2px',
                  ml: 'auto',
                  color: t.palette.text.primaryLight,
                })}
              />
            </IconButton>
          </MenuItem>
        ))}
        <MenuItem
          key="shop-all"
          sx={(t) => ({
            px: 1,
          })}
        >
          <ListItemText
            onClick={() => {
              navigate(`/catalog/categories/${ROOT_CATEGORY_ID}`)
              toggleCategoriesDrawer(false)
            }}
          >
            <Typography
              variant="body2"
              fontWeight={900}
              component="span"
              sx={{ color: (t) => t.palette.text.primaryLight }}
            >
              {t('shop_all')}
            </Typography>
          </ListItemText>
        </MenuItem>
      </MenuList>
    </>
  )
}
