import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { ROOT_CATEGORY_ID } from 'pages/HomeRedemptionSite/utils'

export const ShopAllButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Button
      size="small"
      color="primary"
      variant="outlined"
      sx={(t) => ({
        whiteSpace: 'nowrap',
        [t.breakpoints.down('sm')]: {
          padding: '0.3rem 0.6rem',
        },
      })}
      onClick={() => navigate(`/catalog/categories/${ROOT_CATEGORY_ID}`)}
    >
      {t('shop_all_button')}
    </Button>
  )
}
