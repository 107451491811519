import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import qs from 'query-string'
import { BirthdayPerson } from 'utils/api/Birthdays/BirthdayPerson'
import birthdayQueryKeys from 'utils/api/Birthdays/birthdayQueryKeys'
import { fetchBirthdays } from 'utils/api/Birthdays/fetchBirthdays'

export interface Birthday {
  month: number
  persons: BirthdayPerson[]
}

export const useBirthdays = () => {
  const location = useLocation()
  const paramsUrl = qs.parse(location?.search.replace('?', ''))
  const searchBirthday = (paramsUrl?.searchBirthday as string) || ''
  const organisation = (paramsUrl?.organisation as string) || ''

  const query = useQuery({
    queryKey: birthdayQueryKeys.birthdayList(organisation),
    queryFn: () => fetchBirthdays(organisation),
    select: (response) => response.data,
  })

  const data = useMemo(
    () =>
      Object.entries(query.data || []).map(([month, persons]) => ({
        month: +month,
        persons: persons.filter(
          (profile) => profile.full_name && profile.full_name.toLowerCase().includes(searchBirthday.toLocaleLowerCase())
        ),
      })),
    [query.data, searchBirthday]
  )

  return { ...query, data }
}
