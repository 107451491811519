import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { CommentBubble } from 'components/ArteelIcons/Comment'
import { ThumbUp } from 'components/ArteelIcons/ThumbUp'
import { actionButtonSx } from 'pages/SocialWall/components/utils'
import { useLike } from 'utils/hooks/api/SocialWall/useLike'

interface Props {
  onCommentButtonClick: () => void
  isAddCommentOpen: boolean
  isLiked: boolean
  postId: number
}
export const PostActionsButtons = ({ onCommentButtonClick, isAddCommentOpen, isLiked, postId }: Props) => {
  const { t } = useTranslation()
  const {
    removeLike: { mutate: removeLikeMutate, isLoading: removeLikeIsLoading },
    addLike: { mutate: addLikeMutate, isLoading: addLikeIsLoading },
  } = useLike({ postId })

  return (
    <>
      <Divider />
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <Button
          sx={actionButtonSx}
          variant="text"
          color="primary"
          data-cy="like-button"
          startIcon={<ThumbUp active={isLiked} sx={{ mb: 0.4 }} />}
          onClick={() => {
            if (removeLikeIsLoading || addLikeIsLoading) return
            isLiked ? removeLikeMutate() : addLikeMutate()
          }}
        >
          {t('recognition_post.like')}
        </Button>
        <Button
          sx={actionButtonSx}
          onClick={onCommentButtonClick}
          variant={'text'}
          color="primary"
          data-cy="add-comment-button"
          startIcon={<CommentBubble active={isAddCommentOpen} />}
        >
          {t('recognition_post.comment')}
        </Button>
      </Box>
    </>
  )
}
