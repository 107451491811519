import React from 'react'
import Grid from '@mui/material/Grid'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikMessage } from 'components/Formik/FormikMessage'
import { Form, Formik, FormikConfig } from 'formik'
import { MAX_COMMENT_LENGTH, useValidationSchema } from 'pages/SocialWall/components/comment/utils'
import { PostComment } from 'utils/api/SocialWall/types'
import { useCommentUpdate } from 'utils/hooks/api/SocialWall/useCommentUpdate'

interface Props {
  comment: PostComment
  onExit: () => void
  postId: number
}
type FormValues = {
  content: string
}

export const CommentEdit = ({ comment, onExit, postId }: Props) => {
  const { mutate, isLoading } = useCommentUpdate({ postId, onSuccess: onExit })
  const { validationSchema } = useValidationSchema()

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = ({ content }) => {
    mutate({ content, id: comment.id })
  }

  return (
    <Formik
      initialValues={{
        content: comment.content,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialTouched={{ content: true }}
    >
      <Form data-cy="edit-comment-form">
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12}>
            <FormikMessage name={`content`} size="small" autoFocus maxLength={MAX_COMMENT_LENGTH} />
          </Grid>
          <Grid item xs={12}>
            <FormikButtons
              onBack={onExit}
              isLoading={isLoading}
              textButtonCancel="app.cancel"
              textButtonSubmit="app.update"
              gridLayout
              size="small"
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}
