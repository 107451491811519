import React from 'react'
import Avatar from 'components/Avatar'

interface Props {
  avatar?: string
  fullName: string
}

export const CommentAvatar = ({ avatar, fullName }: Props) => (
  <Avatar image={avatar} fullName={fullName} height={30} width={30} fontSize={1} />
)
