import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useQueryClient } from '@tanstack/react-query'
import { userConsentQueryKeys } from 'shared/UserConsent/slice'

export const UserConsentError = memo(() => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return (
    <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <ErrorOutlineIcon fontSize="large" color="error" />
      </Grid>
      <Grid item>
        <Typography fontWeight={600} variant="h2">
          {t('app.error.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography paragraph variant="h4">
          {t('app.error.body')}
        </Typography>
      </Grid>
      <Grid item>
        <Button sx={{ textAlign: 'center' }} onClick={() => queryClient.invalidateQueries(userConsentQueryKeys.all)}>
          {t('app.refresh')}
        </Button>
      </Grid>
    </Grid>
  )
})
