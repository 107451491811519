import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { useBirthdaysQueryParams } from 'pages/Birthdays/useBirthdaysQueryParam'
import { BirthdayPerson } from 'utils/api/Birthdays/BirthdayPerson'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useBirthdays } from 'utils/hooks/api/Birthdays/useBirthdays'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { useSocialWall } from 'utils/hooks/useSocialWall'

import { BirthdaysGrid } from './components/BirthdaysGrid'
import { Filters } from './components/Filters'
import { SearchBirthday } from './components/SearchBirthday'

export const Birthdays = () => {
  const { t } = useTranslation()
  const { selectedMonths } = useBirthdaysQueryParams()
  const { isBirthdayWidgetActive } = useSocialWall()

  const isTablet = useIsTablet()
  const { data: birthdays = [], isFetching } = useBirthdays()

  const filteredBirthdays = () => {
    if (!selectedMonths.length) return birthdays
    return birthdays.filter((el) => selectedMonths.includes(el.month))
  }

  if (!isBirthdayWidgetActive) return <Navigate to={`/404`} />

  return (
    <WhitePaper
      changeViewDisable
      showPointsDisable
      FilterComponent={Filters}
      searchParam={valueOrUndefined(isTablet, 'searchBirthday')}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderPage text={t('birthdays')} size="large" />
        </Grid>
        {!isTablet && (
          <Grid item xs={12}>
            <SearchBirthday birthdays={birthdays} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Loader
            isLoading={isFetching}
            objects={birthdays?.reduce((prev, birth) => [...prev, ...birth.persons], [] as BirthdayPerson[])}
          >
            <BirthdaysGrid birthdays={filteredBirthdays()} />
          </Loader>
        </Grid>
      </Grid>
    </WhitePaper>
  )
}
