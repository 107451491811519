import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { AuthLoggedUser } from 'components/AuthLoggedUser/AuthLoggedUser'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { LoginForm } from 'pages/Login/LoginForm'
import { redirectOnLogin } from 'pages/Login/redirectOnLogin'
import { FormValues } from 'pages/Login/types'
import { WrapperLoginForm } from 'pages/Login/WrapperLoginForm'
import { useAuthContext } from 'store/useAuthContext'
import { login } from 'utils/api/apiClient'
import { isLoggedIn } from 'utils/axios-jwt-cookies'
import { getProjectWithSocialWallFromUserProjects } from 'utils/hooks/useSocialWall'
import * as Yup from 'yup'

export const LoginPage = () => {
  const { refetch: refreshUser, user } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync } = useMutation(login, {
    onSuccess: async () => {
      const data = await refreshUser()
      const isSocialWallActive = !!getProjectWithSocialWallFromUserProjects(data?.data?.data?.projects || {})
      redirectOnLogin(navigate, isSocialWallActive, location?.search)
      enqueueSnackbar(t('auth.login.success'), { variant: 'success' })
    },
    onError: async () => {
      enqueueSnackbar(t('auth.login.error'), { variant: 'error' })
    },
  })

  const onSubmit = (values: { username: string; password: string }) => mutateAsync(values)

  const validationSchema = Yup.object().shape({
    username: Yup.string().label(t('auth.login.username')).required(),
    password: Yup.string().label(t('auth.login.password')).required(),
  })

  const initialValues: FormValues = {
    username: '',
    password: '',
  }

  if (isLoggedIn() && !!user) return <AuthLoggedUser />

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form style={{ height: '100%' }}>
        <WrapperLoginForm>
          <LoginForm />
        </WrapperLoginForm>
      </Form>
    </Formik>
  )
}
