import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'

import { AccordionCheckboxRow } from './AccordionCheckboxRow'
import { AccordionCheckboxBaseProps, AccordionCheckboxesProps } from './types'

export const AccordionCheckboxes = <T extends AccordionCheckboxBaseProps>({
  options = [],
  onChange,
  initialValues,
  error,
  isLoading,
}: AccordionCheckboxesProps<T>) => {
  const [selected, setSelected] = useState<number[]>([])
  const handleChange = (v: T, checked: boolean) => {
    setSelected((prev) => (checked ? [...prev, v.id] : prev.filter((el) => el !== v.id)))
  }
  useEffect(() => {
    onChange(selected)
  }, [selected])

  useEffect(() => {
    if (initialValues?.length) {
      setSelected(initialValues)
    }
  }, [initialValues])

  if (isLoading) return <Loader isLoading />
  return (
    <>
      {options.map((el, i) => (
        <AccordionCheckboxRow
          isChecked={selected.includes(el.id)}
          onChange={handleChange}
          value={el}
          key={el.id}
          first={i === 0}
        />
      ))}
      {!!error && (
        <Typography ml="14px" mt="4px" color="error" data-cy="accordion-checkbox-helper-text">
          {error}
        </Typography>
      )}
    </>
  )
}
