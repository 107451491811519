import React, { ReactNode } from 'react'
import { TypographyProps } from '@mui/material'
import Typography from '@mui/material/Typography'

interface Props extends TypographyProps {
  children: ReactNode | ReactNode[]
}

export const TypographyWithIcon = ({ children, ...props }: Props) => {
  return (
    <Typography
      sx={{ ...props.sx, color: (t) => t.palette.grey['600'] }}
      display="inline-flex"
      alignItems="center"
      {...props}
    >
      {children}
    </Typography>
  )
}
