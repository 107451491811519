import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DepartmentTree } from 'components/DepartmentTree/DepartmentTree'
import { DrawerSelect } from 'components/DrawerSelect'
import { useBirthdaysQueryParams } from 'pages/Birthdays/useBirthdaysQueryParam'
import { useCountFilters } from 'utils/hooks/useCountFilters'

export const Filters = ({ gutterTop }: { gutterTop?: number }) => {
  const { t } = useTranslation()
  const { pushParam, params, resetFilters } = useBirthdaysQueryParams()
  const { countFilters } = useCountFilters()

  return (
    <DrawerSelect label={t('organisation')} countFilters={countFilters} gutterTop={gutterTop}>
      <Grid container justifyContent="center" gap={3} sx={{ paddingBottom: 3 }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h1">{t('organisation')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <DepartmentTree
            selected={params.organisation}
            setSelected={(id) => pushParam({ organisation: id })}
            searchProps={{
              iconProps: { color: 'disabled' },
              searchSx: {
                '& .MuiOutlinedInput-root': {
                  background: (theme) => theme.palette.background.paper.concat('!important'),
                },
              },
            }}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Grid item xs={4}>
            <Button variant="outlined" color="primary" fullWidth onClick={() => resetFilters(['organisation'])}>
              {t('remove_organisation_filters')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DrawerSelect>
  )
}
