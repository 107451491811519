import React, { Suspense, useState } from 'react'
import { Trans } from 'react-i18next'
import { inputBaseClasses } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { MessageBottomBar } from 'components/Message/components/MessageBottomBar'
import { MessageEmojiSelect } from 'components/Message/components/MessageEmojiSelect'
import { MessageBaseProps } from 'components/Message/types'
import { sxText, textLengthCounter } from 'components/Message/utils'
import { FormikValues, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { makeSx } from 'utils/styles/makeSx'
import { important, inside } from 'utils/styles/stylesUtils'

export type MessageEditProps = Pick<
  MessageBaseProps,
  | 'size'
  | 'placeholder'
  | 'minRows'
  | 'autoFocus'
  | 'textareaRef'
  | 'onChange'
  | 'onBlur'
  | 'maxLength'
  | 'bottomBarText'
  | 'name'
  | 'variant'
>

export const MessageEdit = ({
  size,
  placeholder,
  minRows = 0,
  autoFocus,
  maxLength,
  bottomBarText,
  name,
  textareaRef,
  variant,
}: MessageEditProps) => {
  const [showEmoji, setShowEmoji] = useState(false)
  const { setValues, getFieldProps } = useFormikContext<FormikValues>()
  const { value, onChange, onBlur } = getFieldProps(name)
  const [visiblePlaceholder, setVisiblePlaceholder] = useState(true)

  const textLength = textLengthCounter(value)
  const maxLengthOverseeded = !maxLength ? false : maxLength - textLength < 0

  const onEmojiClick = (emoji: string) => setValues((prev) => ({ ...prev, [name]: prev[name] + emoji }))

  const sxInput = makeSx((theme) => {
    const { fontSize, lineHeight } = sxText(theme)
    return {
      borderWidth: important(0),
      boxShadow: important('none'),
      padding: important(0),
      [inside(inputBaseClasses.input)]: {
        p: '0!important',
        fontSize,
        lineHeight,
        fontWeight: 400,
        background: theme.palette.background[variant === 'default' ? 'default' : 'paper'],
      },
      ['& fieldset']: {
        outlineColor: important('transparent'),
        borderColor: important('transparent'),
      },
      '& textarea::placeholder': {
        color: 'red',
        whiteSpace: 'pre-line',
      },
    }
  })
  const showAbsoluteBottomBar = !value && minRows < 2

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        inputRef={textareaRef}
        autoFocus={autoFocus}
        name={name}
        multiline
        value={value}
        minRows={minRows}
        placeholder={''}
        size="small"
        onChange={onChange}
        onBlur={(event) => {
          onBlur(event)
          setVisiblePlaceholder(true)
        }}
        onFocus={() => setVisiblePlaceholder(false)}
        InputProps={{
          sx: sxInput,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          visibility: visiblePlaceholder && isEmpty(value) ? 'visible' : 'hidden',
          color: (theme) =>
            visiblePlaceholder && isEmpty(value) ? theme.palette.grey[400] : theme.palette.common.white,
          transition: 'all 0.2s ease-in-out',
        }}
      >
        <Trans i18nKey={placeholder as any} />
      </Box>

      <MessageBottomBar
        absolute={showAbsoluteBottomBar}
        textLength={textLength}
        maxLength={maxLength}
        maxLengthOverseeded={maxLengthOverseeded}
        bottomBarText={bottomBarText}
        isEmojiOpen={showEmoji}
        size={size}
        onEmojiButtonClick={() => setShowEmoji((prev) => !prev)}
      />
      {showEmoji && (
        <Suspense fallback={''}>
          <MessageEmojiSelect closeEmojiSelect={() => setShowEmoji(false)} onEmojiClick={onEmojiClick} />
        </Suspense>
      )}
    </Box>
  )
}
