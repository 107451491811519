import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const Gift2 = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon
    {...rest}
    viewBox="0 0 118.3 154.1"
    // @ts-ignore
    style={{ enableBackground: 'new 0 0 118.3 154.1' }}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M90.2,37H75.4l19.7-19.7c3-3,3-7.8,0-10.7c-3-3-7.8-3-10.7,0L59.2,31.7L44.8,17.2c-3-3-7.8-3-10.7,0  c-3,3-3,7.8,0,10.7l9.1,9.1h-15c-14,0-25.3,11.3-25.3,25.3v62.1c0,14,11.3,25.3,25.3,25.3h62.1c14,0,25.3-11.3,25.3-25.3V62.4  C115.5,48.4,104.2,37,90.2,37z M100.3,62.4v23.4H66.7V52.2h23.4C95.8,52.2,100.3,56.8,100.3,62.4z M28.1,52.2h23.4v33.6H18V62.4  C18,56.8,22.5,52.2,28.1,52.2z M18,124.4V101h33.6v33.6H28.1C22.5,134.6,18,130,18,124.4z M90.2,134.6H66.7V101h33.6v23.4  C100.3,130,95.8,134.6,90.2,134.6z"
    />
  </SvgIcon>
)
