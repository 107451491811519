import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import { EMPTY_FILTER_VALUE, OnFilterChange } from 'pages/SocialWallPostFilters/utils'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useRecognitionValues } from 'utils/hooks/api/Values/useRecognitionValues'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

const FilterControl = ({
  label,
  value,
  translation,
  disabled,
  ...props
}: {
  value: string | number
  label?: string
  translation?: TranslationsKeys
  disabled?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <FormControlLabel
      value={value}
      control={<Radio disabled={disabled} />}
      label={
        <Typography
          sx={{ color: (t) => (disabled ? t.palette.text.disabled : t.palette.primary.main) }}
          fontWeight={600}
          fontSize={(t) => t.typography.h2.fontSize}
        >
          {t(translation)}
          {label}
        </Typography>
      }
      {...props}
    />
  )
}

interface FilterProps {
  value: string
  onChange: OnFilterChange
  disableMyself?: boolean
}
export const FilterFrom = ({ value, onChange, disableMyself }: FilterProps) => {
  const { id: userId } = useCurrentUser()

  return (
    <RadioGroup value={value} onChange={(e) => onChange('FROM_MYSELF', e.target.value)}>
      <FilterControl
        value={EMPTY_FILTER_VALUE}
        translation={'social_wall.filters.everyone'}
        data-cy="from-everyone-radio"
      />
      <FilterControl
        value={userId}
        translation={'social_wall.filters.myself'}
        disabled={disableMyself}
        data-cy="from-myself-radio"
      />
    </RadioGroup>
  )
}
export const FilterTo = ({ value, onChange, disableMyself }: FilterProps) => {
  const { id: userId } = useCurrentUser()

  return (
    <RadioGroup value={value} onChange={(e) => onChange('TO_MYSELF', e.target.value)}>
      <FilterControl
        value={EMPTY_FILTER_VALUE}
        translation={'social_wall.filters.everyone'}
        data-cy="to-everyone-radio"
      />
      <FilterControl
        value={userId}
        translation={'social_wall.filters.myself'}
        disabled={disableMyself}
        data-cy="to-myself-radio"
      />
    </RadioGroup>
  )
}
export const FilterValues = ({ value, onChange }: FilterProps) => {
  const { data: valuesData, isLoading } = useRecognitionValues()

  return (
    <>
      <RadioGroup value={value} onChange={(e) => onChange('VALUES', e.target.value)}>
        <FilterControl value={EMPTY_FILTER_VALUE} translation={'social_wall.filters.all'} />
        {valuesData?.data.data.map((el) => (
          <FilterControl
            key={el.id}
            value={el.id}
            label={getObjectTranslation(el.translation).name}
            data-cy={`value-radio-${el.id}`}
          />
        ))}
      </RadioGroup>
      <Loader isLoading={isLoading} />
    </>
  )
}
export const ResetFiltersButton = ({ resetFilters }: { resetFilters: () => void }) => {
  const { t } = useTranslation()

  return (
    <Button size="small" variant="outlined" fullWidth onClick={() => resetFilters()} data-cy="reset-filters-button">
      {t('social_wall.filters.remove_filters')}
    </Button>
  )
}
export const SubmitFiltersButton = ({ onFiltersSubmit }: { onFiltersSubmit: () => void }) => {
  const { t } = useTranslation()
  return (
    <Button
      size="small"
      variant="contained"
      onClick={() => onFiltersSubmit()}
      fullWidth
      data-cy="submit-filters-button"
    >
      {t('social_wall.filters.submit_filters')}
    </Button>
  )
}
