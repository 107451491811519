import React from 'react'
import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const Language = ({ active, ...rest }: SvgIconProps & IconActive) => {
  return (
    <SvgIcon viewBox="0 0 30 30" {...rest}>
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M15,1.3C7.4,1.3,1.3,7.4,1.3,15S7.4,28.7,15,28.7S28.7,22.6,28.7,15S22.6,1.3,15,1.3z M18,24.8   c0.6-1,1.5-1.8,2-2.9c1-1.8,0.2-3.5-1.2-4.5c-0.5-0.4-1-0.6-1.7-0.8C15.6,15.9,13,15.2,12,17c-0.2,0.5-0.4,0.8-0.4,1.4   c-0.4,1.8,0.6,2.7,1.4,4.2c0.4,0.8,0.4,1.7,0.2,2.5C8.5,24.2,4.8,20.1,4.8,15C4.8,12.1,6,9.5,8,7.6c0.4,1,0.4,2,0.5,3   c0.2,1.2,0.5,2.4,1.4,3.3c0.8,0.8,2.2,1.4,3.3,0.6c0.8-0.5,1.2-1.4,1.7-2.2c0.5-1,1.2-2,2.2-2.5c0.5-0.3,1-0.3,1.7-0.5   c1.7-0.2,3.3,0,4.9-0.4c1.2,1.7,1.8,3.7,1.8,5.9C25.2,19.5,22.2,23.6,18,24.8L18,24.8z" />
      </g>
    </SvgIcon>
  )
}
