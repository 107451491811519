import IconButton from '@mui/material/IconButton'
import { Search as SearchIcon } from 'components/ArteelIcons'

type Props = {
  onSearch: () => void
}

export const Search = ({ onSearch }: Props) => {
  return (
    <IconButton color="primary" onClick={onSearch} size="large">
      <SearchIcon />
    </IconButton>
  )
}
