import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const Support = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon
    {...rest}
    viewBox="0 0 134.5 134.5"
    // @ts-ignore
    style={{ enableBackground: 'new 0 0 134.5 134.5' }}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M58.7,71.9c0,4.3-3.5,7.8-7.8,7.8S43,76.3,43,71.9s3.5-7.8,7.8-7.8S58.7,67.6,58.7,71.9" />
      <path d="M91.5,71.9c0,4.3-3.5,7.8-7.8,7.8c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8C88,64.1,91.5,67.6,91.5,71.9" />
      <path d="M122.5,54.5v-4.4c0-26.6-21.6-48.2-48.2-48.2l-14.2,0C33.6,1.9,12,23.5,12,50.1v4.4c-5.5,0.4-9.9,5-9.9,10.5   v17.6c0,2.9,1.1,5.5,3.2,7.4c1.9,1.9,4.7,3,7.4,3h5.9c4,17.2,19.1,39.6,48.6,39.6c29.3,0,44.4-22.4,48.6-39.6h6.1   c2.8,0,5.5-1.1,7.4-3c2.1-1.9,3.2-4.7,3.2-7.4l0-17.5C132.4,59.4,128,54.9,122.5,54.5L122.5,54.5z M60.2,8.7h14.2   c22.8,0,41.4,18.6,41.4,41.4v4.3h-5.5v-4.3c0-19.8-16.1-35.9-35.9-35.9l-14.2,0c-19.8,0-35.9,16.1-35.9,35.9v4.3h-5.5v-4.3   C18.8,27.4,37.3,8.7,60.2,8.7L60.2,8.7z M67.2,123.8c-24.6,0-37-19.1-39.9-33.3V67.3c1-4.8,3-13.2,7.7-21.6c3.7,3,8.4,5.6,13.9,6.1   c0.5,0,1-0.5,0.7-1.1c-1.1-2.1-1.8-4.8-2.3-7.7c4.5,4.3,12.4,9.9,21.7,9.5c0.5,0,0.8-0.7,0.5-1.2c-1.2-1.7-2.2-3.9-3-6.2   c5.4,3.2,14,6.9,22.8,4.5c0.5-0.1,0.7-1,0.3-1.4c-1.7-1.5-3.2-3.6-4.5-5.9c3.7,2.1,9.2,4.3,15.1,4.5c4.5,8.5,6.5,16.8,7.3,21.2   v16.4c-0.8,1.2-1.8,2.3-2.9,3.4c-7,6.7-17.5,9.2-25.2,10.2c-0.5-1.9-2.2-3.4-4.4-3.4l-15.4,0c-2.5,0-4.5,2.1-4.5,4.5v5.5   c0,2.5,2.1,4.5,4.5,4.5H75c2.5,0,4.5-2.1,4.5-4.5c7.7-1,18-3.3,26.3-9.4C101.2,108.8,88.9,123.8,67.2,123.8L67.2,123.8z" />
    </g>
  </SvgIcon>
)
