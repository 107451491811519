import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { Image } from 'components/Image/Image'
import { ProductInfoBar } from 'components/ProductGrid/ProductInfoBar'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'
import { dataCy } from 'utils/cypressUtils'

export interface ProductGridImageProps {
  catalogProduct: CatalogProduct
}

export const ProductGridCard = forwardRef(({ catalogProduct }: ProductGridImageProps, ref) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { categoryId } = useParams<{ categoryId: string }>()

  const availableCategoryId = categoryId || catalogProduct?.product?.categories?.[0]?.id

  const productLink = () => {
    navigate(
      availableCategoryId
        ? `/catalog/categories/${availableCategoryId}/products/${catalogProduct.product.id}`
        : `/catalog/products/${catalogProduct.product.id}`
    )
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  const mediaPath = catalogProduct.product.media.find((m) => m.main)?.path || catalogProduct.product.media?.[0]?.path

  return (
    <Box ref={ref} data-cy={dataCy(`product-${catalogProduct.id as 1}`)}>
      <Tooltip title={t('link.product')} followCursor placement="right-start">
        <Box
          onClick={productLink}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Image src={mediaPath} />
        </Box>
      </Tooltip>
      <ProductInfoBar catalogProduct={catalogProduct} />
    </Box>
  )
})
