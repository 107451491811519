import React, { useContext } from 'react'
import IconButton from '@mui/material/IconButton'
import { Close, Menu } from 'components/ArteelIcons'
import { DrawerSelect } from 'components/DrawerSelect'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import { MainMenu } from 'pages/MainMenu/MainMenu'

const MobileMainMenu = () => {
  const { closeDrawer } = useContext(drawerContext)
  return <MainMenu onPushClick={closeDrawer} />
}
export const MobileMenuButton = () => {
  return (
    <DrawerSelect
      fullHeight
      CustomButton={({ open, onClick }) => (
        <IconButton color="primary" size="large" onClick={onClick} data-cy="mobile-menu-button">
          {open ? <Close /> : <Menu />}
        </IconButton>
      )}
    >
      <MobileMainMenu />
    </DrawerSelect>
  )
}
