import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column } from '@material-table/core'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import EditNoteOutlined from '@mui/icons-material/EditNoteOutlined'
import Visibility from '@mui/icons-material/Visibility'
import DisplayDate from 'components/DisplayDate'
import { DateRangeFilter } from 'components/Filters/DateRangeFilter'
import { actionsCreator } from 'utils/actionsCreator'
import { getObjectTranslation } from 'utils/getObjectTranslation'

import { PersonalMessage } from './types'

export const getColumn = ({ isHistory }: { isHistory?: boolean }): Column<PersonalMessage>[] => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  enum Fields {
    full_name = 'triggered_gift.receiver.full_name',
    deadline = 'deadline',
    dateWritten = 'date_written',
    occasion = 'triggered_gift.occasion.translations.name',
  }

  const getTitle = (name: Fields) => t(`personal_messages.${name}`)

  const currentActions = actionsCreator<PersonalMessage>({
    before: ({ id, has_content }) => ({
      icon: has_content ? <EditNoteOutlined /> : <AddCircleOutline />,
      onClick: () => navigate(`/personal-messages/edit/${id}`),
    }),
  })

  const historyActions = actionsCreator({
    before: {
      icon: <Visibility />,
      onClick: ({ id }) => navigate(`/personal-messages/view/${id}`),
    },
  })

  const actions = isHistory ? historyActions : currentActions

  return [
    {
      title: getTitle(Fields.full_name),
      field: Fields.full_name,
      render: ({ triggered_gift, id }) => triggered_gift?.receiver?.full_name || '',
    },
    {
      title: getTitle(isHistory ? Fields.dateWritten : Fields.deadline),
      field: Fields.deadline,
      filterComponent: DateRangeFilter,
      render: ({ deadline }) => <DisplayDate dateString={deadline} />,
    },
    {
      title: getTitle(Fields.occasion),
      field: Fields.occasion,
      render: ({ triggered_gift }) => getObjectTranslation(triggered_gift?.occasion?.translation)?.name,
    },
    actions,
  ]
}
