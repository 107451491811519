/* eslint-disable complexity */
/* eslint-disable react/no-danger */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Heart } from 'components/ArteelIcons'
import { Breadcrumbs, getCurrentCategoriesPath, MobileBreadcrumbs } from 'components/Breadcrumbs'
import { ButtonWithIcon } from 'components/ButtonWithIcon/ButtonWithIcon'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import Loader from 'components/Loader/Loader'
import { ProductVariants } from 'components/ProductVariants/ProductVariants'
import { PointsWrapper } from 'components/WhitePaper/Toolbar'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { ENVIRONMENT } from 'config/env'
import isEmpty from 'lodash/isEmpty'
import { getBreadcrumbsSegments } from 'pages/HomeRedemptionSite/utils'
import { AddItemToCart } from 'pages/ProductCard/components/AddItemToCart/AddItemToCart'
import { ProductName } from 'pages/ProductCard/components/ProductName'
import { useSelectWishlist } from 'shared/Wishlist/slice'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { ProductMedia, productTypes, VisualTypes } from 'utils/api/Products/CatalogProduct'
import { dataCy } from 'utils/cypressUtils'
import { displayPoints } from 'utils/displayPoints'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useBreadcrumbsCategories } from 'utils/hooks/api/useBreadcrumbsCategories'
import { useProduct } from 'utils/hooks/api/useProduct'
import { useAddItemToWishlist } from 'utils/hooks/api/Wishlist/useAddItemToWishlist'
import { useRemoveWishlistItem } from 'utils/hooks/api/Wishlist/useRemoveWishlistItem'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { isProductOutOfStock } from 'utils/isProductOutOfStock'
import { safeHtml } from 'utils/safeHtml'
import { makeStyles } from 'utils/styles/makeStyles'

import { ProductMediaContainer } from './components/Media/ProductMediaContainer'
import { Panels } from './components/Panels/Panels'

const useStyles = makeStyles((theme) => ({
  points: {
    fontSize: getResponsiveSize(1.75, 'rem'),
    [theme.breakpoints.down('lg')]: {
      fontSize: getResponsiveSize(1.5, 'rem'),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: getResponsiveSize(1.25, 'rem'),
    },
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[500],
    textAlign: 'right',
    textTransform: 'uppercase',
  },
}))

export const ProductCard = () => {
  const { points } = useStyles()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const user = useCurrentUser()
  const { productId, categoryId } = useParams<{ productId: string; categoryId: string }>()
  const { isFetching: isLoading, data } = useProduct(parseInt(productId, 10))
  const catalogProduct = {
    ...data?.data,
    product: {
      ...data?.data?.product,
      media: data?.data?.product?.media
        .sort((a: ProductMedia) => (a.type === VisualTypes.IMAGE ? -1 : 1))
        .sort((a: ProductMedia) => (a.main ? -1 : 1)),
    },
    catalog_variants: data?.data?.catalog_variants?.sort((a, b) => a.price - b.price),
  }
  const isMobile = useIsMobile()
  const { data: breadcrumbsCategories } = useBreadcrumbsCategories()
  const currentCategoriesPath = getCurrentCategoriesPath(
    Number(categoryId) || catalogProduct?.product?.categories?.find((cat) => cat.default)?.id,
    breadcrumbsCategories?.data
  )
  const { data: wishlistItems, isFetching } = useSelectWishlist()
  const { handleAddItemToWishlist, isLoading: isAddingWishlistItem } = useAddItemToWishlist()
  const { mutate: onRemoveWishlistItem, isLoading: isRemovingWishlistItem } = useRemoveWishlistItem()

  const itemInWishlist = wishlistItems?.data?.data?.find((i) => i.product.id === catalogProduct?.product?.id)
  const pointsName = usePointsName(catalogProduct?.price_points)
  const { isSingleLogic, isLuckyBird } = useIsLuckyBirdUser()
  const handleWishlistButton = () => {
    if (itemInWishlist) {
      onRemoveWishlistItem(itemInWishlist.id)
    } else {
      handleAddItemToWishlist(catalogProduct?.product?.id)
    }
  }

  const haveProductVariants = !isEmpty(catalogProduct?.catalog_variants)
  // const haveMainProduct = !isEmpty(catalogProduct?.product?.main_product)

  const minPrice = haveProductVariants ? catalogProduct.catalog_variants[0]?.price : 0
  const maxPrice = haveProductVariants
    ? catalogProduct.catalog_variants[catalogProduct.catalog_variants.length - 1]?.price
    : 0

  const ProductOutOfStock = () => (
    <Chip
      sx={{
        maxWidth: '100vw',
        minHeight: (theme) => theme.spacing(4),
        padding: (theme) => theme.spacing(1),
        mb: 1,
        '& .MuiChip-label': {
          textOverflow: 'initial',
          overflow: 'visible',
          whiteSpace: 'normal',
        },
      }}
      color="primary"
      size="small"
      label={t('product.is_out_of_stock')}
      icon={<DoDisturbAltIcon />}
    />
  )

  const renderProductPoints = () => (
    <Box className={points}>
      {haveProductVariants ? (
        minPrice === maxPrice ? (
          <>{displayPoints(minPrice)} </>
        ) : (
          t('product.variants.points', {
            from: displayPoints(minPrice),
            to: displayPoints(maxPrice),
          })
        )
      ) : (
        displayPoints(catalogProduct?.price_points)
      )}{' '}
      {pointsName}
    </Box>
  )

  const renderProductBrand = () => (
    <Box
      sx={(theme) => ({
        fontSize: getResponsiveSize(2.7, 'rem'),
        fontWeight: 900,
        lineHeight: 1,
        textTransform: 'uppercase',
        overflowWrap: 'break-word',
        [theme.breakpoints.down('lg')]: {
          fontSize: getResponsiveSize(2.25, 'rem'),
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: getResponsiveSize(1.75, 'rem'),
        },
      })}
    >
      {catalogProduct?.product?.brand?.name}
    </Box>
  )

  return (
    <WhitePaper
      header={
        <Box display="grid" gridTemplateColumns="1fr auto" alignItems="center">
          {isMobile ? (
            <>
              <MobileBreadcrumbs segments={getBreadcrumbsSegments(currentCategoriesPath, t, i18n.language)} />
              <PointsWrapper item>
                {displayPoints(user?.available_points)} {pointsName}
              </PointsWrapper>
            </>
          ) : (
            <>
              <Breadcrumbs segments={getBreadcrumbsSegments(currentCategoriesPath, t, i18n.language)} />
              <GoBackButton noPx onClick={() => navigate('/catalog')} />
            </>
          )}
        </Box>
      }
    >
      <Loader isLoading={isLoading}>
        <Grid container spacing={3} px={valueOrUndefined(!isMobile, 5)} pt={isMobile ? 3 : 5}>
          <Grid item xs={12}>
            {isProductOutOfStock(catalogProduct) && <ProductOutOfStock />}
            <Grid container spacing={isMobile ? 2 : 3} direction={isMobile ? 'column-reverse' : undefined}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                  {!isMobile && (
                    <Grid item xs={12}>
                      {renderProductBrand()}
                      <ProductName product={catalogProduct?.product} />
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        mt={2}
                        sx={{ color: (theme) => theme.palette.grey['500'] }}
                      >
                        {catalogProduct?.product?.code}
                      </Typography>
                      {ENVIRONMENT === 'local' && productTypes?.[catalogProduct?.product?.type]}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      fontFamily={fontFamilies.default}
                      variant="body2"
                      fontSize={(t) => t.typography.h2.fontSize}
                      dangerouslySetInnerHTML={safeHtml(
                        getObjectTranslation(catalogProduct?.product?.translation)?.description ||
                          catalogProduct?.product?.navision_description
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontFamily={fontFamilies.default}
                      variant="body2"
                      fontSize={(t) => t.typography.h2.fontSize}
                      dangerouslySetInnerHTML={safeHtml(
                        getObjectTranslation(catalogProduct?.product?.translation)?.extra
                      )}
                    />
                  </Grid>
                  {!haveProductVariants ? (
                    <Grid item xs={12}>
                      <Box display="grid" gridAutoColumns={isMobile ? '1fr' : 'max-content'}>
                        <AddItemToCart />
                        {!isLuckyBird && (
                          <ButtonWithIcon
                            Icon={<Heart active={!!itemInWishlist} />}
                            variant="outlined"
                            translation={itemInWishlist ? 'product.remove_from_wishlist' : 'product.save_for_later'}
                            onClick={handleWishlistButton}
                            sx={{ mt: 2 }}
                            isLoading={isRemovingWishlistItem || isAddingWishlistItem || isFetching}
                            dataCy={dataCy('button-wishlist-add')}
                          />
                        )}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <ProductVariants variants={catalogProduct?.catalog_variants} />
                    </Grid>
                  )}
                </Grid>
                {/*  maybe useful in the future */}
                {/* {haveMainProduct && (
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <MainProduct product={catalogProduct?.product?.main_product} />
                    </Grid>
                  )} */}
              </Grid>
              <Grid item xs={12} md={6} sx={{ maxWidth: isMobile ? '100%!important' : undefined }}>
                <Grid container spacing={2}>
                  {isMobile ? (
                    <Grid item container spacing={0} xs={12}>
                      <Grid item xs={12}>
                        {renderProductBrand()}
                      </Grid>
                      <Grid item xs={12}>
                        <ProductName product={catalogProduct?.product} />
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          mt={2}
                          sx={{ color: (theme) => theme.palette.grey['500'] }}
                        >
                          {catalogProduct?.product?.code}
                        </Typography>
                      </Grid>
                      {!isSingleLogic && <Grid item>{renderProductPoints()}</Grid>}
                    </Grid>
                  ) : isSingleLogic ? null : (
                    <Grid item xs={12}>
                      {renderProductPoints()}
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    {catalogProduct?.product && <ProductMediaContainer catalogProduct={catalogProduct} />}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {catalogProduct?.product && <Panels catalogProduct={catalogProduct} />}
          </Grid>
        </Grid>
      </Loader>
    </WhitePaper>
  )
}
/* eslint-enable react/no-danger */
