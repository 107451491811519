import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useMutation } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import Loader from 'components/Loader/Loader'
import { Form, Formik, FormikConfig } from 'formik'
import { ConsentField } from 'pages/Login/ActivateAccount/ConsentField'
import { InitialValues } from 'pages/Login/LuckyBird/types'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import { useLuckyBirdCodeLoginMutation } from 'pages/Login/LuckyBird/useLuckyBirdCodeLoginMutation'
import { field, redirectLuckyBird } from 'pages/Login/LuckyBird/utils'
import { redirectOnLogin } from 'pages/Login/redirectOnLogin'
import { useAuthContext } from 'store/useAuthContext'
import { postRequest } from 'utils/api/apiRequest'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'
import * as Yup from 'yup'

export type LuckyBirdUserActivatePayload = {
  email: string
  first_name: string
  last_name: string
  consent: boolean
  code: string
  subdomain?: string
}

const luckyBirdUserActivate = (data: LuckyBirdUserActivatePayload) => postRequest(`/lucky-bird/activate`, data)

type Props = {
  onBack: () => void
  initialValues: InitialValues
}

export const Step2 = ({ onBack, initialValues }: Props) => {
  const { refetch: refreshUser } = useAuthContext()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const enqueueErrorSnackbar = useErrorSnackbar()
  const isGlobalLB = useIsLuckyBirdGlobalAuth()

  const { mutateAsync: login } = useLuckyBirdCodeLoginMutation({})

  const { mutateAsync: activateCode, isLoading: isActivatingUser } = useMutation(luckyBirdUserActivate, {
    onSuccess: async () => {
      if (isGlobalLB && initialValues.subdomain) {
        setIsRedirecting(true)
        return redirectLuckyBird(initialValues.subdomain, initialValues.code)
      }
      try {
        await login({ code: initialValues.code })
        await refreshUser()
        redirectOnLogin(navigate, false, location?.search)
      } catch (e) {
        enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('auth.login.error') })
      }
    },
    onError: async (e) => {
      if (isAxiosError(e) && e?.response?.status !== 400) {
        enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('auth.login.error') })
      }
    },
  })
  const onSubmit: FormikConfig<InitialValues>['onSubmit'] = ({ subdomain, ...values }) => activateCode(values)

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().label(t('auth.activate_account.field.first_name')).required().max(19),
    last_name: Yup.string().label(t('auth.activate_account.field.last_name')).required().max(30),
    email: Yup.string().label(t('auth.activate_account.field.email')).required().email(),
    consent: Yup.bool().oneOf([true], t('auth.activate_account.field.consent')),
  })

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
      <Loader isLoading={isRedirecting} visibleChildren fullHeight>
        <Form style={{ height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <FormikTextField
                    autoFocus
                    name={field('first_name')}
                    inputLabel="auth.activate_account.field.first_name"
                    variant="outlined"
                    size="small"
                    center
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormikTextField
                    name={field('last_name')}
                    inputLabel="auth.activate_account.field.last_name"
                    variant="outlined"
                    size="small"
                    center
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormikTextField
                    name={field('email')}
                    inputLabel="auth.activate_account.field.email"
                    variant="outlined"
                    size="small"
                    center
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormikTextField
                    name={field('code')}
                    inputLabel="auth.login.code"
                    variant="outlined"
                    size="small"
                    center
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ConsentField />
            </Grid>
            <Grid item xs={12}>
              <FormikButtons onBack={onBack} isLoading={isActivatingUser} />
            </Grid>
          </Grid>
        </Form>
      </Loader>
    </Formik>
  )
}
