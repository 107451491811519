import React from 'react'
import { FormikAccordionCheckboxes } from 'components/Formik/FormikAccordionCheckboxes'
import { field } from 'pages/RecognizeSomeone/configForm'
import { useRecognitionValues } from 'utils/hooks/api/Values/useRecognitionValues'

export const RecognizeSomeoneValues = () => {
  const name = field('values')
  const { data, isLoading } = useRecognitionValues()

  return <FormikAccordionCheckboxes options={data?.data.data} name={name} isLoading={isLoading} />
}
