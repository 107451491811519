import React from 'react'
import { useTranslation } from 'react-i18next'
import { DrawerSelect } from 'components/DrawerSelect'
import { getResponsiveSize } from 'theme/styles.utils'

import { RecognizeSomeoneForm } from './RecognizeSomeoneForm'

export const RecognizeSomeoneDrawerButton = () => {
  const { t } = useTranslation()

  return (
    <DrawerSelect
      label={t('recognize_someone.recognize_now')}
      keepMounted={false}
      disableCustomStyle
      buttonProps={{
        variant: 'contained',
        ['data-cy']: 'recognize-someone-button',
        size: 'small',
        sx: {
          textTransform: 'uppercase',
          px: getResponsiveSize(4, 'rem'),
        },
      }}
      buttonWrapperProps={() => ({ sx: { textAlign: 'center' } })}
    >
      <RecognizeSomeoneForm />
    </DrawerSelect>
  )
}
