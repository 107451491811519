import React, { useContext, useState } from 'react'
import Search from '@mui/icons-material/Search'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { Close } from 'components/ArteelIcons'
import { BirthdayFiltersMobile } from 'pages/Birthdays/components/BirthdayFiltersMobile'
import { MobileMenuButton } from 'pages/CMS/components/MobileMenuButton'
import { RecognizeSomeoneDrawerButton } from 'pages/RecognizeSomeone'
import { PostsFiltersMobile } from 'pages/SocialWallPostFilters/PostsFiltersMobile'
import SearchContext from 'store/SearchContext'
import { FilterAndSearchMobile } from 'theme/Mobile/FilterAndSearch/FilterAndSearchMobile'
import { ProductFilterSortMobile } from 'theme/Mobile/FilterAndSearch/ProductFilterSortMobile'
import { TopBarGridMobile } from 'theme/Mobile/Menu/TopBarGridMobile'
import { WelcomeText } from 'theme/Mobile/Menu/WelcomeText'
import { getResponsiveSize } from 'theme/styles.utils'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { useSections } from 'utils/hooks/useSections'
import { useSocialWall } from 'utils/hooks/useSocialWall'
import { FCWithChildren } from 'utils/types/FCWithChildren'

import { LogicBoxContent } from './ContentBox/LogicBoxContent'

export const CMSTopbarMobile: FCWithChildren = () => {
  const { isRoute: showBirthdayFilters } = useIsRoute('/birthdays')
  const { isRoute: showPostsFilters } = useIsRoute('/social-wall')
  const { isRoute: showProductFilters } = useIsRoute('/catalog/categories/:id')
  const { getLogicBoxContents, theme } = useSections()
  const [open, setOpen] = useState(false)
  const logicBoxContents = getLogicBoxContents()
  const { isSocialWallActive } = useSocialWall()
  const { onToggleSearch } = useContext(SearchContext)

  const onClick = () => {
    if (!theme) {
      console.warn('Missing CMS theme')
      return
    }
    if (!logicBoxContents.length) {
      console.log('Logic box is not available')
      return
    }
    setOpen((s) => !s)
  }

  return (
    <>
      <TopBarGridMobile
        LeftComponent={<MobileMenuButton />}
        MidComponent={isSocialWallActive ? <RecognizeSomeoneDrawerButton /> : <WelcomeText onClick={onClick} />}
        RightComponent={
          <>
            {showBirthdayFilters && (
              <FilterAndSearchMobile
                filters={<BirthdayFiltersMobile />}
                search={
                  <IconButton onClick={onToggleSearch}>
                    <Search color="primary" fontSize="large" />
                  </IconButton>
                }
              />
            )}
            {showPostsFilters && <FilterAndSearchMobile filters={<PostsFiltersMobile />} />}
            {showProductFilters && (
              <FilterAndSearchMobile
                filters={<ProductFilterSortMobile />}
                search={
                  <IconButton size="small" onClick={onToggleSearch}>
                    <Search color="primary" fontSize="large" />
                  </IconButton>
                }
              />
            )}
          </>
        }
      />
      <Dialog open={open} fullScreen onClose={() => setOpen(false)}>
        <DialogTitle sx={{ height: getResponsiveSize(64), textAlign: 'right' }}>
          <IconButton size="small" onClick={onClick}>
            <Close color="primary" fontSize="medium" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <LogicBoxContent contents={logicBoxContents} />
        </DialogContent>
      </Dialog>
    </>
  )
}
