import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom'
import Box from '@mui/material/Box'
import { CountryContextDialogWrapper } from 'components/CountryContextDialog/CountryContextDialogWrapper'
import NoMatch from 'components/NoMatch'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { MAIN_DOMAIN, PROTOCOL } from 'config/env'
import { Birthdays } from 'pages/Birthdays/Birthdays'
import { BrandProducts } from 'pages/BrandProducts/BrandProducts'
import { Brands } from 'pages/Brands/Brands'
import { CMS } from 'pages/CMS'
import { CMSTopbarMobile } from 'pages/CMS/components/CMSTopbarMobile'
import { HelpWrapper } from 'pages/CMS/components/ContentBox/ServiceRequest/HelpWrapper'
import { ServiceRequest } from 'pages/CMS/components/ContentBox/ServiceRequest/ServiceRequest'
import { Contact } from 'pages/Contact/Contact'
import { CreateOrder } from 'pages/CreateOrder/CreateOrder'
import { CreateOrderConfirmation } from 'pages/CreateOrderConfirmation/CreateOrderConfirmation'
import { HomeRedemptionSite } from 'pages/HomeRedemptionSite/HomeRedemptionSite'
import { Logout } from 'pages/Login/Logout'
import { LuckyBirdCodeLogin } from 'pages/Login/LuckyBird/LuckyBirdCodeLogin'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import { PrivacyPolicy } from 'pages/Login/PrivacyPolicy'
import { LuckyBirdWelcomePage } from 'pages/LuckyBirdWelcomePage/LuckyBirdWelcomePage'
import { MainMenu } from 'pages/MainMenu/MainMenu'
import { MyAccountUserConsent } from 'pages/MyAccount/components/MyAccountUserConsent'
import { MyAccountPointsHistory } from 'pages/MyAccount/components/PointsHistory/MyAccountPointsHistory'
import { MyAccountColumn } from 'pages/MyAccount/MyAccountColumn'
import { MyAccountEdit } from 'pages/MyAccountEdit/MyAccountEdit'
import { OrderHistory } from 'pages/OrderHistory/OrderHistory'
import { EditPersonalMessage } from 'pages/PersonalMessages/Edit/EditPersonalMessage'
import { PersonalMessages } from 'pages/PersonalMessages/PersonalMessages'
import { PersonalMessageTemplates } from 'pages/PersonalMessages/Templates/PersonalMessageTemplates'
import { ViewPersonalMessage } from 'pages/PersonalMessages/View/ViewPersonalMessage'
import { ProductCard } from 'pages/ProductCard/ProductCard'
import { ShoppingCart } from 'pages/ShoppingCart/ShoppingCart'
import { SocialWall } from 'pages/SocialWall/SocialWall'
import { TopRatedProducts } from 'pages/TopRatedProducts/TopRatedProducts'
import { TransferPoints } from 'pages/TransferPoints/TransferPoints'
import { UserConsentProvider } from 'pages/UserConsent'
import { Values } from 'pages/Values/Values'
import { VoucherOrders } from 'pages/VoucherOrders/VoucherOrders'
import { Wishlist } from 'pages/Wishlist/Wishlist'
import { ProtectedLuckyBird } from 'routes/ProtectedLuckyBird'
import { ProtectedRedirect } from 'routes/ProtectedRedirect'
import { RoutingType } from 'routes/types'
import { useDispatchShoppingCart } from 'shared/ShoppingCart/slice'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { CountryProvider } from 'store/CountryContext'
import { CategoriesDrawerProvider } from 'theme/Mobile/CategoriesDrawer/CategoriesDrawer'
import { ProjectTopbarMobile } from 'theme/Mobile/Menu/ProjectTopbarMobile'
import Template from 'theme/Template'
import { filterRoutes } from 'utils/filterRoutes'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser, useIsStandardUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { useLBCodeStatus } from 'utils/hooks/useLuckyBirdCodeStatus'
import { FCWithChildren } from 'utils/types/FCWithChildren'

import { FEATURES } from './Features'
import { ProtectedRoute } from './ProtectedRoute'

export const routing: RoutingType[] = [
  {
    path: '/',
    Component: () => <CMS socialWallSwitcher />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/',
    Component: HomeRedemptionSite,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/catalog/categories/:categoryId/products/:productId',
    Component: ProductCard,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/catalog/products/:productId',
    Component: ProductCard,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/catalog',
    Component: HomeRedemptionSite,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/catalog/categories/:categoryId',
    Component: HomeRedemptionSite,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/catalog/top-rated-products',
    Component: TopRatedProducts,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/catalog/brands',
    Component: Brands,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/catalog/brands/:brandId',
    Component: BrandProducts,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/my-account',
    Component: () => <CMS middleColumnContent={<MyAccountColumn />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/history-points',
    Component: () => <CMS middleColumnContent={<MyAccountPointsHistory />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/history-points/expires',
    Component: () => <CMS middleColumnContent={<MyAccountPointsHistory />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/order-history',
    Component: () => <CMS middleColumnContent={<OrderHistory />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/order-history/:id',
    Component: () => <CMS middleColumnContent={<OrderHistory />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/user-consent',
    Component: () => <CMS middleColumnContent={<MyAccountUserConsent />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/voucher-orders',
    Component: () => <CMS middleColumnContent={<VoucherOrders />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/voucher-orders/:id',
    Component: () => <CMS middleColumnContent={<VoucherOrders />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/my-account/edit',
    Component: () => <CMS middleColumnContent={<MyAccountEdit />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/service-request',

    Component: () => (
      <CMS
        middleColumnContent={
          <ResponsivePaper>
            <ServiceRequest />
          </ResponsivePaper>
        }
      />
    ),
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/social-wall',
    Component: () => <CMS middleColumnContent={<SocialWall />} socialWallColumns socialWallSwitcher />,
    permissions: [FEATURES.SOCIAL_WALL],
    siteType: SiteType.cms,
  },
  {
    path: '/service-request',
    Component: HelpWrapper,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/shopping-cart',
    Component: ShoppingCart,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/create-order',
    Component: CreateOrder,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/create-order/success/:id',
    Component: CreateOrderConfirmation,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/create-order/success/:id',
    Component: CreateOrderConfirmation,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/wishlist',
    Component: Wishlist,
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/menu',
    Component: MainMenu,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/contact',
    Component: () => (
      <CMS
        middleColumnContent={
          <ResponsivePaper>
            <Contact />
          </ResponsivePaper>
        }
      />
    ),
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/contact',

    Component: () => (
      <Box display="flex" justifyContent="center" pt={5}>
        <Contact />
      </Box>
    ),
    permissions: [],
    siteType: SiteType.project,
  },
  {
    path: '/birthdays',
    Component: Birthdays,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/values',
    Component: () => <CMS middleColumnContent={<Values />} socialWallColumns />,
    permissions: [FEATURES.SOCIAL_WALL],
    siteType: SiteType.cms,
  },

  {
    path: '/personal-messages',
    Component: () => <CMS middleColumnContent={<PersonalMessages />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/personal-messages/edit/:id',
    Component: () => <CMS middleColumnContent={<EditPersonalMessage />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/personal-messages/view/:id',
    Component: () => <CMS middleColumnContent={<ViewPersonalMessage />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/personal-messages/templates',
    Component: () => <CMS middleColumnContent={<PersonalMessageTemplates />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/transfer-points',
    Component: () => <CMS middleColumnContent={<TransferPoints />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/privacy-and-terms',
    Component: () => <CMS middleColumnContent={<PrivacyPolicy />} />,
    permissions: [],
    siteType: SiteType.cms,
  },
  {
    path: '/privacy-and-terms',
    Component: () => <PrivacyPolicy />,
    permissions: [],
    siteType: SiteType.project,
  },

  /**
      Lucky bird
   **/
  {
    path: '/',
    Component: LuckyBirdCodeLogin,
    permissions: [],
    siteType: SiteType.luckyBirdGlobal,
  },
  {
    path: '/',
    Component: LuckyBirdWelcomePage,
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/luckybird',
    Component: LuckyBirdWelcomePage,
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/catalog/categories/:categoryId/products/:productId',
    Component: () => (
      <CountryContextDialogWrapper>
        <ProductCard />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/catalog/products/:productId',
    Component: () => (
      <CountryContextDialogWrapper>
        <ProductCard />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/catalog',
    Component: () => (
      <CountryContextDialogWrapper>
        <HomeRedemptionSite />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/catalog/categories/:categoryId',
    Component: () => (
      <CountryContextDialogWrapper>
        <HomeRedemptionSite />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/shopping-cart',
    Component: () => (
      <CountryContextDialogWrapper>
        <ShoppingCart />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/create-order',
    Component: () => (
      <CountryContextDialogWrapper>
        <CreateOrder />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/create-order/success/:id',
    Component: () => (
      <CountryContextDialogWrapper>
        <CreateOrderConfirmation />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/catalog/brands',
    Component: () => (
      <CountryContextDialogWrapper>
        <Brands />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/catalog/brands/:brandId',
    Component: () => (
      <CountryContextDialogWrapper>
        <BrandProducts />
      </CountryContextDialogWrapper>
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/contact',
    Component: () => (
      <LuckyBirdWelcomePage
        middleColumnContent={
          <ResponsivePaper>
            <Contact />
          </ResponsivePaper>
        }
      />
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/service-request',
    Component: () => (
      <LuckyBirdWelcomePage
        middleColumnContent={
          <ResponsivePaper>
            <HelpWrapper />
          </ResponsivePaper>
        }
      />
    ),
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/privacy-and-terms',
    Component: () => <LuckyBirdWelcomePage middleColumnContent={<PrivacyPolicy />} />,
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/menu',
    Component: MainMenu,
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/my-account/voucher-orders',
    Component: () => <LuckyBirdWelcomePage middleColumnContent={<VoucherOrders />} />,
    permissions: [],
    siteType: SiteType.luckyBird,
  },
  {
    path: '/my-account/order-history',
    Component: () => <LuckyBirdWelcomePage middleColumnContent={<OrderHistory />} />,
    permissions: [],
    siteType: SiteType.luckyBird,
  },
]

const renderSwitch = (siteType: SiteType) => {
  const statusLB = useLBCodeStatus()

  return (
    <Routes>
      {routing
        .filter((route) => route.siteType === siteType)
        .filter(
          filterRoutes({
            paths: ['/shopping-cart', '/catalog*'],
            siteType: [SiteType.luckyBird],
            show: !statusLB('fullyRedeemed'),
          })
        )
        .map((route) => (
          <Route key={route.path} path={route.path} element={<ProtectedRoute {...route} />} />
        ))}
      <Route path="/auth/logout" Component={Logout} />
      <Route Component={NoMatch} />
    </Routes>
  )
}

export interface ProtectedSwitchProps {
  siteType: SiteType
  isLoading: boolean
}

// eslint-disable-next-line complexity
export const ProtectedSwitch: FCWithChildren<ProtectedSwitchProps> = ({ siteType, isLoading }) => {
  const user = useCurrentUser()
  const isSignedIn = Boolean(user)
  const { isLuckyBird: isLuckyBirdUser } = useIsLuckyBirdUser()
  const isStandardUser = useIsStandardUser()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isLuckyBird = useMatch('/luckybird')
  const { isRoute: isLBProductsPage } = useIsRoute([
    '/catalog',
    '/catalog/categories/:id',
    '/shopping-cart',
    '/create-order',
  ])
  const location = useLocation()
  const { refetchShoppingCart } = useDispatchShoppingCart()
  const { data } = useSubdomainInfo()
  const isGlobalLB = useIsLuckyBirdGlobalAuth()

  useEffect(() => {
    if (!isSignedIn) return
    if (isLuckyBirdUser) {
      refetchShoppingCart()
    }
  }, [isSignedIn, siteType, isLuckyBirdUser])

  if (isLoading) return null

  if (!isSignedIn) {
    return isLuckyBird ? (
      <Navigate to={`/auth/luckybird?redirect=${location.pathname}&${location.search}`} />
    ) : (
      <ProtectedRedirect to="/" />
    )
  }
  if (siteType === SiteType.cms && isLuckyBirdUser) {
    return (
      <UserConsentProvider>
        <Template
          topBarGutter={(!isMobile && !isTablet) || isLBProductsPage}
          TopbarMobile={<CMSTopbarMobile />}
          hasLuckyBirdImageBg
        >
          <CategoriesDrawerProvider>
            <ProtectedLuckyBird>
              <CountryProvider>{renderSwitch(SiteType.luckyBird)}</CountryProvider>
            </ProtectedLuckyBird>
          </CategoriesDrawerProvider>
        </Template>
      </UserConsentProvider>
    )
  }

  if (siteType === SiteType.cms && isStandardUser) {
    return (
      <UserConsentProvider>
        <Template topBarGutter={!isMobile && !isTablet} TopbarMobile={<CMSTopbarMobile />}>
          {renderSwitch(SiteType.cms)}
        </Template>
      </UserConsentProvider>
    )
  }
  if (siteType === SiteType.project && isStandardUser) {
    return (
      <UserConsentProvider>
        <Template topBarGutter TopbarMobile={<ProjectTopbarMobile />}>
          <CategoriesDrawerProvider>
            <CountryProvider>
              <CountryContextDialogWrapper>
                <>{renderSwitch(SiteType.project)}</>
              </CountryContextDialogWrapper>
            </CountryProvider>
          </CategoriesDrawerProvider>
        </Template>
      </UserConsentProvider>
    )
  }

  if (isSignedIn && isLuckyBirdUser && isGlobalLB) {
    window.location.assign(`${PROTOCOL}://${user?.lucky_bird_domain}.${MAIN_DOMAIN}/luckybird`)
    return <div>Redirecting...</div>
  }

  return <NoMatch withoutButton />
}
