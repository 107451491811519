import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { Form, Formik, FormikHelpers } from 'formik'
import { ServiceRequestFormValues } from 'utils/api/ServiceRequest/postServiceRequest'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useSendServiceRequest } from 'utils/hooks/useSendServiceRequest'
import { createStyles, makeStyles } from 'utils/styles/makeStyles'
import * as Yup from 'yup'

import { ServiceRequestForm } from './ServiceRequestForm'

const useStyles = makeStyles((theme) =>
  createStyles({
    subHeader: {
      fontSize: theme.typography.h3.fontSize,
      color: theme.palette.grey[800],
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
    },
  })
)

export const ServiceRequest = () => {
  const { subHeader } = useStyles()
  const { t, i18n } = useTranslation()
  const user = useCurrentUser()

  const { mutate: handleSendServiceRequest, isLoading } = useSendServiceRequest()

  const initialValues: ServiceRequestFormValues = {
    first_name: user?.profile?.first_name || '',
    last_name: user?.profile?.last_name || '',
    email: user?.email || '',
    phone_number: user?.profile?.phone || '',
    subject: '',
    project_name: '',
    product: '',
    order_reference_id: '',
    message: '',
    language: i18n.language,
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().label(t('service_request.first_name')).required().max(19),
    last_name: Yup.string().label(t('service_request.last_name')).required().max(30),
    email: Yup.string().label(t('service_request.email')).required().email(),
    phone_number: Yup.string().label(t('service_request.phone_number')).required(),
    subject: Yup.string().label(t('service_request.subject')).required(),
    project_name: Yup.string().label(t('service_request.project_name')).notRequired(),
    product: Yup.string().label(t('service_request.product')).notRequired().nullable(),
    order_reference_id: Yup.string().label(t('service_request.order_reference_id')).notRequired().nullable(),
    message: Yup.string().label(t('service_request.message')).required(),
  })

  const onSubmit = (values: ServiceRequestFormValues, { resetForm }: FormikHelpers<ServiceRequestFormValues>) => {
    const {
      file_id_1,
      file_id_2,
      first_name,
      last_name,
      email,
      message,
      subject,
      product,
      project_name,
      phone_number,
      order_reference_id,
      language,
    } = values
    handleSendServiceRequest(
      {
        first_name,
        last_name,
        email,
        message,
        subject,
        project_name,
        phone_number,
        file_id_1: file_id_1?.id,
        file_id_2: file_id_2?.id,
        product,
        order_reference_id,
        language,
      },
      {
        onSuccess: () => {
          resetForm()
        },
      }
    )
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <HeaderPage text={t('service_request.header')} sx={(t) => ({ color: t.palette.primary.main })} />
        <Typography pb={3} className={subHeader}>
          {t('service_request.subheader')}
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <ServiceRequestForm isLoading={isLoading} />
          </Form>
        </Formik>
      </Grid>
    </Grid>
  )
}
