import React from 'react'
import Box from '@mui/material/Box'
import { getResponsiveSize } from 'theme/styles.utils'
import { Product } from 'utils/api/Products/CatalogProduct'
import { getProductName } from 'utils/getProductName'

export const ProductName = ({ product }: { product?: Product }) => {
  return (
    <Box
      sx={(theme) => ({
        fontSize: getResponsiveSize(2.7, 'rem'),
        fontWeight: 900,
        lineHeight: 1,
        textTransform: 'uppercase',
        overflowWrap: 'break-word',
        [theme.breakpoints.down('lg')]: {
          fontSize: getResponsiveSize(2.25, 'rem'),
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: getResponsiveSize(1.75, 'rem'),
        },
      })}
    >
      {getProductName(product)}
    </Box>
  )
}
