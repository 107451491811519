import { ButtonProps } from '@mui/material'
import Button from '@mui/material/Button'
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'
import clsx from 'clsx'
import { ArrowDown } from 'components/ArteelIcons'
import { Theme } from 'theme/styles'
import { getResponsiveSize } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { makeStyles } from 'utils/styles/makeStyles'
import { FCWithChildren } from 'utils/types/FCWithChildren'
import { Modify } from 'utils/types/Modify'

const useStyles = makeStyles((theme) => ({
  arrow: {
    transition: '0.3s all',
    [theme.breakpoints.down('md')]: {
      fontSize: `${getResponsiveSize(0.75)}rem !important`,
    },
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  icon: {
    '& > *:first-child': {
      fontSize: `${getResponsiveSize(0.8)}rem`,
    },
  },
  csOpen: {
    position: 'relative',
    '&:after': {
      content: '" "',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      marginBottom: theme.spacing(0.5),
    },
  },
}))

export type SelectButtonProps = Modify<ButtonProps, { sx?: SystemStyleObject<Theme> }> & {
  open?: boolean
  disableCustomStyle?: boolean
}
export const SelectButton: FCWithChildren<SelectButtonProps> = ({
  children,
  open = false,
  disableCustomStyle,
  sx = {},
  ...props
}) => {
  const { arrow, icon, rotated, csOpen } = useStyles()
  return (
    <Button
      className={clsx(open && !disableCustomStyle ? csOpen : undefined)}
      color="primary"
      sx={{
        px: 1,
        minWidth: 'auto',
        ...sx,
      }}
      {...props}
      endIcon={valueOrUndefined(
        !disableCustomStyle,
        <ArrowDown fontSize="small" className={clsx(arrow, open && rotated)} />
      )}
      classes={{ iconSizeMedium: icon }}
    >
      {children}
    </Button>
  )
}
