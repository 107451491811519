import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { TextMaskInput } from 'components/TextMaskInput/TextMaskInput'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { parseError } from 'utils/api/parseError'
import userQueryKeys from 'utils/api/User/userQueryKeys'
import { activateValueCode } from 'utils/api/ValueCodes/ValueCodes'
import * as Yup from 'yup'

export const useActivateValueCode = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: activateValueCode,
    mutationKey: [`activateValueCode`],
    onSuccess: () => {
      enqueueSnackbar(t('value_code.activate.success'), { variant: 'success' })
      queryClient.invalidateQueries({ queryKey: userQueryKeys.me() })
    },
    onError: (e) => {
      const errors = parseError(e)
      if (errors.mappedErrors.length) {
        errors.mappedErrors.forEach(([_, err]) => enqueueSnackbar(err, { variant: 'error' }))
        return
      }
      enqueueSnackbar(t('value_code.activate.fail'), { variant: 'error' })
    },
  })
}

export const ValueCodesWidget = () => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useActivateValueCode()
  const validationSchema = Yup.object({
    code: Yup.string().min(12).nullable(),
  })

  return (
    <>
      <Typography align="center" variant="h4" fontWeight="bold" sx={{ color: (t) => t.palette.grey['800'] }} py={1}>
        {t('value_code.activate')}
      </Typography>
      <Formik
        initialValues={{ code: '' }}
        onSubmit={(v, h) => {
          mutate(v.code, { onSuccess: h.resetForm })
        }}
        validationSchema={validationSchema}
      >
        <Form data-cy="value-code-widget-form">
          <FormikTextField
            center
            name="code"
            inputLabel="auth.activate_account.field.code"
            placeholder="XXXX-XXXX-XX"
            size="small"
            sx={{ mb: 1 }}
            InputProps={{
              inputComponent: TextMaskInput as any,
            }}
          />
          <FormikButtons textButtonSubmit="auth.activate" size="small" isLoading={isLoading} />
        </Form>
      </Formik>
    </>
  )
}
