import { fontFamilies } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    height: theme.spacing(8),
    justifyContent: 'center',
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  csSubscriberName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h1.fontSize,
    color: theme.palette.grey[800],
    fontFamily: fontFamilies.logo,
  },
  bottomMenu: {
    bottom: 0,
    top: 'auto',
    borderTop: `2px solid ${theme.palette.divider}`,
  },
  footerSpacer: {
    height: theme.spacing(8),
    width: '100%',
  },
}))
