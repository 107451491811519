import { ReactNode, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { CypressButtonLogout } from 'components/CypressButtons/CypressButton'
import { RecognizeSomeoneDrawerButton } from 'pages/RecognizeSomeone'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { useThemeContext } from 'store/ThemeContext'
import { ScrollUp } from 'theme/ScrollUp/ScrollUp'
import { getResponsiveSize } from 'theme/styles.utils'
import { LogoWeb } from 'theme/Web/LogoWeb'
import { MenuWeb } from 'theme/Web/Menu/Menu'
import { useLBThemes } from 'utils/api/Theme/useThemes'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsCypress } from 'utils/hooks/useIsCypress'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { useResize } from 'utils/hooks/useResize'
import { useSocialWall } from 'utils/hooks/useSocialWall'
import { makeSx } from 'utils/styles/makeSx'

import { useStyles } from './templateStyles'

type Props = {
  children: ReactNode | string
  TopbarMobile: ReactNode
  topBarGutter?: boolean
  hasLuckyBirdImageBg?: boolean
}

export default ({ children, TopbarMobile, topBarGutter, hasLuckyBirdImageBg }: Props) => {
  const { appBar } = useStyles()
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()
  const { data: luckyBirdTheme } = useLBThemes()
  const { isRoute: isRouteWithImgBg } = useIsRoute(['/luckybird', '/'])
  const { isCypress } = useIsCypress()

  const { data: subdomainInfo } = useSubdomainInfo()
  const { isSocialWallActive } = useSocialWall()
  const [ref, setRef] = useState(null)
  const { height } = useResize(ref)
  const { setAppBarHeight } = useThemeContext()
  const user = useCurrentUser()

  useEffect(() => {
    if (!height) return
    setAppBarHeight(height)
  }, [height])

  const defaultTemplateSx = makeSx((_) => ({
    background: _.palette.background.default,
    height: '100%',
    maxWidth: '100%',
    fontFamily: 'ProximaNovaCond',
  }))
  const imageTemplateSx = makeSx((_) => ({
    minHeight: '100%',
    maxWidth: '100%',
    fontFamily: 'ProximaNovaCond',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundColor: _.palette.background.default,
    backgroundImage: `url('${luckyBirdTheme?.theme?.background}')`,
  }))

  return (
    <Box sx={!(hasLuckyBirdImageBg && isRouteWithImgBg) ? defaultTemplateSx : imageTemplateSx}>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12}>
          <AppBar ref={setRef} position="fixed" color="inherit" className={appBar} elevation={isMobile ? 0 : 1}>
            {!isTablet && (
              <Box>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid container item xs={10} spacing={2} alignItems="center">
                    <Grid item lg>
                      <LogoWeb />
                    </Grid>
                    <Grid item>
                      {subdomainInfo?.data?.type === SiteType.cms && isSocialWallActive && (
                        <RecognizeSomeoneDrawerButton />
                      )}
                    </Grid>
                    <Grid item lg>
                      <MenuWeb />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {isTablet && <Box>{TopbarMobile}</Box>}
          </AppBar>
        </Grid>
        <Grid item xs={12} sx={{ pt: valueOrUndefined(topBarGutter, getResponsiveSize(64, 'px')) }}>
          {children}
        </Grid>
      </Grid>
      <ScrollUp />
      {isCypress && <CypressButtonLogout />}
    </Box>
  )
}
