import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ArrowDown } from 'components/ArteelIcons'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { useGetPersonalTemplates } from 'pages/PersonalMessages/api/useGetPersonalTemplates'
import { Occasion, PersonalTemplate } from 'pages/PersonalMessages/api/useGetPersonalTemplatesByOccasionId'
import { useGetSubscriberTemplates } from 'pages/PersonalMessages/api/useGetSubscriberTemplates'
import { AddEditTemplate } from 'pages/PersonalMessages/Templates/AddEditTemplate/AddEditTemplate'
import { OccasionTemplates } from 'pages/PersonalMessages/Templates/OccasionTemplates'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { important } from 'utils/styles'

export const PersonalMessageTemplates = () => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const [formObject, setFormObject] = useState<null | undefined | (PersonalTemplate & { occasionId: Occasion['id'] })>(
    undefined
  )
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data, isLoading } = useGetSubscriberTemplates()
  const { data: personalData, isLoading: isLoadingPersonal } = useGetPersonalTemplates()

  return (
    <ResponsivePaper data-cy="personal-message-templates-desktop">
      <Box position="absolute" right={getResponsiveSize(10, 'px')} top={0}>
        <GoBackButton translation="app.back" noPx onClick={() => navigate('/personal-messages')} />
      </Box>
      <Grid container gap={2} justifyContent="center">
        <Grid item xs={12}>
          <HeaderPage
            data-cy="personal-message-templates-header"
            text={t('personal_messages.templates')}
            color="medium"
            uppercase={false}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                color="primary"
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                endIcon={
                  <ArrowDown
                    sx={{
                      fontSize: important(getResponsiveSize(1, 'rem')),
                      fontWeight: 'bold',
                      transition: '0.3s ease all',
                      transform: formObject === undefined ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                }
                fullWidth
                onClick={() => setFormObject((prev) => (prev !== null ? null : undefined))}
              >
                {t('personal_messages.templates.add')}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ pt: valueOrUndefined(formObject === undefined, `0!important`), transition: '0.3s all ease' }}
            >
              <Collapse in={formObject !== undefined} mountOnEnter unmountOnExit>
                <AddEditTemplate template={formObject} onBack={() => setFormObject(undefined)} />
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" fontFamily={fontFamilies.black} fontWeight={900}>
                {t('personal_messages.templates.personal_templates')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Loader isLoading={isLoadingPersonal} objects={personalData?.data?.data}>
                {personalData?.data?.data?.map((occasion) => (
                  <OccasionTemplates
                    occasion={occasion}
                    key={occasion.id}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setObjectToEdit={setFormObject}
                  />
                ))}
              </Loader>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" fontFamily={fontFamilies.black} fontWeight={900}>
                {t('personal_messages.templates.default_templates')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Loader isLoading={isLoading} objects={data?.data?.data}>
                {data?.data?.data?.map((occasion) => (
                  <OccasionTemplates
                    occasion={occasion}
                    key={occasion.id}
                    expanded={expanded}
                    setExpanded={setExpanded}
                  />
                ))}
              </Loader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ResponsivePaper>
  )
}
