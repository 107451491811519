import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { Close } from 'components/ArteelIcons'
import Loader from 'components/Loader/Loader'
import { useSelectUserConsent } from 'shared/UserConsent/slice'
import { getResponsiveSize } from 'theme/styles.utils'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

import { UserConsentContent, UserConsentError } from '.'
interface UserConsentModalProps {
  open: boolean
  close(): void
}

export const UserConsentModal = ({ close, open }: UserConsentModalProps) => {
  const { isFetching, isError, isSuccess } = useSelectUserConsent()
  const isMobile = useIsMobile()

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth fullScreen={isMobile}>
      <DialogTitle sx={{ height: getResponsiveSize(64), textAlign: 'right' }}>
        <IconButton size="small" onClick={close}>
          <Close color="primary" fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ height: isMobile ? '100%' : '80vh' }}>
        <Loader isLoading={isFetching}>{isError && <UserConsentError />}</Loader>
        {isSuccess && <UserConsentContent />}
      </DialogContent>
    </Dialog>
  )
}
