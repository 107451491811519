import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { TextField } from 'components/TextField/TextField'
import { useGetPersonalMessage } from 'pages/PersonalMessages/api/useGetPersonalMessage'
import { PersonalMessageDetails } from 'pages/PersonalMessages/components/PersonalMessageDetails'
import { getResponsiveSize } from 'theme/styles.utils'
import { removeHTML } from 'utils/removeHTML'

export const ViewPersonalMessage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data, isLoading } = useGetPersonalMessage({ isEdit: false })

  const content = removeHTML(data?.content)

  const onBack = () => navigate('/personal-messages')

  return (
    <ResponsivePaper data-cy="view-personal-message-desktop">
      <Box position="absolute" right={getResponsiveSize(10, 'px')} top={0}>
        <GoBackButton translation="app.back" noPx onClick={onBack} />
      </Box>
      <Grid container justifyContent="center" spacing={6}>
        <Grid item xs={12}>
          <HeaderPage
            data-cy="edit-personal-message-header"
            text={t('personal_messages.view.header')}
            color="medium"
            uppercase={false}
          />
        </Grid>
        <Grid item xs={12} sm={10} lg={10} xl={8}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={4}>
              <PersonalMessageDetails triggerGift={data?.triggered_gift} />
              <Grid item xs={12}>
                {data?.has_content ? (
                  <TextField multiline rows={4} value={content} disabled />
                ) : (
                  <Alert variant="outlined" color="warning">
                    {t('personal_messages.message_no_content')}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button type="button" variant={'contained'} onClick={onBack} data-cy="cancel-button">
                      {t('app.back')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </ResponsivePaper>
  )
}
