import * as React from 'react'
import Box from '@mui/material/Box'
import { FormikAutocompleteUsers } from 'components/Formik/FormikAutocompleteUsers'
import { field, mapId } from 'pages/RecognizeSomeone/configForm'
import { fontFamilies } from 'theme/styles.utils'
import { RecognitionProfile } from 'utils/api/SocialWall/getRecognitionProfiles'
import { getStringOrEmpty } from 'utils/getStringOrEmpty'
import { useGetRecognitionProfiles } from 'utils/hooks/api/SocialWall/useGetRecognitionProfiles'
import { useGetSocialWallUser } from 'utils/hooks/api/SocialWall/useGetSocialWallUser'

export const RecognizeSomeoneSelectUsers = () => {
  const { isLoading: isLoadingRecognitionList, data: dataRecognitionList } = useGetRecognitionProfiles()
  const { isLoading: isLoadingUser, data: dataUser } = useGetSocialWallUser()

  const name = field('users_awarded')

  const getOptionLabel = ({ full_name, department_path, job_title }: RecognitionProfile): string =>
    `${getStringOrEmpty({ value: full_name })}${getStringOrEmpty({
      prefix: ' - ',
      value: job_title,
    })}${getStringOrEmpty({
      prefix: ' - ',
      value: department_path,
    })}`
  const getChipLabel = ({ full_name, department_path, job_title }: RecognitionProfile) => (
    <Box component="span" fontFamily={fontFamilies.default}>
      <Box component="span" fontWeight={600}>
        {full_name}
      </Box>
      {getStringOrEmpty({ prefix: ' - ', value: job_title })}
      {getStringOrEmpty({ prefix: ' - ', value: department_path })}
    </Box>
  )
  const maxOptions = dataUser?.data?.social_wall_user?.available_recognitions || 0

  return (
    <FormikAutocompleteUsers
      name={name}
      options={mapId(dataRecognitionList?.data?.data)}
      maxOptions={maxOptions}
      isLoading={isLoadingRecognitionList || isLoadingUser}
      getOptionLabel={getOptionLabel}
      getChipLabel={getChipLabel}
    />
  )
}
