import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { Breadcrumbs, Segment } from 'components/Breadcrumbs'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { ProductGrid } from 'components/ProductGrid/ProductGrid'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { TFunction } from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { CategoryList } from 'pages/HomeRedemptionSite/CategoryList'
import { useProductsInCategory } from 'utils/hooks/api/useProductsInCategory'
import { useSubCategories } from 'utils/hooks/api/useSubCategories'

const getBreadcrumbsSegments = (t: TFunction): Segment[] => [
  {
    id: 'catalog',
    label: t('catalog'),
    url: '/catalog',
  },
  {
    id: 'topRated',
    label: 'Top rated',
  },
]

export const TopRatedProducts = () => {
  const { t } = useTranslation()
  const { data: categoriesData, isLoading } = useSubCategories(parseInt('1', 10))
  const categories = categoriesData?.data?.subcategories || []
  const productQuery = useProductsInCategory()

  return (
    <WhitePaper
      onFilter={() => {}}
      onSort={() => {}}
      searchParam="searchProduct"
      header={<Breadcrumbs segments={getBreadcrumbsSegments(t)} />}
    >
      <Grid container spacing={2}>
        <ProductGrid headingT="top_rated_products" {...productQuery} />
        {!isLoading && !isEmpty(categories) && (
          <>
            <Grid item xs={12}>
              <HeaderPage text="Categories" size="medium" />
            </Grid>
            <Grid item xs={12}>
              <CategoryList />
            </Grid>
          </>
        )}
      </Grid>
    </WhitePaper>
  )
}
