import React, { ReactNode, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { MessageEditButton } from 'components/Message/components/MessageEditButton'
import { MessageBaseProps } from 'components/Message/types'
import { sxSize } from 'components/Message/utils'
import { borderRadiusInput } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsTouchDevice } from 'utils/hooks/useIsTouchDevice'
import { makeSx } from 'utils/styles/makeSx'

export type MessageWrapperProps = {
  showEditButton?: boolean
  children: ReactNode | ReactNode[]
  onClick?: () => void
} & Pick<
  MessageBaseProps,
  'size' | 'onEditClick' | 'authorSide' | 'fullWidth' | 'isLoading' | 'error' | 'helperText' | 'variant'
>

export const MessageWrapper = ({
  size,
  showEditButton,
  fullWidth,
  children,
  onClick,
  authorSide,
  onEditClick,
  isLoading,
  error,
  helperText,
  variant,
}: MessageWrapperProps) => {
  const isTouchDevice = useIsTouchDevice()
  const [isHovered, setIsHovered] = useState(isTouchDevice)

  const radius =
    authorSide === 'left'
      ? { borderTopLeftRadius: '0!important' }
      : authorSide === 'right'
        ? { borderTopRightRadius: '0!important' }
        : undefined

  const sxBox = makeSx((theme) => ({
    background: theme.palette.background[variant === 'default' ? 'default' : 'paper'],
    position: 'relative',
    px: sxSize(size)(theme).px,
    py: sxSize(size)(theme).py,
    pr: valueOrUndefined(showEditButton, 4.5),
    width: fullWidth ? '100%' : 'fit-content',
    pointerEvents: valueOrUndefined(isLoading, 'none'),
    borderRadius: size === 'small' ? borderRadiusInput.extraSmall : borderRadiusInput.small,
    ...radius,
  }))

  return (
    <>
      <Box
        onClick={() => onClick?.()}
        onMouseOver={() => !isTouchDevice && setIsHovered(true)}
        onMouseLeave={() => !isTouchDevice && setIsHovered(false)}
        sx={sxBox}
        data-cy="message-box"
      >
        {children}
        {showEditButton && isHovered && <MessageEditButton onEditClick={onEditClick} />}
      </Box>
      {error && (
        <Typography ml="14px" mt="4px" color="error" data-cy="message-helper-text">
          {helperText}
        </Typography>
      )}
    </>
  )
}
