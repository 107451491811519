import React from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import clsx from 'clsx'
import { CustomPopover } from 'components/CustomPopover/CustomPopover'
import { getResponsiveSize } from 'theme/styles.utils'
import { important } from 'utils/styles'

import { Option } from './FilterOptions'

interface Props {
  title: string
  options: Option[]
  onSelect: (option: Option) => void
  currentOption: Option
}

export const Filter = ({ title, options, onSelect, currentOption }: Props) => {
  const { t } = useTranslation()

  return (
    <CustomPopover
      button={({ open }) => (
        <Button
          data-cy="filter-button"
          sx={{
            lineHeight: 'inherit',
            padding: 0,
            minWidth: getResponsiveSize(86),
            fontSize: getResponsiveSize(1, 'rem'),
            '& .MuiButton-endIcon': {
              marginLeft: 0,
            },
          }}
          endIcon={
            <KeyboardArrowDown
              className={clsx(open && 'open')}
              sx={() => ({
                fontSize: important(getResponsiveSize(1, 'rem')),
                transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                transform: 'rotate(0deg)',
                '&.open': {
                  transform: 'rotate(180deg)',
                },
              })}
            />
          }
        >
          {title}
        </Button>
      )}
      content={({ closeHandler }) => (
        <MenuList>
          {options.map((option) => (
            <MenuItem
              key={option.name}
              data-cy={`option-${option.name}`}
              onClick={() => {
                onSelect(option)
                closeHandler()
              }}
              selected={currentOption.name === option.name}
            >
              {t(option.label)}
            </MenuItem>
          ))}
        </MenuList>
      )}
    />
  )
}
