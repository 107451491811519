import * as React from 'react'
import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const MenuItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'clickable',
})<{ clickable: boolean }>(({ theme, clickable }) => ({
  color: theme.palette.text.primary,
  ...(clickable && {
    transition: '0.3s all',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
}))

interface Props {
  onClick?: () => void
}

export const MenuItem = ({ children, onClick }: React.PropsWithChildren<Props>) => {
  return (
    <Grid item>
      <MenuItemText onClick={onClick} color="textPrimary" clickable={!!onClick}>
        {children}
      </MenuItemText>
    </Grid>
  )
}
