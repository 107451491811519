import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikMessage } from 'components/Formik/FormikMessage'
import { Form, Formik, FormikConfig } from 'formik'
import { CommentAvatar } from 'pages/SocialWall/components/comment/CommentAvatar'
import { MAX_COMMENT_LENGTH, useValidationSchema } from 'pages/SocialWall/components/comment/utils'
import { useCommentAdd } from 'utils/hooks/api/SocialWall/useCommentAdd'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

interface Props {
  onExit: () => void
  postId: number
  showComments: boolean
}
type FormValues = {
  content: string
}

export const CommentAdd = ({ postId, onExit, showComments }: Props) => {
  const { mutate, isLoading } = useCommentAdd({ postId, onSuccess: onExit })
  const user = useCurrentUser()

  const { validationSchema } = useValidationSchema()
  const onSubmit: FormikConfig<FormValues>['onSubmit'] = ({ content }) => {
    mutate({ content, post: postId })
  }

  return (
    <Formik
      initialValues={{
        content: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialTouched={{ content: true }}
    >
      <Form data-cy="add-comment-form">
        <Box
          display="grid"
          gridTemplateColumns={'auto 1fr'}
          columnGap={1}
          mt={1}
          mb={showComments ? -2 : 0}
          sx={{ transition: '0.3s all ease' }}
        >
          <CommentAvatar avatar={user.profile.avatar} fullName={user.profile.full_name} />

          <Grid container spacing={2} mb={3}>
            <Grid item xs={12}>
              <FormikMessage name={`content`} size="small" autoFocus maxLength={MAX_COMMENT_LENGTH} />
            </Grid>
            <Grid item xs={12}>
              <FormikButtons
                onBack={onExit}
                isLoading={isLoading}
                textButtonCancel="app.cancel"
                textButtonSubmit="app.add"
                gridLayout
                size="small"
              />
            </Grid>
          </Grid>
        </Box>
      </Form>
    </Formik>
  )
}
