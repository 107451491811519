import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Check } from 'components/ArteelIcons'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import Loader from 'components/Loader/Loader'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import qs from 'query-string'
import { getResponsiveSize } from 'theme/styles.utils'
import { dataCy } from 'utils/cypressUtils'
import { useGetVouchersLinksLB } from 'utils/hooks/api/Vouchers/useGetVouchersLinksLB'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  whitePaper: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  headerStep: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    '& svg': {
      fontSize: getResponsiveSize(6, 'rem'),
      color: theme.palette.primary.main,
    },
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: getResponsiveSize(3, 'rem'),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
  },
  wrapper: {
    marginTop: theme.spacing(2),
  },
  info: {
    fontFamily: 'ProximaNova',
    lineHeight: 1.25,
  },
  email: {
    color: theme.palette.primary.main,
  },
  button: {
    marginBottom: theme.spacing(3),
  },
  vouchers: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
  },
}))

export const CreateOrderConfirmation = () => {
  const { whitePaper, headerStep, icon, title, wrapper, info, email, button, vouchers } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = qs.parse(location.search)
  const { id } = useParams<{ id: string }>()
  const user = useCurrentUser()
  const { isSingleLogic, isLuckyBird, isDemoUser } = useIsLuckyBirdUser()
  const ext_web_order_reference = !(id === 'undefined' || id === 'null') ? id : isDemoUser ? 'demo' : undefined
  const { isFetching: isLoadingVouchers, data: vouchersData } = useGetVouchersLinksLB(ext_web_order_reference)

  return (
    <Grid container justifyContent="center" data-cy={dataCy('container-order-confirmation')}>
      <Grid item xs={12} xl={8} className={whitePaper}>
        <WhitePaper
          changeViewDisable
          showPointsDisable
          header={
            <Grid container justifyContent="flex-end">
              {isSingleLogic && <Grid item xs={12} sx={{ height: (theme) => theme.spacing(4) }} />}
              <Grid item>{!isSingleLogic && <GoBackButton noPx onClick={() => navigate('/catalog')} />}</Grid>
              <Grid item xs={12} className={headerStep}>
                <div className={icon}>
                  <Check />
                </div>
                {t('create_order.confirmation')}
              </Grid>
            </Grid>
          }
        >
          {ext_web_order_reference ? (
            <Grid container spacing={4} justifyContent="center" className={wrapper}>
              <Grid item xs={12}>
                <Typography variant="h1" align="center" className={title}>
                  {t('create_order.congratulations', { name: user?.profile?.first_name })}
                </Typography>
              </Grid>
              {isDemoUser ? (
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    align="center"
                    color="error"
                    fontWeight="bold"
                    data-cy={dataCy('label-demo-user-order-confirmation')}
                  >
                    {t('create_order.confirmation.demo.info1')}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h3" align="center" className={info}>
                    {t('create_order.confirmation.info1')}
                    <span className={email}>{params?.email || user?.email || user?.profile?.email}</span>
                    {`!`}
                  </Typography>
                  <Typography variant="h3" align="center" className={info}>
                    {t('create_order.confirmation.info2')}
                  </Typography>
                </Grid>
              )}
              {isLoadingVouchers && (
                <Grid item xs={12}>
                  <Loader isLoading />
                </Grid>
              )}
              {isSingleLogic && !!vouchersData?.data?.links?.length && (
                <Grid
                  item
                  xs={12}
                  sx={(_) => ({
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  })}
                >
                  <Typography variant="h1" align="center" className={vouchers} pb={1}>
                    {t('create_order.vouchers')}
                  </Typography>
                  {vouchersData?.data?.links?.map((link: string, i, arr) => (
                    <Button
                      sx={(_) => ({
                        maxWidth: 500,
                        width: '100%',
                      })}
                      key={i}
                      type="button"
                      color="primary"
                      onClick={() => window.open(link, '_blank')}
                    >
                      {`${t('create_order.get_voucher')} ${arr.length > 1 ? i + 1 : ''}`}
                    </Button>
                  ))}
                </Grid>
              )}
              <Grid item>
                <Button
                  data-cy={dataCy('button-order-home-visit')}
                  className={button}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/')}
                >
                  {t('app.back_to_home')}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h1" align="center" className={title}>
              {t('create_order.confirmation.fail')}
            </Typography>
          )}
        </WhitePaper>
      </Grid>
    </Grid>
  )
}
