import { useState } from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { useQueryClient } from '@tanstack/react-query'
import { Location } from 'components/ArteelIcons/Location'
import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { CustomPopover } from 'components/CustomPopover/CustomPopover'
import DialogConfirm from 'components/DialogConfirm'
import { SelectButton } from 'components/SelectButton'
import { Tooltip } from 'components/Tooltip'
import { useCountryContext } from 'store/useCountryContext'
import { Country } from 'utils/api/country'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useIsTablet } from 'utils/hooks/useBreakpoints'

export const CountrySelectButton = () => {
  const [state, setState] = useState<{ closeHandler: () => void; country: Country } | null>(null)
  const { country, setCountry } = useCountryContext()
  const { data, isLoading } = useGetAvailableCountries()
  const isTablet = useIsTablet()
  const queryClient = useQueryClient()

  const handleChange = () => {
    state?.closeHandler()
    setCountry(state?.country)
    setState(null)
  }

  return (
    <>
      <CustomPopover
        button={({ open }) => (
          <SelectButton
            open={!!open}
            color="primary"
            size={isTablet ? 'small' : 'medium'}
            startIcon={<Location sx={{ fontSize: isTablet ? undefined : '1rem !important' }} />}
            data-cy="country-select-button"
          >
            <Tooltip title="country_context.tooltip" placement="top" arrow>
              <Box>{getObjectTranslation(country?.translation)?.name}</Box>
            </Tooltip>
          </SelectButton>
        )}
        content={({ closeHandler }) => (
          <MenuList>
            {data?.data?.map((c) => (
              <MenuItem
                data-cy={`country-${c.id}`}
                key={c.id}
                onClick={() => {
                  setState({ closeHandler, country: c })
                }}
                selected={country.id === c.id}
              >
                {getObjectTranslation(c?.translation)?.name}
              </MenuItem>
            ))}
          </MenuList>
        )}
      />
      <DialogConfirm
        confirmTranslation="app.change"
        contentText="country_context.confirm_text"
        confirmAction={handleChange}
        confirmColor="primary"
        onClose={() => setState(null)}
        openDialog={!!state}
      />
    </>
  )
}
