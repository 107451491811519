import qs from 'query-string'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type Brand = {
  id: number
  name: string
}

export const fetchBrands = (search: string) => {
  return getRequest<WithPagination<Brand>>(
    `/redemption-site/brands?${qs.stringify(
      {
        limit: 1000,
        'filters[name]': search || undefined,
      },
      { encode: false }
    )}`
  )
}
