import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import { Close } from 'components/ArteelIcons'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { CategoriesDrawerContent } from 'theme/Mobile/CategoriesDrawer/CategoriesDrawerContent'
import { CategoriesDrawerContext } from 'theme/Mobile/CategoriesDrawer/CategoriesDrawerContext'
import { CONSTANTS } from 'theme/Mobile/CategoriesDrawer/styles'
import { MOBILE_TOP_BAR_HEIGHT } from 'theme/styles.utils'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { important } from 'utils/styles'
import { Z_INDEX } from 'utils/styles/zIndex'
import { FCWithChildren } from 'utils/types/FCWithChildren'

export const CategoriesDrawerProvider: FCWithChildren = ({ children }): any => {
  const [open, setOpen] = useState(false)
  const isTablet = useIsTablet()
  const showCategoriesTree = useShowCategoriesTree()

  const { isRoute: showDrawer } = useIsRoute([
    '/catalog/categories/:categoryId',
    '/catalog',
    '/catalog/brands',
    '/catalog/brands/:id',
    '/',
  ])

  const isVisible = isTablet && showDrawer && showCategoriesTree

  return (
    <CategoriesDrawerContext.Provider value={{ open, toggleCategoriesDrawer: setOpen }}>
      {children}
      {isVisible && (
        <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              top: MOBILE_TOP_BAR_HEIGHT,
              overflow: 'visible',
              height: `calc(100% - ${MOBILE_TOP_BAR_HEIGHT}px)`,
              maxWidth: '85vw',
              width: 400,
              ...(open && CONSTANTS.paperBorderRadius),
              pl: CONSTANTS.paperHorizontalPadding,
              pr: 0,
              py: 3,
            },
          }}
          ModalProps={{
            keepMounted: true,
            sx: { top: MOBILE_TOP_BAR_HEIGHT, zIndex: Z_INDEX.SIDE_MOBILE_DRAWER },
          }}
        >
          <Fade in={open}>
            <IconButton
              color="primary"
              size="large"
              onClick={() => setOpen(false)}
              sx={(t) => ({
                position: 'absolute',
                right: -10,
                top: 10,
                transform: 'translateX(100%)',
                background: important(t.palette.background.paper),
              })}
            >
              <Close fontSize="small" />
            </IconButton>
          </Fade>
          <Box sx={{ overflow: 'auto' }}>
            <CategoriesDrawerContent />
          </Box>
        </Drawer>
      )}
    </CategoriesDrawerContext.Provider>
  )
}
