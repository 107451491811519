import React from 'react'
import Grid from '@mui/material/Grid'
import { DepartmentSearchField } from 'components/DepartmentTree/DepartmentSearchField'
import { DepartmentTreeView } from 'components/DepartmentTree/DepartmentTreeView'
import { DepartmentTreeProps } from 'components/DepartmentTree/types'
import { useDepartments } from 'components/DepartmentTree/useDepartments'
import { valueOrUndefined } from 'utils/getValueOrUndefined'

export const DepartmentTree = ({ selected, setSelected, searchProps, centered = true }: DepartmentTreeProps) => {
  const { data, isLoading, expanded, selectedAncestors, handleToggle, handleSetSearch } = useDepartments({
    setSelected,
    selected,
  })

  return (
    <Grid container gap={2}>
      <Grid item container xs={12} justifyContent={valueOrUndefined(centered, 'center')}>
        <Grid item xs={12}>
          <DepartmentSearchField handleSetSearch={handleSetSearch} searchProps={searchProps} />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent={valueOrUndefined(centered, 'center')}>
        <DepartmentTreeView
          selected={selected}
          setSelected={setSelected}
          isLoading={isLoading}
          selectedAncestors={selectedAncestors}
          data={data}
          handleToggle={handleToggle}
          expanded={expanded}
        />
      </Grid>
    </Grid>
  )
}
